import React, { useEffect, useState } from "react";
import ErrorMessage from "../../components/ErrorMessage";
import API from "../../api/API";

// Schedule
function MusicStore({ errorList, prevStep, nextStep, data }) {
  const [errors, setErrors] = useState([]);
  const [storeList, setStoreList] = useState([]);

  const handleChange = (evt) => {
    const { value, checked } = evt.target;
    setStoreList((prevState) =>
      prevState?.map((s) =>
        s?.name === value ? { ...s, selected: checked } : s
      )
    );
  };

  const nextSubmit = (e) => {
    e.preventDefault();
    let selectedStore = storeList?.filter((s) => s?.selected);
    nextStep({ stores: selectedStore?.map((s) => s?.name) }, data?.trackType);
  };

  useEffect(() => {
    API.get(`stores`).then((res) => {
      setStoreList(
        res?.data?.map((store, index) => ({
          ...store,
          selected: index === 0 ? true : false,
        }))
      );
    });
  }, []);
  return (
    <div className="em-db-content">
      {errors.is_single_track ? (
        <ErrorMessage message={errors.is_single_track} />
      ) : (
        ""
      )}

      <div className="em-upload-track">
        <h5>Release Details</h5>

        <div className="em-music-store row">
          {storeList && storeList?.length > 0
            ? storeList?.map((s) => (
                <div key={s?.id} className="col-6 col-md-3 col-lg-2 p-1">
                  <div className="store-card em-music-store-info">
                    <input
                      value={s?.name}
                      onChange={handleChange}
                      type={"checkbox"}
                      id={`storeLogo_${s?.id}`}
                      className="rounded"
                      checked={s?.selected}
                    ></input>
                    <label htmlFor={`storeLogo_${s?.id}`}>
                      <img alt="" src={s?.logo}></img>
                      <span className="text-capitalize">{s?.name}</span>
                    </label>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <ul className="em-upload-btn">
        <li>
          <button onClick={prevStep} className="em-upoad-btn">
            BACK
          </button>
        </li>
        <li>
          <button onClick={nextSubmit} className="em-upoad-btn">
            NEXT
          </button>
        </li>
      </ul>
    </div>
  );
}

export default MusicStore;

import React, { useEffect, useState } from "react";
import API from "../../../api/API";

import "../../../sass/Pages/_em-login.scss";
import ErrorMessage from "../../../components/ErrorMessage";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import ImagePath from "../../../constants/ImagePath";
import PayPal from "../../../components/PaymentMethod/PayPal";
import StripeForm from "../../../components/PaymentMethod/StripeForm";
import ConfirmModal from "./ConfirmModal";

function Checkout({ error, nextStep, prevStep, data }) {
  const [paymentInfo, setPaymentInfo] = useState({});
  const [cardInfo, setCardInfo] = useState({
    paymentMethod: data?.cardInfo?.paymentMethod || "card",
  });

  const [errors, setErrors] = useState([]);

  const handleChange = (evt) => {
    const value = evt.target.value;
    setCardInfo({
      ...cardInfo,
      [evt.target.name]: value,
    });
  };

  const confirmAndPay = () => {
    // console.log(paymentInfo, "paymentInfo");
    nextStep({ submit: true }, 3);
  };

  useEffect(() => {
    Object.keys(paymentInfo)?.length > 0 &&
      nextStep(
        {
          paymentMethod: cardInfo?.paymentMethod,
          paymentInfo: paymentInfo,
          serviceId: 1,
          price: 1,
        },
        3
      );
  }, [paymentInfo]);

  return (
    <div className="em-signup min-vh-100">
      <Link
        className="d-flex align-items-center text-white nav-link"
        onClick={prevStep}
        to="#"
        type="button"
      >
        <img src={ImagePath?.icBackArrow} className="img-fluid" />
      </Link>

      <h3 className="py-4">Payment Method</h3>
      <div className="em-form m-0">
        <div className="border rounded-3 p-2">
          <div className="em-input d-flex justify-content-between align-items-center">
            <div>
              <input
                onChange={handleChange}
                name="paymentMethod"
                type="radio"
                id="cr-de-card"
                checked={cardInfo?.paymentMethod === "card" ? true : false}
                value="card"
              />
              <label htmlFor="cr-de-card" className="position-relative">
                Credit/Debit Card
              </label>
            </div>
            <div>
              <img src={ImagePath?.icVisaMasterCard} />
            </div>
          </div>

          <div className="em-input d-flex justify-content-between align-items-center">
            <div>
              <input
                onChange={handleChange}
                name="paymentMethod"
                type="radio"
                id="payPal"
                checked={cardInfo?.paymentMethod === "paypal" ? true : false}
                value="paypal"
              />
              <label htmlFor="payPal" className="position-relative">
                PayPal
              </label>
            </div>
            <div>
              <img src={ImagePath?.icPayPal} />
            </div>
          </div>

          {/* <div className="em-input d-flex justify-content-between align-items-center">
            <div>
              <input
                onChange={handleChange}
                name="paymentMethod"
                type="radio"
                id="free"
                checked={cardInfo?.paymentMethod === "free" ? true : false}
                value="free"
              />
              <label htmlFor="free" className="position-relative">
                Free Account
              </label>
            </div>
          </div> */}
        </div>

        {errors.terms ? <ErrorMessage message={errors?.terms} /> : ""}

        <div className="em-form m-0">
          {cardInfo?.paymentMethod === "paypal" ? (
            <div className="pt-4">
              <PayPal item={data?.orderDetails?.service} setPaymentInfo={setPaymentInfo} />
            </div>
          ) : (
            <StripeForm
              item={data?.orderDetails?.service}
              setPaymentInfo={setPaymentInfo}
            />
          )}
        </div>
      </div>

      {Object.keys(paymentInfo).length > 0 && (
        <ConfirmModal nextSubmit={confirmAndPay} />
      )}
    </div>
  );
}

export default Checkout;

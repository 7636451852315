import React from "react";
import ImagePath from "../../constants/ImagePath";

const SubscriptionCard = ({ data = {}, handleChange, item }) => {
  return (
    <div
      className={`border rounded-3 ${!data?.isFree ? "border-danger" : ""}`}
      onClick={() => handleChange("service", item)}
    >
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between text-white">
          <div className="d-flex align-items-center">
            <img src={ImagePath?.icCrown} className="img-fluid mb-1" />
            <span className="mx-2 small">{item?.title}</span>
          </div>
          <span className="small">{item?.price}$ / annum</span>
        </div>

        <div className="pt-3 d-flex align-items-center justify-content-between text-white">
          <span className="small">Discount on Music services</span>
          <span className="small">{item?.discount}%</span>
        </div>
      </div>

      <div className="border-top"></div>
      <div className="d-flex align-items-center justify-content-between p-3">
        <span className="text-white fs-5 fw-bold">Total</span>
        <span className="text-white fs-5 fw-bold">{item?.price}$ / annum</span>
      </div>
    </div>
  );
};

export default SubscriptionCard;

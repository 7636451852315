import React from "react";
import ImagePath from "../constants/ImagePath";
const LoadingIcon = () => {
  return (
    <div className="position-absolute top-50 start-50 translate-middle">
    <img width="100" className="img-fluid" src={ImagePath?.icSpinner} />
    </div>
  );
};

export default LoadingIcon;

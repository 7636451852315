import React from "react";
import "../../../assets/pricing.css";
import Images from "../../../assets/images";
import Menu from "../Menu/Menu";
import Layout from "../Layout";
import { Link } from "react-router-dom";
import ImagePath from "../../../constants/ImagePath";
function Pricing() {
  return (
    <Layout>
      <div className="em-bgcolor">
        <div className="container">
          <Menu />
          <div className="payWrap">
            <div className="pay-box">
              <p className="pay-subHeading">
                Welcome to emergence distribution services
              </p>
              <h2 className="pb-3">DAD | Digital Artist Development</h2>
              <p>
                From marketing strategies, education, music industry contract,
                graphic design, mental health support for artists and many more.
                Emergence Music Distribution provides a key-in-hand custom
                service for the digital development of a music artist. The only
                ecosystem involving the automatization of the artist being
                registered and distributed throughout the world. By using our
                one-stop-shop platform to secure and digitalize every aspect of
                a music artist.
              </p>
            </div>
            <div className="inner-price">
              <div className="price-box">
                <div className="d-flex flex-column flex-md-row align-items-center">
                  <div className="icon d-flex justify-content-center py-3 me-0 me-md-3">
                    <img
                      src={ImagePath?.AWBIcon}
                      className="img-fluid"
                      width={120}
                    />
                  </div>
                  <div>
                    <p>AWB | Artist With Benefits</p>
                    <p className="price-desc">
                      Annual Subscription <span>$14.99/Year</span>
                    </p>
                    <ul className="ser-list">
                      <li>15% discount on music services.</li>
                      <li>Unlimited digital music distribution.</li>
                    </ul>
                  </div>
                </div>

                <Link to="/signup">Emerge Now </Link>
              </div>
            </div>
          </div>
          <div className="Services-section">
            <h2>Digital Artist Development Services</h2>
            <div className="pay-box col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-3 px-2 pb-3">
              <p className="text-md-center">
                We empower the artist by digitally branding themselves. By
                assisting them with all the tools to guide them to numerous
                revenue opportunities.
              </p>
            </div>
            <div className="services-wrap">
              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.VocalCoachServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">
                  Emergence Music Mental Support For Artist | EMMSA
                </p>
                <p className="ser-title">
                  The NLP coach's goal is to bring you to mentally overcome the
                  thought patterns that limit you from reaching your goals.
                  Using Neuro-Linguistic Programming can clearly identify
                  strategies you use in your decision-making or your behavioral
                  patterns.
                </p>
                <ul className="ser-list">
                  <li>NLP Coach Consultation | 60:00 MIN $79.99</li>
                </ul>
              </div>

              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.EPKServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">EPK | BASIC $39.99 | PRO $64.99</p>
                <p className="ser-title">
                  Build your artist and/or your music media press release.
                  Promote your career or new musical project with your
                  high-quality music resume.
                </p>
                <ul className="ser-list">
                  <li>Basic</li>
                  <li>Pro</li>
                  <li>Update $29.99 </li>
                </ul>
              </div>

              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.GraphicDesignServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">Graphic Design | ON DEMAND</p>
                <p className="ser-title">
                  Get the best visual content for your musical project with our
                  top-of-the-line graphic design team.{" "}
                </p>
                <ul className="ser-list">
                  <li>Cover/Artwork | BASIC $49.99 | PRO $74.99</li>
                  <li>Social Media Promotion | $39.99</li>
                  <li>Audio Video Social Media | BASIC $39.99 | PRO $59.99</li>
                </ul>
              </div>

              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.MarketingCampaignServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">
                  Marketing Campaign | FROM $149.99
                </p>
                <p className="ser-title">
                  Plan your promotional music campaign
                </p>
                <ul className="ser-list">
                  <li>Social Media Ads</li>
                  <li>Youtube Ads</li>
                  <li>Influencer</li>
                  <li>Email</li>
                </ul>
              </div>

              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.MusicPlaylistServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">
                  Music Playlists | FROM $149.99
                </p>
                <p className="ser-title">
                  Promote your music with our music playlist curators.
                </p>
                <ul className="ser-list">
                  <li>Spotify (Organic & Official Curators)</li>
                  <li>Emergence Music Playlists</li>
                  {/* <li>Emerge Now (Clicker)</li> */}
                </ul>
              </div>

              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.MusicDistributionServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">Music Distribution</p>
                <p className="ser-title">
                  Distribute your music on the most popular digital music
                  streaming platforms around the world and more.
                </p>
                <ul className="ser-list">
                  <li>Sound Royalties Collection</li>
                  <li>Unlimited Music Distribution</li>
                  {/* <li>Emerge Now (Clicker)</li> */}
                </ul>
              </div>

              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.MusicConsultationServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">Music Consultation | FROM $29.99</p>
                <p className="ser-title">
                  Be guided by the best with the right strategy to reach and
                  surpass your objectives.
                </p>
                <ul className="ser-list">
                  <li>30:00 MIN & 60:00 MIN</li>
                  <li>Management Consultation by a certified Music Manager</li>
                  <li>
                    Supervised Music Release by one of our Music Supervisors
                  </li>
                  <li>
                    Artist Music Registration with one of our Music Supervisors
                  </li>
                </ul>
              </div>

              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.MusicIndustryContractServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">
                  Music Industry Contract | FROM $49.99
                </p>
                <p className="ser-title">
                  Get the proper documents to protect your music career and/or
                  company legally with our contracts.
                </p>
                <ul className="ser-list">
                  <li>Booking</li>
                  <li>Licensing Agreement | Non-Exclusive & Exclusive</li>
                  <li>On-Demand</li>
                </ul>
              </div>

              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.MusicVideoDistributionServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">
                  Music Video Distribution | ON DEMAND
                </p>
                <p className="ser-title">
                  Distribute your music video on the most popular digital music
                  video streaming platforms around the world and more.
                </p>
                <ul className="ser-list">
                  <li>Vevo</li>
                  <li>Itunes Store/Apple Music</li>
                </ul>
              </div>

              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.PublicRelationServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">Public Relations | ON DEMAND</p>
                <p className="ser-title">
                  Plan your specific media rollout locally or internationally to
                  reach your target audience and get your brand more visibility.
                </p>
                <ul className="ser-list">
                  <li>Media Tour</li>
                  <li>Radio Tour</li>
                  <li>Media Press</li>
                  <li>Interview</li>
                </ul>
              </div>

              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.RadioBroadcastingServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">Radio Broadcasting | ON DEMAND</p>
                <p className="ser-title">
                  Distribute your music on Radio Broadcasting International
                  Networks.
                </p>
                <ul className="ser-list">
                  <li>!EARSHOT DISTRO</li>
                  <li>International DMDS</li>
                </ul>
              </div>

              <div className="service-box">
                <div className="icon d-flex justify-content-center py-3">
                  <img
                    src={ImagePath?.TvBroadcastingServiceIcon}
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <p className="ser-price">TV Broadcasting | ON DEMAND</p>
                <p className="ser-title">
                  Distribute your music video on International TV Networks.
                </p>
                <ul className="ser-list">
                  <li>BET/BET INT.</li>
                  <li>MTV Networks</li>
                  <li>MTV Latin America</li>
                  <li>Latido Musica</li>
                  <li>MuchMusic</li>
                  <li>And many more</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Pricing;

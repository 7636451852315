import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import "./sidebar-mobile.css";
import $ from "jquery";
import ImagePath from "../constants/ImagePath";

function MobileSideBar() {
  const [user, setUser] = useState({ name: "", accountType: "" });
  const navigate = useNavigate();
  const [width, setWidth] = useState("");
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const cookies = new Cookies();

    setWidth($(window).width());
    let sidebar = document.querySelector(".sidebar");

    let closeBtn = document.querySelector("#btn");

    let userName = cookies.get("user_name");
    let accountType = cookies.get("account_type");

    if (userName) {
      setUser({
        name: userName,
        accountType: accountType,
      });
    }

    if (closeBtn) {
      closeBtn.addEventListener("click", () => {
        sidebar.classList.toggle("open");
        menuBtnChange(); //calling the function(optional)
      });
    }
    // following are the code to change sidebar button(optional)
  }, []);

  const menuBtnChange = () => {
    let sidebar = document.querySelector(".sidebar");
    let closeBtn = document.querySelector("#btn");
    if (sidebar.classList.contains("open")) {
      closeBtn.classList.replace("bx-menu", "bx-menu-alt-right"); //replacing the iocns class
    } else {
      closeBtn.classList.replace("bx-menu-alt-right", "bx-menu"); //replacing the iocns class
    }
  };
  const logout = () => {
    const cookies = new Cookies();
    cookies.remove("auth_token");
    cookies.remove("user");
    cookies.remove("user_name");
    cookies.remove("account_type");
    navigate.push("/login");
  };

  const toggleMenu = () => {
    $(".toggle-menu").toggleClass("bx-x");

    $(".toggle-menu").css({
      "margin-left": "4rem",
    });

    $(".sidebar").css({ "margin-left": 0 });
  };

  useEffect(() => {
    if (width < 1000) {
      import("./sidebar-mobile.css");
    }
  }, [width]);
  return (
    <>
      <div
        className="burger-menu mt-0 py-2 d-flex align-items-center justify-content-between d-lg-none"
        onClick={() => setToggleSidebar(!toggleSidebar)}
      >
        <i
          className={
            toggleSidebar
              ? "fa-solid fa-times bx-menu fs-1"
              : "bx bx-menu toggle-menu fs-1"
          }
          style={{ marginLeft: toggleSidebar ? "4rem" : "0" }}
        ></i>

        <Link to="/">
          <img
            width="80"
            src={ImagePath?.icLogo}
            alt=""
            className="img-fluid mx-4"
          />
        </Link>
      </div>

      <div
        className={`sidebar ${width > 992 ? "" : ""}`}
        style={{ left: toggleSidebar || width > 992 ? "0" : "-78px" }}
      >
        <div className="logo-details d-none d-lg-block">
          <img src={ImagePath?.icLogo} alt="" className="img-fluid" />

          {/* <div className="logo_name">Emergence</div>
                        { 
                            width < 768 && 
                            <i className="fa-solid fa-bars bx-menu" id="btn" ></i>
                        
                        } */}
        </div>
        <ul className="nav-list">
          <li>
            <Link
              onClick={() => setToggleSidebar(!toggleSidebar)}
              className={location?.pathname === "/dashboard" ? "active" : ""}
              to="/dashboard"
            >
              <i className="bx bx-grid-alt"></i>
              <span className="links_name">Dashboard</span>
            </Link>
            <span className="tooltip">Dashboard</span>
          </li>
          <li>
            <Link
              onClick={() => setToggleSidebar(!toggleSidebar)}
              className={location?.pathname === "/upload-music" ? "active" : ""}
              to="/upload-music"
            >
              <i className="bx bx-cloud-upload"></i>
              <span className="links_name">Upload Music</span>
            </Link>
            <span className="tooltip">Upload Music</span>
          </li>
          <li>
            <Link
              onClick={() => setToggleSidebar(!toggleSidebar)}
              className={location?.pathname === "/musics" ? "active" : ""}
              to="/musics"
            >
              <i className="bx bx-music"></i>
              <span className="links_name">Music</span>
            </Link>
            <span className="tooltip">Music</span>
          </li>
          <li>
            <Link
              onClick={() => setToggleSidebar(!toggleSidebar)}
              className={location?.pathname === "/albums" ? "active" : ""}
              to="/albums"
            >
              <i className="bx bx-album"></i>
              <span className="links_name">EP / Albums</span>
            </Link>
            <span className="tooltip">EP / Albums</span>
          </li>
          <li>
            <Link
              onClick={() => setToggleSidebar(!toggleSidebar)}
              className={location?.pathname === "/subscription" ? "active" : ""}
              to="/subscription"
            >
              <i className="bx bx-crown"></i>
              <span className="links_name">Subscriptions</span>
            </Link>
            <span className="tooltip">Subscriptions</span>
          </li>
          <li>
            <Link
              onClick={() => setToggleSidebar(!toggleSidebar)}
              className={location?.pathname === "/settings" ? "active" : ""}
              to="/settings"
            >
              <i className="bx bx-cog"></i>
              <span className="links_name">Settings</span>
            </Link>
            <span className="tooltip">Settings</span>
          </li>

          <li>
            <Link to="#" type="button" onClick={logout}>
              <i className="bx bx-log-in"></i>
              <span className="links_name">Log Out</span>
            </Link>
            <span className="tooltip">Log Out</span>
          </li>
          {user?.name && (
            <li
              className="profile"
              style={{ left: toggleSidebar || width > 992 ? "0" : "-78px" }}
            >
              <div className="profile-details logout-sec">
                {user?.accountType === "free" ? (
                  <img src={ImagePath?.freeProfileLogo} />
                ) : (
                  <img src={ImagePath?.paidProfileLogo} />
                )}
                <div className="links_name p-2">
                  <p className="text-wrap small">{user?.name}</p>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
    // <div className="em-db-sidebar">
    //     <strong>
    //         <img src={Images.sm_logo} alt="" /> <span>Emergence</span>
    //     </strong>
    //     <ul className="em-db-sidebar-wraper">
    //         <li><Link to="/"> Dashboard</Link></li>
    //         <li><Link to="/upload-music-step1"> Upload Music</Link></li>
    //         <li><Link to="musics"> My Musics </Link></li>
    //         <li><Link to="album"> My Albums</Link></li>
    //         <li><Link to="/subscription"> subscription</Link></li>
    //         <li><Link to="/settings"> Settings</Link></li>
    //     </ul>
    //     <div className="em-db-footer">
    //         <div className="em-db-footer-wraper">
    //             <img src="images/em-db-footer-logo.png" alt="" />
    //             <div className="em-footer-info">
    //             <h6>{name}</h6>
    //             <em>Global Sound</em>
    //             </div>
    //         </div>
    //         <a onClick={logout}>Log out</a>
    //     </div>
    // </div>
  );
}

export default MobileSideBar;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import ImagePath from "../../../constants/ImagePath";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

function Menu() {
  const cookies = new Cookies();
  return (
    <div className="navigation-wrap">
      <div className="nav-left">
        <Link to="/" className="nav-link">
          <img
            src={ImagePath?.icLogoWithName}
            alt="site-logo.png"
            className="img-fluid"
          />
        </Link>
      </div>
      <div className="nav-middle nav-link">
        {/* <input type="checkbox" name="" id="click" />
        <label htmlFor="click" className="menu-btn">
          <i className="fa fa-bars" aria-hidden="true"></i>
        </label> */}
        <DesktopMenu />
        {/* Mobile */}
        <MobileMenu />
      </div>
      {cookies.get("auth_token") ? (
        <div className="nav-right">
          <Link to="/dashboard" className="emergence text-white">
            DASHBOARD
          </Link>
        </div>
      ) : (
        <div className="nav-right">
          <Link to="/login" className="login">
            LOGIN
          </Link>
          <Link to="/signup" className="emergence">
            SIGN UP
          </Link>
        </div>
      )}
    </div>
  );
}

export default Menu;

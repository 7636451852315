import React from "react";
import SideBar from "../../components/SideBar";
import SongList from "../../components/SongList";

function Musics() {
  // const getDuration = (src) => {
  //     return new Promise(function(resolve) {
  //         var audio = new Audio();
  //         $(audio).on("loadedmetadata", function(){
  //             resolve(audio.duration);
  //         });
  //         audio.src = src;
  //     });
  // }

  return (
    <>
      <SideBar />
      <div className="em-db-content home-section">
        <div className="em-db-content-wraper m-0">
          <div className="em-top-release-music m-0">
            <SongList />
            {/* <h5>Top Release</h5>
          <ul className="em-top-release-music-wraper">
            {songs &&
              songs.map((item, index) => {
                return (
                  <li key={index}>
                    <div className="em-top-release-music-info">
                      <span>#{index + 1}</span>
                      <img
                        className="em-thumbnail"
                        src={`${baseURL}/${item.thubnail}`}
                        alt="music image"
                      />
                      <div className="em-top-release-music-profie">
                        <div className="em-top-release-music-profie-info">
                          <h6>{item.title}</h6>
                          <span>{item.artisit}</span>
                        </div>
                        <div className="em-top-release-music-time">
                          <i className="fa-solid fa-clock"></i>
                          <span id={`time${index}`}>
                            {getDuration(`${baseURL}/${item.file_url}`, index)}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Musics;

import React from "react";
import { Link } from "react-router-dom";
import ImagePath from "../../constants/ImagePath";

const ServiceCard = ({ item }) => {
  return (
    <div className="service-card border rounded-3 h-100 d-flex flex-column justify-content-between">
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between text-white">
          <div className="d-flex align-items-center">
            <img src={ImagePath?.icCrown} className="img-fluid mb-1" />
            <span className="mx-2 small fw-bold">{item?.title}</span>
          </div>
          {/* <span className="small">{0.0}$ / annum</span> */}
        </div>
        <div className="pt-3 text-start text-white">
          {/* <span className="small">{item?.description}</span> */}
          {/* <span className="small">15%</span> */}
          {item?.childServices?.length > 0 && (
            <ul className="list-unstyled m-0">
              {item?.childServices?.map((service, index) => (
                <li key={service?.id}>
                  <i className="fas fa-check-circle text-success small"></i>
                  <span className="ms-2 small">{service?.title}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="d-flex p-3 w-100">
        <Link
          to={`/services/${item?.slug}`}
          className="em-upoad-btn active rounded-5 border border-dark py-3 w-100 text-uppercase"
        >
          EMERGE NOW
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;

import React, { useState } from "react";
import API from "../../api/API";

import "../../sass/Pages/_em-login.scss";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import ImagePath from "../../constants/ImagePath";
import SignUpForm from "./SignUp/SignUpForm";
import Checkout from "./SignUp/Checkout";
import OrderDetail from "./SignUp/OrderDetail";
import LoadingModal from "./SignUp/LoadingModal";

function SignUp({ setUserToken }) {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [step, setStep] = useState(1);

  const [errors, setErrors] = useState([]);

  const nextStep = (record, doneStep) => {
    if (!record.submit) {
      setStep(doneStep);

      setData((prevState) => ({
        ...prevState,
        ...record,
      }));
    }
    if (record.submit) {
      if (record?.isFree) {
        submitForm({
          ...data,
          paymentMethod: "free",
          serviceId: null,
          price: 0.0,
          paymentHistory: null,
        });
      } else {
        submitForm(data);
      }
      // console.log(data, "complete data");
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const renderStep = () => {
    // console.log(step, "step");
    switch (step) {
      case 1:
        return <SignUpForm errors={errors} nextStep={nextStep} data={data} />;
      case 2:
        return (
          <OrderDetail
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        );
      case 3:
        return (
          <Checkout
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        );
      default:
      // do nothing
    }
  };

  const submitForm = (data) => {
    setErrors([]);
    const formData = {
      username: data?.signUp?.username,
      fullName: data?.signUp?.fullName,
      secondaryName: data?.signUp?.secondaryName,
      dob: data?.signUp?.dob,
      country: data?.signUp?.country,
      phoneNumber: data?.signUp?.phoneNumber,
      email: data?.signUp?.email,
      password: data?.signUp?.password,
      confirm_password: data?.signUp?.confirm_password,
      paymentHistory: data.paymentInfo
        ? JSON.stringify(data.paymentInfo)
        : null,
      serviceId: data?.serviceId,
      price: data?.price,
      paymentType: data?.paymentMethod,
    };

    // console.log(formData, "final submit");
    setLoading(true);

    API.post(`signup`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log(res, "sign up and subscribe");

        const cookies = new Cookies();
        cookies.set("auth_token", res.data.data.token);
        cookies.set("user_id", res.data.data.user_id);
        cookies.set("user_name", res.data.data.name);
        cookies.set("account_type", res.data.data.account_type);
        setUserToken();
        if (res?.data?.data?.subscribe == "success") {
          setTimeout(() => {
            navigate("/dashboard");
          }, 500);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error, "error");
        setErrors(error.response.data.errors);
      });
  };

  return (
    <section className="em-signup-section min-vh-100">
      {loading && <LoadingModal />}
      <div className="row em-row">
        <div className="col-4 g-0">{renderStep()}</div>
        <div className="col-8">
          <div className="em-info position-relative" style={{ top: "-400px" }}>
            <figure>
              <Link to="/" className="nav-link">
                <img
                  src={ImagePath.icLogoWithName}
                  className="img-fluid"
                  alt=""
                />
              </Link>
            </figure>
            {/* <p>
              Emergence Music Distribution was created first and foremost for
              artists to get noticed and discovered internationally. After
              observing a lack of services other digital distributions were
              offering, we decided to offer a revolutionary plan that will
              disrupt and forever change the music industry. At Emergence Music
              Distribution, each artist will be represented equally and will
              access the same promotional service as they would with a major
              label.
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignUp;

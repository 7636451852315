import React from "react";
import LoadingIcon from "./LoadingIcon";

const Loading = ({ message, progress }) => {
  return (
    <div
      style={{ zIndex: 1010 }}
      className="loading-wrapper d-flex align-items-center justify-content-center position-absolute top-0 bottom-0 w-100 bg-black bg-opacity-75"
    >
      <div className="d-flex flex-column">
        <LoadingIcon />
        <div className="d-flex align-items-center justify-content-center">
          <h5 className="text-white">{progress}%</h5>
          <h5 className="text-white">&nbsp;{message}</h5>
        </div>
      </div>
    </div>
  );
};

export default Loading;

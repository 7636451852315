import React from "react";

const Pagination = ({ current, last, pageChange }) => {
  let delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l;

  for (let i = 1; i <= last; i++) {
    if (i == 1 || i == last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return (
    <div>
      <ul className="pagination d-flex justify-content-end mt-3">
        {rangeWithDots?.map((item) => (
          <li className={`page-item ${current == item ? 'active':''}`} key={item}>
            {current == item ? (
              <button className="page-link">{item}</button>
            ) : (
              <button className="page-link" onClick={() => pageChange(item)}>{item}</button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pagination;

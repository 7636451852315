import React from "react";
import { Link } from "react-router-dom";
import { getDuration, makeSerialNumber } from "../../utils";

const Song = ({ item, serial }) => {
  // console.log(item, "item");
  return (
    <div>
      <Link to={`/musics/${item?.id}`} className="nav-link">
        <div className="em-top-release-music-info">
          <span>#{serial}</span>
          <img
            className="em-thumbnail"
            src={`${item?.track?.thumbnail}`}
            alt="music image"
          />
          <div className="em-top-release-music-profie">
            <div className="em-top-release-music-profie-info">
              <span className="text-wrap mb-2">{item?.track?.title}</span>

              <h6 className="text-wrap">{item?.artist}</h6>
            </div>
            <div className="em-top-release-music-time">
              <i className="fa-solid fa-clock"></i>
              <span id={`time${serial}`}>
                {getDuration(`${item?.track?.file}`, serial)}{" "}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Song;

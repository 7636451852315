import React, { useState } from "react";
import API from "../../api/API";
import "../../sass/Pages/_em-login.scss";
import Images from "../../assets/images";
import ErrorMessage from "../../components/ErrorMessage";
import { useNavigate, Link } from "react-router-dom";
import ImagePath from "../../constants/ImagePath";

function VerifyOtp() {
  const [credential, setCredential] = useState({
    otp: "",
  });
  const [errors, setErrors] = useState([]);

  const handleChange = (evt) => {
    const value = evt.target.value;

    setCredential({
      ...credential,
      [evt.target.name]: value,
    });
  };

  let navigate = useNavigate();
  const SubmitForm = (e) => {
    e.preventDefault();
    setErrors([]);
    API.post(`verify-otp`, credential)
      .then((res) => {
        // console.log(res.data.data)
        if (res.data.type == "success")
          navigate(
            `/update-passord/${res.data.data.otp}?email=${res.data.data.email}`
          );
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
      });
  };

  const resendOTP = () => {
    let otp_email = sessionStorage.getItem("otp_email");
    API.post(`resend-otp`, { otp_email })
      .then((res) => {
        if (res.data.data.type == "success")
          sessionStorage.setItem("otp_email", credential.email);
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
      });
  };

  return (
    <section className="em-signup-section min-vh-100">
      <div className="row em-row min-vh-100">
        <div className="col-4 g-0 min-vh-100">
          <div className="em-signup min-vh-100">
            <h3>Verification Required</h3>
            <em>
              To Continue, complete this verification step. We’ve sent a One
              Time Password (OTP) to the email Johndoe@gmail.com Please enter it
              below.
            </em>
            <div className="em-form min-vh-100">
              <div className="em-form-group">
                <label htmlFor="email">Enter OTP</label>
                <div className="em-input">
                  <input
                    onChange={handleChange}
                    name="otp"
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Enter OTP"
                  />
                </div>
                {errors.email ? <ErrorMessage message={errors.email} /> : ""}
              </div>
              <button onClick={SubmitForm} className="em-sign-in-btn">
                {" "}
                CONTINUE{" "}
              </button>
              <span className="em-account-login">
                {" "}
                Didn’t receive, <span onClick={resendOTP}>Resend OTP</span>{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="em-info">
            <figure>
              <Link to="/" className="nav-link">
                <img
                  src={ImagePath.icLogoWithName}
                  className="img-fluid"
                  alt=""
                />
              </Link>
            </figure>
            <p>
              Emergence Music Distribution was created first and foremost for
              artists to get noticed and discovered internationally. After
              observing a lack of services other digital distributions were
              offering, we decided to offer a revolutionary plan that will
              disrupt and forever change the music industry. At Emergence Music
              Distribution, each artist will be represented equally and will
              access the same promotional service as they would with a major
              label.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default VerifyOtp;

import React, { useState } from "react";
import { pdfLink } from "../../../api/API";
import ImagePath from "../../../constants/ImagePath";

const AWBTermsAndConditionModal = ({ closeModal }) => {
  const [fullWidth, setFullWidth] = useState(false);

  return (
    <div
      className="modal fade show d-block bg-black bg-opacity-50"
      id="orderConfirm"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="orderConfirmTitle"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog modal-dialog-centered ${
          fullWidth ? "mw-100" : ""
        }`}
        role="document"
      >
        <div className="modal-content bg-black text-white-50 rounded-4">
          <div className="modal-body p-4 pb-0">
            <div className="d-flex justify-content-between align-items-center pb-3 border-bottom border-dark gap-2">
              <h1 className="text-white fs-5 w-100">Terms & Conditions</h1>
              <div className="d-flex gap-2">
                <a href={pdfLink} download target="_blank" className="btn btn-dark m-0 btn-sm">
                  Download
                </a>
                <button
                  onClick={() => setFullWidth(!fullWidth)}
                  className="btn btn-dark m-0 btn-sm"
                >
                  <i className="fas fa-maximize"></i>
                </button>
                <button
                  onClick={() => closeModal(false)}
                  className="btn btn-danger m-0 btn-sm"
                >
                  x
                </button>
              </div>
            </div>

            <object className="w-100" height="400" data={`https://docs.google.com/gview?embedded=true&url=${pdfLink}`}></object>

            <div className="text-center py-3">
              <img
                width={100}
                src={ImagePath?.icLogoWithName}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AWBTermsAndConditionModal;

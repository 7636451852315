import React, { useState } from "react";
import API from "../../api/API";

import "../../sass/Pages/_em-login.scss";
import Images from "../../assets/images";
import ErrorMessage from "../../components/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { useParams, useSearchParams } from "react-router-dom";
import ImagePath from "../../constants/ImagePath";
import { Link } from "react-router-dom";

function UpdatePassword() {
  const [credential, setCredential] = useState({
    password: "",
    confirm_password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setshowConfirmPass] = useState(false);
  const [errors, setErrors] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { otp } = useParams();

  const handleChange = (evt) => {
    const value = evt.target.value;
    setCredential({
      ...credential,
      [evt.target.name]: value,
    });
  };

  const updatePass = (e) => {
    e.preventDefault();
    setErrors([]);
    let email = searchParams.get("email");

    API.post(`update-password`, { ...credential, otp, email })
      .then((res) => {
        if (res.data.type == "success") {
          navigate("/login");
        }
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
      });
  };

  return (
    <section className="em-signup-section min-vh-100">
      <div className="row em-row min-vh-100">
        <div className="col-4 g-0 min-vh-100">
          <div className="em-signup min-vh-100">
            <h3>Create New Password</h3>
            <em>We’ll ask for this password whenever you sign in.</em>
            <div className="em-form">
              <div className="em-form-group">
                <label htmlFor="password">New password</label>
                <div className="em-input">
                  <input
                    onChange={handleChange}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="form-control em-hasicon"
                    id="password"
                    placeholder="Enter password"
                  />
                  <i
                    className={`fa-solid ${
                      showPassword ? "fa-eye-slash" : "fa-eye"
                    }`}
                    onClick={() => setShowPassword(!showPassword)}
                  ></i>
                </div>
                {errors.password ? (
                  <ErrorMessage message={errors.password} />
                ) : (
                  ""
                )}
              </div>
              <div className="em-form-group">
                <label htmlFor="confirm_password">Confirm password</label>
                <div className="em-input">
                  <input
                    onChange={handleChange}
                    name="confirm_password"
                    type={showConfirmPass ? "text" : "password"}
                    className="form-control em-hasicon"
                    id="confirm_password"
                    placeholder="Enter password"
                  />
                  <i
                    className={`fa-solid ${
                      showConfirmPass ? "fa-eye-slash" : "fa-eye"
                    }`}
                    onClick={() => setshowConfirmPass(!showConfirmPass)}
                  ></i>
                </div>
                {errors.confirm_password ? (
                  <ErrorMessage message={errors.confirm_password} />
                ) : (
                  ""
                )}
              </div>
              <button onClick={updatePass} className="em-sign-in-btn">
                {" "}
                SAVE PASSWORD{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="col-8 min-vh-100">
          <div className="em-info min-vh-100">
            <figure>
              <Link to="/" className="nav-link">
                <img
                  src={ImagePath.icLogoWithName}
                  className="img-fluid"
                  alt=""
                />
              </Link>
            </figure>
            <p>
              Emergence Music Distribution was created first and foremost for
              artists to get noticed and discovered internationally. After
              observing a lack of services other digital distributions were
              offering, we decided to offer a revolutionary plan that will
              disrupt and forever change the music industry. At Emergence Music
              Distribution, each artist will be represented equally and will
              access the same promotional service as they would with a major
              label.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpdatePassword;

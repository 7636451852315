import React from "react";
import { Link, useLocation } from "react-router-dom";

const DesktopMenu = () => {
  const location = useLocation();

  return (
    <ul className="nav-menu d-none d-lg-flex desktop-menu">
    <li>
      <Link
        className={
          location.pathname === "/" ? "nav-link active" : "nav-link"
        }
        to="/"
      >
        HOME
      </Link>
    </li>
    <li>
      <Link
        className={
          location.pathname === "/services" ? "nav-link active" : "nav-link"
        }
        to="/services"
        title="PRICING"
      >
        SERVICES
      </Link>
    </li>
    <li>
      <Link
        className={
          location.pathname === "/ambassador" ? "nav-link active" : ""
        }
        to="/ambassador"
        title="AMBASSADOR"
      >
        AMBASSADOR CLUBS
      </Link>
    </li>
    <li>
      <Link
        className={
          location.pathname === "/about-us" ? "nav-link active" : ""
        }
        to="/about-us"
        title="ABOUT US"
      >
        ABOUT US
      </Link>
    </li>
    <li>
      <Link
        className={location.pathname === "/career" ? "nav-link active" : ""}
        to="/career"
        title="CAREERS"
      >
        JOIN US
      </Link>
    </li>
    <li>
      <Link
        className={location.pathname === "/news" ? "nav-link active" : ""}
        to="/news"
        title="NEWS"
      >
        NEWS
      </Link>
    </li>
  </ul>
  );
};

export default DesktopMenu;

import React, { useState } from "react";
import ErrorMessage from "../../components/ErrorMessage";
import PayPal from "../../components/PaymentMethod/PayPal";
import StripeForm from "../../components/PaymentMethod/StripeForm";
import ImagePath from "../../constants/ImagePath";

const SubscribeModal = ({ onClick, closeModal = () => {}, service }) => {
  const [cardInfo, setCardInfo] = useState({
    paymentMethod: "card",
  });
  const [errors, setErrors] = useState([]);

  const handleChange = (evt) => {
    const value = evt.target.value;
    setCardInfo({
      ...cardInfo,
      [evt.target.name]: value,
    });
  };

  return (
    <div
      className="modal fade show d-block bg-black bg-opacity-50"
      id="orderConfirm"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="orderConfirmTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content bg-black text-white-50 rounded-4">
          <div className="modal-body p-4">
            <div className="d-flex justify-content-between align-items-center mb-4 gap-2">
              <h3 className="text-white w-100 m-0 fs-5">Payment Method</h3>
              <button
                onClick={() => closeModal({})}
                className="btn-sm btn btn-danger m-0"
              >
                x
              </button>
            </div>

            <div>
              <div className="em-form m-0">
                <div className="border rounded-3 p-2">
                  <div className="em-input d-flex justify-content-between align-items-center">
                    <div>
                      <input
                        onChange={handleChange}
                        name="paymentMethod"
                        type="radio"
                        id="cr-de-card"
                        checked={
                          cardInfo?.paymentMethod === "card" ? true : false
                        }
                        value="card"
                      />
                      <label htmlFor="cr-de-card" className="position-relative">
                        Credit/Debit Card
                      </label>
                    </div>
                    <div>
                      <img src={ImagePath?.icVisaMasterCard} />
                    </div>
                  </div>

                  <div className="em-input d-flex justify-content-between align-items-center">
                    <div>
                      <input
                        onChange={handleChange}
                        name="paymentMethod"
                        type="radio"
                        id="payPal"
                        checked={
                          cardInfo?.paymentMethod === "paypal" ? true : false
                        }
                        value="paypal"
                      />
                      <label htmlFor="payPal" className="position-relative">
                        PayPal
                      </label>
                    </div>
                    <div>
                      <img src={ImagePath?.icPayPal} />
                    </div>
                  </div>
                </div>

                {errors.terms ? <ErrorMessage message={errors?.terms} /> : ""}

                <div className="em-form m-0">
                  {cardInfo?.paymentMethod === "paypal" ? (
                    <div className="pt-4">
                      <PayPal item={service} setPaymentInfo={onClick} />
                    </div>
                  ) : (
                    <StripeForm item={service} setPaymentInfo={onClick} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeModal;

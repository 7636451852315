import React from "react";
import Images from "../../assets/images";

const Album = ({item}) => {
  return (
    <div className="album-card">
      <div className="em-my-music-slider-wraper">
        <img className="img-fluid rounded" src={item?.thumbnail} alt={item?.title} />
        <div className="em-my-music-slider-info">
          <h6>{item?.title}</h6>
          <span className="text-wrap">{item?.artist}</span>
        </div>
      </div>
    </div>
  );
};

export default Album;

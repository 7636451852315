import React, { useState } from "react";
import API from "../../../api/API";

import "../../../sass/Pages/_em-login.scss";
import ErrorMessage from "../../../components/ErrorMessage";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import AWBTermsAndConditionModal from "./AWBTermsAndConditionModal";
import ACPTermsAndConditionModal from "./ACPTermsAndConditionModal";
import { countries } from "../../../api/data";

function SignUpForm({ error, nextStep, data }) {
  const [credential, setCredential] = useState({
    username: data?.signUp?.username || "",
    fullName: data?.signUp?.fullName || "",
    secondaryName: data?.signUp?.secondaryName || "",
    country: data?.signUp?.country || "pakistan",
    phoneNumber: data?.signUp?.phoneNumber || "",
    dob: data?.signUp?.dob || "",
    email: data?.signUp?.email || "",
    password: data?.signUp?.password || "",
    confirm_password: data?.signUp?.confirm_password || "",
    terms: data?.signUp?.terms || false,
  });

  const [openAWBModal, setOpenAWBModal] = useState(false);
  const [openACPModal, setOpenACPModal] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleChange = (evt) => {
    const value = evt.target.value;
    setCredential({
      ...credential,
      [evt.target.name]: value,
    });
  };

  const handelTermsAndCondition = () => {
    setCredential({
      ...credential,
      terms: !credential?.terms,
    });
  };

  const nextSubmit = (e) => {
    e.preventDefault();

    setErrors([]);
    let error_list = [];
    Object.keys(credential).forEach(function (key, index) {
      if (credential[key] == "") error_list[key] = ["This field id required"];
    });

    if (credential?.password != credential?.confirm_password) {
      error_list["password"] = ["Password does not match"];
    }

    if (Object.keys(error_list).length) {
      setErrors(error_list);
      return;
    }
    nextStep({ signUp: credential }, 2);
  };

  return (
    <div className="em-signup min-vh-100">
      <h3>Welcome to Emergence</h3>
      <em>Enter your email address and password to access your account.</em>
      <div className="em-form">
        <div className="em-form-group">
          <label htmlFor="name">Username</label>
          <div className="em-input">
            <input
              onChange={handleChange}
              name="username"
              type="text"
              className="form-control"
              id="username"
              placeholder="Enter user username"
            />
          </div>
          {errors.username ? <ErrorMessage message={errors.username} /> : ""}
        </div>
        <div className="em-form-group">
          <label htmlFor="email">Email address</label>
          <div className="em-input">
            <input
              onChange={handleChange}
              name="email"
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email address"
            />
          </div>
          {errors.email ? <ErrorMessage message={errors.email} /> : ""}
        </div>

        <div className="em-form-group">
          <label htmlFor="fullName">Full Name</label>
          <div className="em-input">
            <input
              onChange={handleChange}
              name="fullName"
              type="text"
              className="form-control"
              id="fullName"
              placeholder="Enter full name"
            />
          </div>
          {errors.fullName ? <ErrorMessage message={errors.fullName} /> : ""}
        </div>
        <div className="em-form-group">
          <label htmlFor="secondaryName">
            Artist / Company / Organization / Other Name
          </label>
          <div className="em-input">
            <input
              onChange={handleChange}
              name="secondaryName"
              type="text"
              className="form-control"
              id="secondaryName"
              placeholder="Enter name any one of the above"
            />
          </div>
          {errors.secondaryName ? (
            <ErrorMessage message={errors.secondaryName} />
          ) : (
            ""
          )}
        </div>

        <div className="em-form-group">
          <label htmlFor="dob">Date of Birth</label>
          <div className="em-input">
            <input
              onChange={handleChange}
              name="dob"
              type="date"
              className="form-control"
              id="dob"
            />
          </div>
          {errors.dob ? <ErrorMessage message={errors.dob} /> : ""}
        </div>

        <div className="em-form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <div className="em-input">
            <input
              onChange={handleChange}
              name="phoneNumber"
              type="text"
              className="form-control"
              id="phoneNumber"
              placeholder="Enter phone number"
            />
          </div>
          {errors.phoneNumber ? (
            <ErrorMessage message={errors.phoneNumber} />
          ) : (
            ""
          )}
        </div>

        <div className="em-form-group">
          <label htmlFor="country">Country</label>
          <div className="em-input">
            <select
              onChange={handleChange}
              name="country"
              className="form-select"
              defaultValue={credential?.country}
            >
              {countries?.map((country) => (
                <option
                  key={country?.name}
                  value={country?.name?.toLowerCase()}
                >
                  {country?.name}
                </option>
              ))}
            </select>
          </div>
          {errors.country ? <ErrorMessage message={errors.country} /> : ""}
        </div>

        <div className="em-form-group">
          <label htmlFor="password">Password</label>
          <div className="em-input">
            <input
              onChange={handleChange}
              name="password"
              type={showPassword ? "text" : "password"}
              className="form-control em-hasicon"
              id="password"
              placeholder="Enter password"
            />
            <i
              className={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
              onClick={() => setShowPassword(!showPassword)}
            ></i>
          </div>
          {errors.password ? <ErrorMessage message={errors.password} /> : ""}
        </div>
        <div className="em-form-group">
          <label htmlFor="confirm_password">Confirm password</label>
          <div className="em-input">
            <input
              onChange={handleChange}
              name="confirm_password"
              type={showConfirmPass ? "text" : "password"}
              className="form-control em-hasicon"
              id="confirm_password"
              placeholder="Enter password"
            />
            <i
              className={`fa-solid ${
                showConfirmPass ? "fa-eye-slash" : "fa-eye"
              }`}
              onClick={() => setShowConfirmPass(!showConfirmPass)}
            ></i>
          </div>
          {errors.confirm_password ? (
            <ErrorMessage message={errors.confirm_password} />
          ) : (
            ""
          )}
        </div>

        <div className="em-input d-flex justify-content-between align-items-center">
          <div>
            <input
              onChange={handelTermsAndCondition}
              name="terms"
              type="checkbox"
              id="terms"
              checked={credential?.terms}
              value="terms"
              className="d-none"
            />
            <label
              htmlFor="terms"
              className="position-relative py-3 d-flex align-baseline gap-1"
            >
              {credential?.terms ? (
                <i className="fas fa-check-square text-danger position-relative start-0 top-0"></i>
              ) : (
                <i className="fas fa-square position-relative start-0 top-0"></i>
              )}{" "}
              <span className="px-1 small">
                I agree to the Terms & Conditions for{" "}
                <a
                  href="#"
                  role="button"
                  className=""
                  style={{ color: "#ef3c5a" }}
                  onClick={() => setOpenAWBModal(true)}
                >
                  Artists with Benefits
                </a>{" "}
                and{" "}
                <a
                  href="#"
                  role="button"
                  className=""
                  style={{ color: "#ef3c5a" }}
                  onClick={() => setOpenACPModal(true)}
                >
                  Ambassador Clubs Program
                </a>
                .
              </span>
            </label>
          </div>
        </div>
        {errors.terms ? <ErrorMessage message={errors?.terms} /> : ""}

        <button onClick={nextSubmit} className="em-sign-in-btn">
          {" "}
          NEXT{" "}
        </button>
        <span className="em-account-login">
          Already have an account?{" "}
          <Link to="/login" className="em-Sign-up-btn">
            {" "}
            Log in
          </Link>
        </span>
      </div>
      {openAWBModal && (
        <AWBTermsAndConditionModal closeModal={setOpenAWBModal} />
      )}
      {openACPModal && (
        <ACPTermsAndConditionModal closeModal={setOpenACPModal} />
      )}
    </div>
  );
}

export default SignUpForm;

import React, { useState } from "react";
import { primaryGenre, secondaryGenre } from "../../api/data";
import ErrorMessage from "../../components/ErrorMessage";

// Release Genre
function TrackGenres({ errorList, prevStep, nextStep, data }) {
  const [errors, setErrors] = useState([]);
  const [genres, setGenres] = useState({
    primaryGenre: "Alternative",
    secondaryGenre: "No",
    language: "Hindi",
    albumFormat: "Single",
  });

  const requiredFields = [
    "primaryGenre",
    "language",
    "albumFormat",
  ];

  const handleChange = (input) => (evt) => {
    const value = evt.target.value;
    setGenres({
      ...genres,
      [input]: value,
    });
  };

  const nextSubmit = (e) => {
    e.preventDefault();
    setErrors([]);
    let error_list = [];
    Object.keys(genres).forEach(function (key, index) {
      if (
        requiredFields.indexOf(key) !== -1 &&
        (genres[key] === "" || genres[key] == null)
      ) {
        error_list[key] = ["This field id required"];
      }
    });
    if (Object.keys(error_list).length) {
      console.log(error_list);
      setErrors(error_list);
      return;
    }

    nextStep({ genres }, data?.trackType);
  };

  return (
    <div className="em-db-content">
      <ul className="em-track-title">
        <h5 className="title mt-3">Release Genres</h5>
        <li>
          <label htmlFor="">Primary genre</label>
          <small className="text-muted d-block">
            <em>
              Choose one of the genres from the list provided. This will be the
              primary genre used by the channels where your release is
              available.
            </em>
          </small>
          <select
            onChange={handleChange("primaryGenre")}
            className="form-select"
            defaultValue={genres?.primaryGenre}
          >
            {primaryGenre?.map((pGenre, index) => (
              <option value={pGenre?.name} key={index}>
                {pGenre?.name}
              </option>
            ))}
          </select>
          {errors.primaryGenre ? (
            <ErrorMessage message={errors.primaryGenre} />
          ) : (
            ""
          )}
        </li>
        <li>
          <label htmlFor="">Secondary Genre (optional)</label>
          <select
            onChange={handleChange("secondaryGenre")}
            className="form-select"
            defaultValue={genres?.secondaryGenre}
          >
            {secondaryGenre?.map((sGenre, index) => (
              <option value={sGenre?.name} key={index * 0.2}>
                {sGenre?.name}
              </option>
            ))}
          </select>

          {errors.secondaryGenre ? (
            <ErrorMessage message={errors.secondaryGenre} />
          ) : (
            ""
          )}
        </li>

        <li>
          <label htmlFor="">Language</label>
          <small className="text-muted d-block">
            <em>
              Indicate in which language the lyrics of this release are written
              (if applicable)
            </em>
          </small>
          <select
            onChange={handleChange("language")}
            className="form-select"
            data-placeholder="Choose a Language..."
            defaultValue={genres?.language}
          >
            <option value="Afrikaans">Afrikaans</option>
            <option value="Albanian">Albanian</option>
            <option value="Arabic">Arabic</option>
            <option value="Armenian">Armenian</option>
            <option value="Basque">Basque</option>
            <option value="Bengali">Bengali</option>
            <option value="Bulgarian">Bulgarian</option>
            <option value="Catalan">Catalan</option>
            <option value="Cambodian">Cambodian</option>
            <option value="Chinese (Mandarin)">Chinese (Mandarin)</option>
            <option value="Croatian">Croatian</option>
            <option value="Czech">Czech</option>
            <option value="Danish">Danish</option>
            <option value="Dutch">Dutch</option>
            <option value="English">English</option>
            <option value="Estonian">Estonian</option>
            <option value="Fiji">Fiji</option>
            <option value="Finnish">Finnish</option>
            <option value="French">French</option>
            <option value="Georgian">Georgian</option>
            <option value="German">German</option>
            <option value="Greek">Greek</option>
            <option value="Gujarati">Gujarati</option>
            <option value="Hebrew">Hebrew</option>
            <option value="Hindi">Hindi</option>
            <option value="Hungarian">Hungarian</option>
            <option value="Icelandic">Icelandic</option>
            <option value="Indonesian">Indonesian</option>
            <option value="Irish">Irish</option>
            <option value="Italian">Italian</option>
            <option value="Japanese">Japanese</option>
            <option value="Javanese">Javanese</option>
            <option value="Korean">Korean</option>
            <option value="Latin">Latin</option>
            <option value="Latvian">Latvian</option>
            <option value="Lithuanian">Lithuanian</option>
            <option value="Macedonian">Macedonian</option>
            <option value="Malay">Malay</option>
            <option value="Malayalam">Malayalam</option>
            <option value="Maltese">Maltese</option>
            <option value="Maori">Maori</option>
            <option value="Marathi">Marathi</option>
            <option value="Mongolian">Mongolian</option>
            <option value="Nepali">Nepali</option>
            <option value="Norwegian">Norwegian</option>
            <option value="Persian">Persian</option>
            <option value="Polish">Polish</option>
            <option value="Portuguese">Portuguese</option>
            <option value="Punjabi">Punjabi</option>
            <option value="Quechua">Quechua</option>
            <option value="Romanian">Romanian</option>
            <option value="Russian">Russian</option>
            <option value="Samoan">Samoan</option>
            <option value="Serbian">Serbian</option>
            <option value="Slovak">Slovak</option>
            <option value="Slovenian">Slovenian</option>
            <option value="Spanish">Spanish</option>
            <option value="Swahili">Swahili</option>
            <option value="Swedish ">Swedish </option>
            <option value="Tamil">Tamil</option>
            <option value="Tatar">Tatar</option>
            <option value="Telugu">Telugu</option>
            <option value="Thai">Thai</option>
            <option value="Tibetan">Tibetan</option>
            <option value="Tonga">Tonga</option>
            <option value="Turkish">Turkish</option>
            <option value="Ukrainian">Ukrainian</option>
            <option value="Urdu">Urdu</option>
            <option value="Uzbek">Uzbek</option>
            <option value="Vietnamese">Vietnamese</option>
            <option value="Welsh">Welsh</option>
            <option value="Xhosa">Xhosa</option>
          </select>
          {errors.language ? <ErrorMessage message={errors.language} /> : ""}
        </li>

        <li>
          <label htmlFor="">Album Format</label>
          <small className="text-muted d-block">
            <em>
              Select the relevant description that applies to this release
              (Single, EP, Album…). This information may be used by some
              channels for pricing and chart reporting.
            </em>
          </small>
          <select
            onChange={handleChange("albumFormat")}
            className="form-select"
            data-placeholder="Choose a album format..."
            defaultValue={genres?.albumFormat}
          >
            <option value="Single">Single</option>
            <option value="Ep">Ep</option>
            <option value="Album">Album</option>
          </select>
          {errors.albumFormat ? (
            <ErrorMessage message={errors.albumFormat} />
          ) : (
            ""
          )}
        </li>
      </ul>
      <ul className="em-upload-btn">
        <li>
          <button onClick={prevStep} className="em-upoad-btn">
            BACK
          </button>
        </li>
        <li>
          <button onClick={nextSubmit} className="em-upoad-btn">
            NEXT
          </button>
        </li>
      </ul>
    </div>
  );
}

export default TrackGenres;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import API from "../../api/API";
import Images from "../../assets/images";
import Album from "../../components/Music/Album";
import SideBar from "../../components/SideBar";

function Albums() {
  const [myAlbums, setMyAlbums] = useState([]);
  useEffect(() => {
    const cookies = new Cookies();
    let user_id = cookies.get("user_id");
    API.get(`albums/list/${user_id}?page=1`).then((res) => {
      // console.log(res, "res");
      setMyAlbums(res?.data?.data);
    });
  }, []);

  return (
    <>
      <SideBar />
      <div className="em-db-content home-section">
        <div className="em-db-content-title">
          <h2>My EP / Albums</h2>
        </div>
        <div className="em-db-content-wraper m-0">
          <div className={`${myAlbums?.length > 0 ? "em-my-music" : ""}`}>
            {myAlbums && myAlbums?.length > 0 ? (
              <div className="row">
                {myAlbums?.map((item, index) => (
                  <div
                    style={{ width: "calc(100%  / 5)" }}
                    className="mb-4 album-card-wrapper"
                    key={index}
                  >
                    <Link to={`/albums/${item?.id}`} className="nav-link">
                      <Album item={item} />
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <div className="empty py-3">
                <i className="bx bx-album"></i>
                <p className="m-0">Empty list!</p>
                <p className="m-0">You have no ep/albums at this moment.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Albums;

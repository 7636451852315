import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { toastMessage } from "../../utils";
import Cookies from "universal-cookie";
import { useEffect } from "react";

const PayPal = ({ item = {}, setPaymentInfo }) => {
  const [authUser, setAuthUser] = useState({ name: "", accountType: "" });
  const handelPaymentInfo = (details) => {
    setPaymentInfo(details);
    toastMessage("success", "Payment Success");
  };

  useEffect(() => {
    const cookies = new Cookies();
    setAuthUser({
      name: cookies.get("user_name"),
      accountType: cookies.get("account_type"),
    });
  }, []);

  const price = () => {
    if (authUser?.accountType === "free") {
      return parseFloat(item?.price) !== parseFloat(0)
        ? parseFloat(item?.price - item?.price * (25 / 100)).toFixed(2)
        : 1;
    } else {
      return parseFloat(item?.price) !== parseFloat(0)
        ? parseFloat(item?.price - item?.price * (15 / 100)).toFixed(2)
        : 1;
    }
  };

  return (
    <>
      <ToastContainer />
      {/* {console.log(process.env, "process env")} */}

      <PayPalScriptProvider
        options={{
          // "client-id": `${process.env.REACT_APP_DEV_PAYPAL_CLIENT_ID}`,
          "client-id": `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
          currency: "USD",
          "disable-funding": "card",
        }}
      >
        <PayPalButtons
          style={{
            layout: "vertical",
            // label: "subscribe",
            shape: "pill",
            color: "white",
          }}
          // onClick={(data, actions) => {
          //   const hasAlreadySubscribe = false;
          //   if (hasAlreadySubscribe) {
          //     console.log("You already subscribe");
          //     return actions.reject();
          //   }
          // }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    // value: 12.74,
                    value: price(),
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              handelPaymentInfo(details);
              // console.log(details, "details");
            });
          }}
          onCancel={() => {}}
          onError={(error) => {
            console.log(`Error: ${error}`);
          }}
        />
      </PayPalScriptProvider>
    </>
  );
};

export default PayPal;

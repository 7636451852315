import React, { useState } from "react";

import API from "../../api/API";
import ErrorMessage from "../../components/ErrorMessage";
import Images from "../../assets/images";
// Release detail
function Step1({ errorList, nextStep }) {
  const [errors, setErrors] = useState([]);

  const [singleMusic, setSingleMusic] = useState({
    trackType: "",
  });

  const nextSubmit = (e) => {
    e.preventDefault();
    setErrors([]);
    let error_list = [];
    Object.keys(singleMusic).forEach(function (key, index) {
      if (singleMusic[key] == "") error_list[key] = ["This field id required"];
    });

    if (Object.keys(error_list).length) {
      setErrors(error_list);
      return;
    }
    nextStep(singleMusic);
  };

  return (
    <div className="em-db-content">
      <div className="em-upload-track pt-0">
        <h5>Release Details</h5>
        <ul className="em-upload-track-info">
          <li className="w-100">
            <div
              onClick={() => {
                setSingleMusic({ ...singleMusic, trackType: "single" });
              }}
              className={`em-upload-single-track ${
                singleMusic.trackType == "single" ? "active" : ""
              }`}
            >
              <img src={Images.em_uplaod_msc}></img>
              <h4>Single</h4>
              <p>SINGLE (7 min max, audio file WAV and FLAC)</p>
              <p className="m-0">&nbsp;</p>
            </div>
          </li>
          <li className="w-100">
            <div
              onClick={() =>
                setSingleMusic({ ...singleMusic, trackType: "album" })
              }
              className={`em-upload-single-track ${
                singleMusic.trackType == "album" ? "active" : ""
              }`}
            >
              <img src={Images.em_uplaod_msc2}></img>
              <h4>My EP / Album</h4>
              <p>ALBUM (45 min max, audio file WAV and FLAC)</p>
              <p className="m-0">EP (20 min max, audio file WAV and FLAC)</p>
            </div>
          </li>
        </ul>
        {errors.trackType ? <ErrorMessage message={errors.trackType} /> : ""}

        <ul className="em-upload-btn">
          <li>
            <button onClick={nextSubmit} className="em-upoad-btn">
              NEXT
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Step1;

import React from "react";
import ImagePath from "../../../constants/ImagePath";

const ConfirmModal = ({ nextSubmit }) => {
  return (
    <div
      className="modal fade show d-block bg-black bg-opacity-50"
      id="orderConfirm"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="orderConfirmTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content bg-black text-center rounded-4">
          <div className="modal-body p-5">
            <img src={ImagePath.icAt} className="img-fluid" />
            <h3 className="py-5">Confirm your email address</h3>
            <button
              onClick={(e) => nextSubmit(e)}
              className="em-sign-in-btn active m-0"
            >
              Finish and Go to dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../api/API";
import { getDuration } from "../../utils";
import ReactAudioPlayer from "react-audio-player";
import ImagePath from "../../constants/ImagePath";
import SideBar from "../../components/SideBar";

const MusicDetail = () => {
  const { id } = useParams();
  const [song, setSong] = useState({});
  const navigate = useNavigate();
  const [selectedTruck, setSelectedTruck] = useState("");
  useEffect(() => {
    // const cookies = new Cookies();
    // let user_id = cookies.get("user_id");
    API.get(`musics/details/${id}`).then((res) => {
      // console.log(res, "res");
      setSong(res?.data?.data);
    });
  }, []);

  const handelTruckPlay = (id, file) => {
    selectedTruck?.file == file
      ? setSelectedTruck({})
      : setSelectedTruck({ id, file });
  };
  return (
    <>
      <SideBar />
      <div className="em-db-content home-section position-relative">
        <Link
          className="d-flex align-items-center text-white nav-link"
          onClick={() => navigate(-1)}
          to="#"
          type="button"
        >
          <img src={ImagePath?.icBackArrow} className="img-fluid" />
          <h3 className="mx-3 my-0 text-capitalize">{song?.track?.title}</h3>
        </Link>

        <div className="row py-3 py-md-5">
          <div className="col-12 col-md-4 pb-5 pb-md-0 text-center">
            <img className="img-fluid rounded" src={song?.track?.thumbnail} />
          </div>
          <div className="col-12 col-md-8">
            <div className="em-top-release-music-info">
              <div className="row">
                <div className="col-6 mb-4">
                  <h6>Track Title</h6>
                  <span className="text-white mt-2 display-3">
                    {song?.track?.title}
                  </span>
                </div>
                <div className="col-6 mb-4">
                  <h6>Primary Artist</h6>
                  <span className="text-white mt-2 display-3">
                    {song?.artist}
                  </span>
                </div>
                <div className="col-6 mb-4">
                  <h6>Featured Artist</h6>
                  <span className="text-white mt-2 display-3">
                    {song?.featuredArtist}
                  </span>
                </div>
                <div className="col-6 mb-4">
                  <h6>Label</h6>
                  <span className="text-white mt-2 display-3">
                    {song?.label}
                  </span>
                </div>
                <div className="col-6 mb-4">
                  <h6>Copyright Holder</h6>
                  <span className="text-white mt-2 display-3">
                    {song?.copyrightHolder}
                  </span>
                </div>
                <div className="col-6 mb-4">
                  <h6>Copyright Year</h6>
                  <span className="text-white mt-2 display-3">
                    {song?.copyrightYear}
                  </span>
                </div>
                <div className="col-6 mb-4">
                  <h6>Record Label</h6>
                  {/* <span className="text-white mt-2 display-3">
                  {song?.recordLabel}
                </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row py-3 py-md-5">
          <div className="col-12">
            <ul className="em-top-release-music-wraper m-0">
              {/* {!song?.isSingleTruck &&
              song?.file_url &&
              song?.file_url?.length > 0 &&
              song?.file_url?.map((file, index) => (
                <li className="active" key={index}>
                  <div
                    className={`em-top-release-music-info ${
                      selectedTruck?.file == file ? "active" : ""
                    }`}
                  >
                    <span onClick={() => handelTruckPlay(song?.id, file)}>
                      {Object.keys(selectedTruck)?.length > 0 && selectedTruck?.file == file ? (<i className='bx bx-pause bx-md'></i>) : (<i className='bx bx-play bx-md'></i>)}
                    </span>
                    <img
                      className="em-thumbnail rounded"
                      src={`${song?.thubnail}`}
                      alt="music image"
                    />
                    <div className="em-top-release-music-profie">
                      <div className="em-top-release-music-profie-info">
                        <h6>{song?.title}</h6>
                        <span>{song?.artisit}</span>
                      </div>
                      <div className="em-top-release-music-time">
                        <i className="fa-solid fa-clock"></i>
                        <span id={`time${index}`}>
                          {getDuration(`${file}`, index)}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              ))} */}

              <li>
                <div
                  className={`em-top-release-music-info ${
                    selectedTruck?.id == song?.id ? "active" : ""
                  }`}
                >
                  <span
                    onClick={() => handelTruckPlay(song?.id, song?.track?.file)}
                  >
                    {Object.keys(selectedTruck)?.length > 0 &&
                    selectedTruck?.file == song?.track?.file ? (
                      <i className="bx bx-pause bx-md"></i>
                    ) : (
                      <i className="bx bx-play bx-md"></i>
                    )}
                  </span>
                  <img
                    className="em-thumbnail rounded"
                    src={`${song?.track?.thumbnail}`}
                    alt="music image"
                  />
                  <div className="em-top-release-music-profie">
                    <div className="em-top-release-music-profie-info">
                      <span className="text-wrap mb-2">
                        {song?.track?.title}
                      </span>
                      <h6 className="text-wrap">{song?.artist}</h6>
                    </div>
                    <div className="em-top-release-music-time">
                      <i className="fa-solid fa-clock"></i>
                      <span id={`time${1}`}>
                        {getDuration(`${song?.track?.file}`, 1)}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {Object.keys(selectedTruck).length > 0 && (
          <div className="position-fixed bottom-0 start-50 translate-middle-x w-25 px-2">
            <ReactAudioPlayer
              className="w-100"
              src={selectedTruck?.file}
              autoPlay
              controls
              preload
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MusicDetail;

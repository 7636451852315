import React, { useEffect, useState } from "react";
import API from "../../api/API";
import LoadingIcon from "../../components/LoadingIcon";
import ServiceCard from "../../components/ServiceCard";

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    API.get(`services/?page=1`)
      .then((res) => {
        setLoading(false);
        setServices(res?.data?.data);
      })
      .catch((error) => {
        console.error(error, "Error");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div className="em-db-content-title">
        <h2>Artist Development Services</h2>
      </div>

      <div className="em-db-content-wraper position-relative">
        {loading ? (
          <div className="py-5">
            <LoadingIcon />
          </div>
        ) : (
          <div className="em-my-music">
            {services && services?.length > 0 && (
              <div className="row">
                {services?.map((item, index) => (
                  <div className="col-md-4 mb-4" key={item?.id}>
                    <ServiceCard item={item} />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Services;

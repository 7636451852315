import React, { useState } from 'react'
import API from "../../api/API";
import '../../sass/Pages/_em-login.scss';
import Images from '../../assets/images';
import ErrorMessage from '../../components/ErrorMessage';
import { useNavigate } from "react-router-dom";
import ImagePath from '../../constants/ImagePath';
import { Link } from 'react-router-dom';

function ResetPassword() {
    const [credential, setCredential ] = useState({
        email:'',
    });
    const [errors, setErrors] = useState([]);
    const handleChange = (evt) => {
        const value = evt.target.value;
        setCredential({
          ...credential,
          [evt.target.name]: value,
        });
    };
    
    let navigate = useNavigate();
    const SubmitForm = (e) =>{
        e.preventDefault();
        setErrors([]);
        API.post(`reset-password`, credential).then((res) => {
            if(res.data.data.type == 'success')
            sessionStorage.setItem("otp_email", credential.email);
            navigate(`/verify-otp`);
                // navigate(`/update-passord/${res.data.data.otp}?email=${credential.email}`);
        }).catch(error => {
            setErrors(error.response.data.errors)
        });
    }

    return (
        <section className="em-signup-section min-vh-100">
            <div className="row em-row">
                <div className="col-4 g-0">
                    <div className="em-signup">
                        <h3>Enter email address</h3>
                        <em>We will send you a reset password link to your email address.</em>
                        <div className="em-form">
                            <div className="em-form-group">
                                <label htmlFor="email">Email address</label>
                                <div className="em-input">
                                    <input onChange={handleChange} name="email" type="email" className="form-control" id="email" placeholder="Enter email address"/> <i className="em-accessible"></i>
                                </div>
                                { errors.email ? <ErrorMessage message={errors.email}/> : ''}
                            </div>
                            <button onClick={SubmitForm} className="em-sign-in-btn"> RESET PASSWORD </button>
                        </div>
                    </div>
                </div>
                <div className="col-8">
                    <div className="em-info">
                    <figure><Link to="/" className="nav-link"><img src={ImagePath.icLogoWithName} className="img-fluid" alt="" /></Link></figure>
                        <p> <i className='em-letter-h'></i> Emergence Music Distribution was created first and foremost for artists to get noticed and discovered internationally. After observing a lack of services other digital distributions were offering, we decided to offer a revolutionary plan that will disrupt and forever change the music industry. At Emergence Music Distribution, each artist will be represented equally and will access the same promotional service as they would with a major label.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ResetPassword

import React, { useEffect, useState } from "react";
import API from "../../api/API";

import "../../sass/Pages/_em-login.scss";
import ErrorMessage from "../../components/ErrorMessage";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toastMessage } from "../../utils";
import { countries } from "../../api/data";
import SideBar from "../../components/SideBar";

function Settings({ setUserToken }) {
  const [credential, setCredential] = useState({
    username: "",
    fullName: "",
    secondaryName: "",
    country: "",
    phoneNumber: "",
    dob: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  let navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setshowConfirmPass] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleChange = (evt) => {
    const { name, value } = evt.target;

    setCredential((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const register = (e) => {
    e.preventDefault();
    setErrors([]);
    let error_list = [];
    if (credential?.password || credential?.confirm_password) {
      if (credential?.confirm_password != credential?.password) {
        error_list["password"] = ["Password does not match"];
      }
    }

    if (Object.keys(error_list).length) {
      setErrors(error_list);
      return;
    }

    const formData = new FormData();
    formData.append("username", credential.username);
    formData.append("fullName", credential.fullName);
    formData.append("secondaryName", credential.secondaryName);
    formData.append("dob", credential.dob);
    formData.append("country", credential.country);
    formData.append("phoneNumber", credential.phoneNumber);
    formData.append("email", credential.email);
    formData.append("password", credential.password);
    formData.append("confirm_password", credential.confirm_password);

    API.post(`update-me`, formData)
      .then((res) => {
        const cookies = new Cookies();
        // console.log(res, "update");
        cookies.set("user_name", res.data?.data?.name);
        toastMessage("success", "Update Successfully");
      })
      .catch((error) => {
        toastMessage("error", "Please try again.");
        setErrors(error.response.data.errors);
      });
  };

  useEffect(() => {
    const cookies = new Cookies();
    let user_id = cookies.get("user_id");
    API.get(`user/me/${user_id}`)
      .then((res) => {
        console.log(res, "res");
        setCredential((prevState) => ({
          ...prevState,
          username: res?.data?.data?.username,
          fullName: res?.data?.data?.fullName,
          secondaryName: res?.data?.data?.secondaryName,
          dob: res?.data?.data?.dob,
          phoneNumber: res?.data?.data?.phoneNumber,
          country: res?.data?.data?.country,
          email: res?.data?.data?.email,
        }));
      })
      .catch((error) => {
        setErrors(error.response.data.message);
      });
  }, []);

  return (
    <>
      <SideBar />
      <section id="setting">
        <ToastContainer />
        <div className="em-db-content home-section">
          <div className="em-db-content-wraper m-0">
            <div className="em-top-release-music m-0">
              <h5>Settings</h5>
              <div className="em-form">
                <div className="em-form-group">
                  <label style={{ color: "#a1a1a1" }} htmlFor="username">
                    Username
                  </label>
                  <div className="em-input">
                    <input
                      onChange={handleChange}
                      name="username"
                      type="text"
                      className="form-control m-0"
                      id="username"
                      placeholder="Enter username"
                      value={credential?.username}
                    />
                  </div>
                  {errors.username ? (
                    <ErrorMessage message={errors.username} />
                  ) : (
                    ""
                  )}
                </div>

                <div className="em-form-group">
                  <label style={{ color: "#a1a1a1" }} htmlFor="email">
                    Email address
                  </label>
                  <div className="em-input">
                    <p className="form-control m-0">{credential?.email}</p>
                    {/* <input
                    onChange={handleChange}
                    name="email"
                    type="email"
                    className="form-control m-0"
                    id="email"
                    placeholder="Enter email address"
                    value={credential?.email}
                    readOnly
                  /> */}
                  </div>
                  {errors.email ? <ErrorMessage message={errors.email} /> : ""}
                </div>

                <div className="em-form-group">
                  <label style={{ color: "#a1a1a1" }} htmlFor="fullName">
                    Full Name
                  </label>
                  <div className="em-input">
                    <input
                      onChange={handleChange}
                      name="fullName"
                      type="text"
                      className="form-control m-0"
                      id="fullName"
                      placeholder="Enter full name"
                      value={credential?.fullName}
                    />
                  </div>
                  {errors.fullName ? (
                    <ErrorMessage message={errors.fullName} />
                  ) : (
                    ""
                  )}
                </div>

                <div className="em-form-group">
                  <label htmlFor="secondaryName">
                    Artist / Company / Organization / Other Name
                  </label>
                  <div className="em-input">
                    <input
                      onChange={handleChange}
                      name="secondaryName"
                      type="text"
                      className="form-control m-0"
                      id="secondaryName"
                      placeholder="Enter name any one of the above"
                      value={credential?.secondaryName}
                    />
                  </div>
                  {errors.secondaryName ? (
                    <ErrorMessage message={errors.secondaryName} />
                  ) : (
                    ""
                  )}
                </div>

                <div className="em-form-group">
                  <label htmlFor="dob">Date of Birth</label>
                  <div className="em-input">
                    <p className="form-control m-0">{credential?.dob}</p>
                    {/* <input
                    onChange={handleChange}
                    name="dob"
                    type="date"
                    className="form-control m-0"
                    id="dob"
                    value={credential?.dob}
                  /> */}
                  </div>
                  {errors.dob ? <ErrorMessage message={errors.dob} /> : ""}
                </div>

                <div className="em-form-group">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <div className="em-input">
                    <input
                      onChange={handleChange}
                      name="phoneNumber"
                      type="text"
                      className="form-control m-0"
                      id="phoneNumber"
                      placeholder="Enter phone number"
                      value={credential?.phoneNumber}
                    />
                  </div>
                  {errors.phoneNumber ? (
                    <ErrorMessage message={errors.phoneNumber} />
                  ) : (
                    ""
                  )}
                </div>

                <div className="em-form-group">
                  <label htmlFor="country">Country</label>
                  <div className="em-input">
                    <select
                      onChange={handleChange}
                      name="country"
                      className="form-select"
                      defaultValue={credential?.country}
                    >
                      {countries?.map((country) => (
                        <option
                          selected={
                            country?.name?.toLowerCase() === credential?.country
                          }
                          key={country?.name}
                          value={country?.name?.toLowerCase()}
                        >
                          {country?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.country ? (
                    <ErrorMessage message={errors.country} />
                  ) : (
                    ""
                  )}
                </div>

                <div className="em-form-group">
                  <label style={{ color: "#a1a1a1" }} htmlFor="password">
                    Password
                  </label>
                  <div className="em-input">
                    <input
                      onChange={handleChange}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className="form-control em-hasicon"
                      id="password"
                      placeholder="Enter password"
                      value={credential?.password}
                    />
                    <i
                      className={`fa-solid ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  </div>
                  {errors.password ? (
                    <ErrorMessage message={errors.password} />
                  ) : (
                    ""
                  )}
                </div>
                <div className="em-form-group">
                  <label
                    style={{ color: "#a1a1a1" }}
                    htmlFor="confirm_password"
                  >
                    Confirm password
                  </label>
                  <div className="em-input">
                    <input
                      onChange={handleChange}
                      name="confirm_password"
                      type={showConfirmPass ? "text" : "password"}
                      className="form-control em-hasicon"
                      id="confirm_password"
                      placeholder="Enter password"
                      value={credential?.confirm_password}
                    />
                    <i
                      className={`fa-solid ${
                        showConfirmPass ? "fa-eye-slash" : "fa-eye"
                      }`}
                      onClick={() => setshowConfirmPass(!showConfirmPass)}
                    ></i>
                  </div>
                  {errors.confirm_password ? (
                    <ErrorMessage message={errors.confirm_password} />
                  ) : (
                    ""
                  )}
                </div>
                {/* <button onClick={register} className="em-sign-in-btn">
                {" "}
                UPDATE{" "}
              </button> */}
              </div>
            </div>
            <ul className="em-upload-btn">
              <li>
                <button onClick={register} className="em-upoad-btn active">
                  UPDATE
                </button>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Settings;

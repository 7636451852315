import React, { useState } from "react";
import ErrorMessage from "../../components/ErrorMessage";

// Distribution stores
function TrackScheduleForm({ errorList, prevStep, nextStep, data }) {
  const [errors, setErrors] = useState([]);
  const [schedule, setSchedule] = useState({
    isSingleTrack: "0",
    digitalReleaseDate: "",
    originalReleaseDate: "",
    licenseType: "Copyright",
    creativeCommon: "Share alike",
    licenseHolder: "",
    copyright: "",
    // releasingType: "pre_release",
  });

  const handleChange = (input) => (evt) => {
    const value = evt.target.value;
    setSchedule({
      ...schedule,
      [input]: value,
    });
    // console.log(schedule, value, input);
  };

  const nextSubmit = (e) => {
    e.preventDefault();
    setErrors([]);
    let error_list = [];
    Object.keys(schedule).forEach(function (key, index) {
      if (schedule[key] === "") error_list[key] = ["This field id required"];
    });

    if (Object.keys(error_list).length) {
      setErrors(error_list);
      return;
    }

    nextStep({ schedule: schedule }, data?.trackType);
  };

  return (
    <div className="em-db-content">
      <div className="em-track-title">
        <ul className="em-track-title-wrap">
          <h5 className="title mt-3">Schedule</h5>
          <li>
            <label htmlFor="">Has this Single Track release before</label>
            <select
              onChange={handleChange("isSingleTrack")}
              className="form-select"
              defaultValue={schedule?.isSingleTrack}
            >
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
            {errors.isSingleTrack ? (
              <ErrorMessage message={errors.isSingleTrack} />
            ) : (
              ""
            )}
          </li>
          <li>
            <label htmlFor="">Digital Release Date</label>
            <small className="text-muted d-block">
              <em>
                Select the actual date when you want your release to be
                available for digital sale in the territories specified. Take
                into account that we deliver your release within 24-48 hours to
                the channels you select. But stores can take 2 days to 5 weeks
                to publish your release and make it available.
              </em>
            </small>
            <input
              type="date"
              onChange={handleChange("digitalReleaseDate")}
              className="form-control"
              value={schedule?.digitalReleaseDate}
            />
            {errors.digitalReleaseDate ? (
              <ErrorMessage message={errors.digitalReleaseDate} />
            ) : (
              ""
            )}
          </li>

          <li>
            <label htmlFor="">Original Release Date</label>
            <small className="text-muted d-block">
              <em>
                This is the date that the album was first released. This will
                not affect the date that your music is available for sale.
              </em>
            </small>
            <input
              type="date"
              onChange={handleChange("originalReleaseDate")}
              className="form-control"
              value={schedule?.originalReleaseDate}
            />
            {errors.originalReleaseDate ? (
              <ErrorMessage message={errors.originalReleaseDate} />
            ) : (
              ""
            )}
          </li>

          <li>
            <label htmlFor="em-Copyright">License Type</label>

            <small className="text-muted d-block">
              <em>
                Indicate the type of licence that is applicable to your release:
                Copyright or Creative Commons (select the applicable cc licence)
              </em>
            </small>
            <select
              onChange={handleChange("licenseType")}
              id="em-Copyright"
              className="form-select"
              defaultValue={schedule?.licenseType}
            >
              <option value="Copyright">COPYRIGHT</option>
              <option value="Creative Commons">Creative Commons</option>
            </select>
            {errors.licenseType ? (
              <ErrorMessage message={errors.licenseType} />
            ) : (
              ""
            )}
          </li>

          {schedule?.licenseType === "Creative Commons" && (
            <li>
              <label htmlFor="em-Copyright">Creative Commons</label>
              <select
                onChange={handleChange("creativeCommon")}
                id="em-creativeCommon"
                className="form-select"
                defaultValue={schedule?.creativeCommon}
              >
                <option value="Share alike">SHARE ALIKE</option>
                <option value="Non commercial">NON-COMMERCIAL</option>
                <option value="No derivatives">NO DERIVATIVES</option>
              </select>
              {errors.creativeCommon ? (
                <ErrorMessage message={errors.creativeCommon} />
              ) : (
                ""
              )}
            </li>
          )}

          <li>
            <label htmlFor="">License Holder</label>
            <small className="text-muted d-block">
              <em>
                Provide the year in which the release was registered (copyright
                or creative commons), and the owner of the release.
              </em>
            </small>
            <input
              type="year"
              onChange={handleChange("licenseHolder")}
              className="form-control"
              value={schedule?.licenseHolder}
            />
            {errors.licenseHolder ? (
              <ErrorMessage message={errors.licenseHolder} />
            ) : (
              ""
            )}
          </li>

          <li>
            <label htmlFor="">Copyright for sound recordings</label>
            <small className="text-muted d-block">
              <em>
                The legal name of the sound recordings owner. Format: Firstname
                Lastname or Exact Legal name.
              </em>
            </small>
            <input
              type="text"
              onChange={handleChange("copyright")}
              className="form-control"
              value={schedule?.copyright}
            />
            {errors.copyright ? (
              <ErrorMessage message={errors.copyright} />
            ) : (
              ""
            )}
          </li>
        </ul>

        {/* <ul className="em-pre-pricing">
          <li>
            <div className="em-pricing">
              <div className="em-pricing-input">
                <input
                  type="radio"
                  checked={schedule.releasingType === "pre_release" ?? false}
                  onChange={handleChange("releasingType")}
                  name="releasingType"
                  id="pre_release"
                  value="pre_release"
                ></input>
                <label htmlFor="pre_release">
                  Specificdate with Pre-Release{" "}
                </label>
                <span>$40</span>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since.
              </p>
            </div>
          </li>
          <li>
            <div className="em-pricing">
              <div className="em-pricing-input">
                <input
                  type="radio"
                  checked={schedule.releasingType === "incoming" ?? false}
                  onChange={handleChange("releasingType")}
                  name="releasingType"
                  id="incoming"
                  value="incoming"
                ></input>
                <label htmlFor="incoming">As Soon as Possible for</label>
                <span>$40</span>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since.
              </p>
            </div>
          </li>
        </ul>
        {errors.releasingType ? (
          <ErrorMessage message={errors.releasingType} />
        ) : (
          ""
        )} */}
        <ul className="em-upload-btn">
          <li>
            <button onClick={prevStep} className="em-upoad-btn">
              BACK
            </button>
          </li>
          <li>
            <button onClick={nextSubmit} className="em-upoad-btn">
              NEXT
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TrackScheduleForm;

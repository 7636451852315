import React, { useEffect, useState } from "react";
import Images from "../../assets/images";
import ErrorMessage from "../../components/ErrorMessage";
import { FileUploader } from "react-drag-drop-files";
import { getDuration } from "../../utils";
// Release artwork
function TrackDetailsForm({ errorList, prevStep, nextStep, data }) {
  const [errors, setErrors] = useState([]);
  const [singleMusic, setSingleMusic] = useState({
    thumbnail: null,
    title: "",
    primaryArtist: "",
    performer: "",
    producer: "",
    remixer: "",
    composer: "",
    lyricist: "",
    publisher: "",
    featuredArtist: "",
    with: "",
    conductor: "",
    arranger: "",
    orchestra: "",
    actor: "",
    label: "",
    copyrightHolder: "",
    copyrightYear: "",
    upcCodeRelease: "yes",
    upcCode: "",
    explicitLyric: "Not Explicit",
    referenceNumber: "",
    globalReleaseIdentifier: "",
    ISRCCode: "",
    ISWCCode: "",
    publishingRights: "",
    lyrics: "",
  });

  const requiredFields = [
    "title",
    "primaryArtist",
    "performer",
    "producer",
    "composer",
    "lyricist",
    "label",
    "copyrightHolder",
    "copyrightYear",
    "upcCodeRelease",
    // "upcCode",
    "explicitLyric",
    "ISRCCode",
    "publishingRights",
  ];

  const imgTypes = ["jpg", "png", "jpeg", "gif"];

  const selectThumbnail = (file) => {
    setErrors([]);
    setSingleMusic((prevState) => ({ ...prevState, thumbnail: file }));
  };

  const handleChange = (input) => (evt) => {
    const value = evt.target.value;
    setSingleMusic({
      ...singleMusic,
      [input]: value,
    });
  };

  const nextSubmit = (e) => {
    e.preventDefault();
    setErrors([]);
    let error_list = [];
    Object.keys(singleMusic).forEach(function (key, index) {
      if (
        requiredFields.indexOf(key) !== -1 &&
        (singleMusic[key] === "" || singleMusic[key] == null)
      ) {
        error_list[key] = ["This field id required"];
      }
    });

    // console.log(singleMusic, "singleMusic", errors, "errors");

    if (!singleMusic?.thumbnail) {
      error_list["thumbnail"] = ["This field id required"];
    }

    if (Object.keys(error_list).length) {
      setErrors(error_list);
      return;
    }
    nextStep(singleMusic, data?.trackType);
  };

  // const nextSubmit = (e) => {
  //   e.preventDefault();
  //   setErrors([]);
  //   let error_list = [];

  //   if (!trackThumbnail) {
  //     error_list["trackThumbnail"] = ["This field id required"];
  //   }

  //   if (!trackThumbnail) {
  //     setErrors(error_list);
  //     return;
  //   }

  //   nextStep({
  //     trackThumbnail: trackThumbnail,
  //   });
  // };

  return (
    <div className="em-db-content">
      <div className="em-upload-track pt-0">
        <h5>Release Details: {data?.songs[data?.selectedTrack]?.name}</h5>
        <ul className="em-upload-track-list-info">
          <li>
            <div className="row">
              <div
                className={`${singleMusic?.thumbnail ? "col-10" : "col-12"}`}
              >
                <FileUploader
                  multiple={false}
                  handleChange={selectThumbnail}
                  name="thumbnail"
                  types={imgTypes}
                >
                  <div className="em-upload-track-list">
                    <label htmlFor="em-input-file">
                      <i className="fa-solid fa-upload"></i>
                    </label>
                    <h4>Drag & Drop track thumbnail</h4>
                    <a href="" className="em-upload-track-list-btn">
                      Browse Track
                    </a>
                  </div>
                </FileUploader>
              </div>

              <div className="text-muted">
                <ul className="ps-0 my-2">
                  <li>
                    <strong>Size:</strong> 3000 - 5000 pixels (perfect square)
                  </li>
                  <li className="m-0">
                    <strong>Format:</strong> JPG, TIFF or PNG (Max. 36 MB; RGB
                    mode)
                  </li>
                </ul>
                <p>
                  <small>
                    You must own the rights of the artwork and every image or
                    visual element in it. The information on the artwork has to
                    be consistent with all the information entered in the
                    release metadata.
                  </small>
                </p>

                <p>
                  <small>
                    Stores will reject blurry images, low quality, or if it
                    contains any URL, e-mail, phone number, barcode, price or
                    registered trademark logos. Also, it must not contain any
                    explicit or outrageous material such as pornography or hate
                    speech. Any artwork not meeting the stores’ requirements
                    will be rejected.
                  </small>
                </p>
              </div>

              {singleMusic?.thumbnail && (
                <div className="col-2 align-self-center">
                  <img
                    src={URL.createObjectURL(singleMusic?.thumbnail)}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="">
                    <i className="bx bx-grid-alt"></i>
                  </div>
                </div>
              )}
            </div>
          </li>
          {errors.thumbnail ? <ErrorMessage message={errors.thumbnail} /> : ""}
        </ul>

        <ul className="em-track-title">
          <li>
            <label htmlFor="">Track Title</label>
            <input
              onChange={handleChange("title")}
              type="text"
              placeholder="Enter tract title"
              className="form-select"
              value={singleMusic?.title}
            />
            {errors.title ? <ErrorMessage message={errors.title} /> : ""}
          </li>
          <li>
            <label htmlFor="">Primary Artist</label>
            <input
              onChange={handleChange("primaryArtist")}
              type="text"
              placeholder="Enter primary artist"
              className="form-select"
              value={singleMusic?.primaryArtist}
            />
            {errors.primaryArtist ? (
              <ErrorMessage message={errors.primaryArtist} />
            ) : (
              ""
            )}
          </li>
          <li>
            <label htmlFor="">Performer</label>
            <input
              onChange={handleChange("performer")}
              type="text"
              placeholder="Enter performer"
              className="form-select"
              value={singleMusic?.performer}
            />
            {errors.performer ? (
              <ErrorMessage message={errors.performer} />
            ) : (
              ""
            )}
          </li>
          <li>
            <label htmlFor="">Producer</label>
            <input
              onChange={handleChange("producer")}
              type="text"
              placeholder="Enter producer"
              className="form-select"
              value={singleMusic?.producer}
            />
            {errors.producer ? <ErrorMessage message={errors.producer} /> : ""}
          </li>
          <li>
            <label htmlFor="">Remixer (optional)</label>
            <input
              onChange={handleChange("remixer")}
              type="text"
              placeholder="Enter remixer"
              className="form-select"
              value={singleMusic?.remixer}
            />
            {errors.remixer ? <ErrorMessage message={errors.remixer} /> : ""}
          </li>
          <li>
            <label htmlFor="">Composer</label>
            <input
              onChange={handleChange("composer")}
              type="text"
              placeholder="Enter composer"
              className="form-select"
              value={singleMusic?.composer}
            />
            {errors.composer ? <ErrorMessage message={errors.composer} /> : ""}
          </li>
          <li>
            <label htmlFor="">Lyricist</label>
            <input
              onChange={handleChange("lyricist")}
              type="text"
              placeholder="Enter lyricist"
              className="form-select"
              value={singleMusic?.lyricist}
            />
            {errors.lyricist ? <ErrorMessage message={errors.lyricist} /> : ""}
          </li>
          <li>
            <label htmlFor="">Publisher (optional)</label>
            <input
              onChange={handleChange("publisher")}
              type="text"
              placeholder="Enter publisher"
              className="form-select"
              value={singleMusic?.publisher}
            />
            {errors.publisher ? (
              <ErrorMessage message={errors.publisher} />
            ) : (
              ""
            )}
          </li>

          <li>
            <label htmlFor="">Featuring (optional)</label>
            <input
              onChange={handleChange("featuredArtist")}
              type="text"
              placeholder="Enter featured artist"
              className="form-select"
              value={singleMusic?.featuredArtist}
            />
            {errors.featuredArtist ? (
              <ErrorMessage message={errors.featuredArtist} />
            ) : (
              ""
            )}
          </li>
          <li>
            <label htmlFor="">With (optional)</label>
            <input
              onChange={handleChange("with")}
              type="text"
              placeholder="Enter with"
              className="form-select"
              value={singleMusic?.with}
            />
            {errors.with ? <ErrorMessage message={errors.with} /> : ""}
          </li>
          <li>
            <label htmlFor="">Conductor (optional)</label>
            <input
              onChange={handleChange("conductor")}
              type="text"
              placeholder="Enter conductor"
              className="form-select"
              value={singleMusic?.conductor}
            />
            {errors.conductor ? (
              <ErrorMessage message={errors.conductor} />
            ) : (
              ""
            )}
          </li>
          <li>
            <label htmlFor="">Arranger (optional)</label>
            <input
              onChange={handleChange("arranger")}
              type="text"
              placeholder="Enter arranger"
              className="form-select"
              value={singleMusic?.arranger}
            />
            {errors.arranger ? <ErrorMessage message={errors.arranger} /> : ""}
          </li>
          <li>
            <label htmlFor="">Orchestra (optional)</label>
            <input
              onChange={handleChange("orchestra")}
              type="text"
              placeholder="Enter orchestra"
              className="form-select"
              value={singleMusic?.orchestra}
            />
            {errors.orchestra ? (
              <ErrorMessage message={errors.orchestra} />
            ) : (
              ""
            )}
          </li>
          <li>
            <label htmlFor="">Actor (optional)</label>
            <input
              onChange={handleChange("actor")}
              type="text"
              placeholder="Enter actor"
              className="form-select"
              value={singleMusic?.actor}
            />
            {errors.actor ? <ErrorMessage message={errors.actor} /> : ""}
          </li>
          <li>
            <label htmlFor="">Label</label>
            <small className="text-muted d-block">
              <em>
                Add Label Name or add Independent (If Artist does not have a
                Label Name)
              </em>
            </small>

            <input
              onChange={handleChange("label")}
              type="text"
              placeholder="Enter label"
              className="form-select"
              value={singleMusic?.label}
            />
            {errors.label ? <ErrorMessage message={errors.label} /> : ""}
          </li>
          <li>
            <label htmlFor="">Copyright Holder</label>
            <input
              onChange={handleChange("copyrightHolder")}
              type="text"
              placeholder="Copyright holder"
              className="form-select"
              value={singleMusic?.copyrightHolder}
            />
            {errors.copyrightHolder ? (
              <ErrorMessage message={errors.copyrightHolder} />
            ) : (
              ""
            )}
          </li>
          <li>
            <label htmlFor="em-Copyright">Copyright Year</label>
            <input
              type="number"
              onChange={handleChange("copyrightYear")}
              id="em-Copyright"
              className="form-select"
              value={singleMusic?.copyrightYear}
            />
            {errors.copyrightYear ? (
              <ErrorMessage message={errors.copyrightYear} />
            ) : (
              ""
            )}
          </li>

          <li>
            <label htmlFor="em-Copyright">
              Do you have a UPC code for this release
            </label>
            <select
              onChange={handleChange("upcCodeRelease")}
              id="em-Copyright"
              name="em-Copyright"
              className="form-select"
              defaultValue={singleMusic?.upcCodeRelease}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            {errors.upcCodeRelease ? (
              <ErrorMessage message={errors.upcCodeRelease} />
            ) : (
              ""
            )}
          </li>
          {singleMusic?.upcCodeRelease === "yes" && (
            <li>
              <label htmlFor="em-upcCode">UPC Code</label>
              <input
                type="number"
                onChange={handleChange("upcCode")}
                id="em-upcCode"
                className="form-select"
                value={singleMusic?.upcCode}
              />
              {errors.upcCode ? <ErrorMessage message={errors.upcCode} /> : ""}
            </li>
          )}

          {/* <li>
            <label htmlFor="em-Copyright">
              Does this release contain explicit lyrics
            </label>
            <select
              onChange={handleChange("explicitLyric")}
              id="em-Copyright"
              className="form-select"
              defaultValue={singleMusic?.explicitLyric}
            >
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
            {errors.explicitLyric ? (
              <ErrorMessage message={errors.explicitLyric} />
            ) : (
              ""
            )}
          </li> */}

          <li>
            <label htmlFor="em-Copyright">Reference Number (optional)</label>
            <small className="text-muted d-block">
              <em>
                The unique reference number associated with this album. It is
                obligatory for distributing your release. If you don´t have a
                reference number, we will assign one to your release.
              </em>
            </small>

            <input
              type="text"
              onChange={handleChange("referenceNumber")}
              id="em-referenceNumber"
              className="form-select"
              value={singleMusic?.referenceNumber}
            />
            {errors.referenceNumber ? (
              <ErrorMessage message={errors.referenceNumber} />
            ) : (
              ""
            )}
          </li>

          <li>
            <label htmlFor="em-Copyright">
              Global Release Identifier (GRid)(optional)
            </label>
            <small className="text-muted d-block">
              <em>
                The Global Release Identifier (GRid) is a unique identification
                of digital releases used by some channels.
              </em>
            </small>

            <input
              type="text"
              onChange={handleChange("globalReleaseIdentifier")}
              id="em-globalReleaseIdentifier"
              className="form-select"
              value={singleMusic?.globalReleaseIdentifier}
            />
            {errors.globalReleaseIdentifier ? (
              <ErrorMessage message={errors.globalReleaseIdentifier} />
            ) : (
              ""
            )}
          </li>

          {/* <li>
            <label htmlFor="em-Copyright">Reference Number (optional)</label>
            <small className="text-muted d-block">
              <em>
                The unique reference number associated with this album. It is
                obligatory for distributing your release. If you don´t have a
                reference number, we will assign one to your release.
              </em>
            </small>

            <input
              type="text"
              onChange={handleChange("referenceNumber")}
              id="em-referenceNumber"
              className="form-select"
              value={singleMusic?.referenceNumber}
            />
            {errors.referenceNumber ? (
              <ErrorMessage message={errors.referenceNumber} />
            ) : (
              ""
            )}
          </li> */}

          <li>
            <label htmlFor="">ISRC Code</label>
            <small className="text-muted d-block">
              <em>
                The ISRC is a unique code used to identify your track in the
                distribution process and to track sales. It is obligatory for
                distributing your release. If you don´t have this code, we will
                assign one to your track. If you do provide one, please use the
                correct 12 character format LL-LLL-NN-NNNNN ('-' are optional)
              </em>
            </small>
            <input
              type="text"
              onChange={handleChange("ISRCCode")}
              className="form-select"
              value={singleMusic?.ISRCCode}
            />
            {errors.ISRCCode ? <ErrorMessage message={errors.ISRCCode} /> : ""}
          </li>

          <li>
            <label htmlFor="">ISWC Code (optional)</label>
            <small className="text-muted d-block">
              <em>
                The ISRC is a unique code used to identify your track in the
                distribution process and to track sales. It is obligatory for
                distributing your release. If you don´t have this code, we will
                assign one to your track. If you do provide one, please use the
                correct 12 character format LL-LLL-NN-NNNNN ('-' are optional)
              </em>
            </small>
            <input
              type="text"
              onChange={handleChange("ISWCCode")}
              className="form-select"
              value={singleMusic?.ISWCCode}
            />
            {errors.ISWCCode ? <ErrorMessage message={errors.ISWCCode} /> : ""}
          </li>

          <li>
            <label htmlFor=""> Publishing rights</label>
            <small className="text-muted d-block">
              <em>
                The legal name of the rights owner. Format: Firstname Lastname
                or Exact Legal name.
              </em>
            </small>
            <input
              type="text"
              onChange={handleChange("publishingRights")}
              className="form-select"
              value={singleMusic?.publishingRights}
            />
            {errors.publishingRights ? (
              <ErrorMessage message={errors.publishingRights} />
            ) : (
              ""
            )}
          </li>

          <li>
            <label htmlFor="">Lyrics (optional)</label>
            <input
              type="text"
              onChange={handleChange("lyrics")}
              className="form-select"
              value={singleMusic?.lyrics}
            />
            {errors.lyrics ? <ErrorMessage message={errors.lyrics} /> : ""}
          </li>

          <li>
            <label htmlFor="em-Copyright">Explicit lyrics</label>

            <small className="text-muted d-block">
              <em>
                If the lyrics contain strong language or any reference to
                violence, language nudity, or sexual content, you must select
                "Explicit". If the track is a cleaned-up version of a track
                originally explicit, it should be marked as "Cleaned".
              </em>
            </small>
            <select
              onChange={handleChange("explicitLyric")}
              id="em-Copyright"
              className="form-select"
              defaultValue={singleMusic?.explicitLyric}
            >
              <option value="Not Explicit">Not Explicit</option>
              <option value="Explicit">Explicit</option>
              <option value="Cleaned">Cleaned</option>
            </select>
            {errors.explicitLyric ? (
              <ErrorMessage message={errors.explicitLyric} />
            ) : (
              ""
            )}
          </li>
        </ul>

        <ul className="em-upload-btn">
          <li>
            <button onClick={prevStep} className="em-upoad-btn">
              BACK
            </button>
          </li>
          <li>
            <button onClick={nextSubmit} className="em-upoad-btn">
              NEXT
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TrackDetailsForm;

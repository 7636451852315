import React from "react";
import ImagePath from "../../../constants/ImagePath";

const LoadingModal = () => {
  return (
    <div
      className="modal fade show d-block bg-black bg-opacity-50"
      id="orderConfirm"
      tabIndex="-1"ß
      role="dialog"
      aria-labelledby="orderConfirmTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content bg-black text-center rounded-4">
          <div className="modal-body p-5">
            <h3 className="py-5 text-white">Loading...</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingModal;

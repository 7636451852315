import React, { useEffect, useState } from "react";
import Images from "../../assets/images";
import ErrorMessage from "../../components/ErrorMessage";
import { FileUploader } from "react-drag-drop-files";
import { getDuration } from "../../utils";
// Release artwork
function ChooseTrackForm({ errorList, prevStep, nextStep, data }) {
  const [errors, setErrors] = useState([]);
  const [track, selectTrack] = useState(data?.songs || null);

  const fileTypes = {
    audio: ["wav", "FLAC"],
  };

  const selectFile = (file) => {
    selectTrack(null);
    setErrors([]);
    file?.length > 0
      ? selectTrack(Array.from(file)?.map((song) => ({ track: song })))
      : selectTrack([{ track: file }]);
  };

  const nextSubmit = (e, trackIndex) => {
    // console.log(Object.keys(track)?.length, "trackIndex");
    e.preventDefault();
    setErrors([]);
    let error_list = [];

    if (!track) {
      error_list["track"] = ["This field id required"];
    }

    if (!track) {
      setErrors(error_list);
      return;
    }

    nextStep({
      selectedTrack: trackIndex,
      songs: track,
    });
  };

  // useEffect(() => {
  //   console.log(data, "data");
  // }, [data]);

  const allTrackInfoUpdate = data?.songs?.filter((song) => song?.done == true);

  return (
    <div className="em-db-content">
      <div className="em-upload-track pt-0">
        <h5>{data?.trackType === "album" ? "Track List" : "Single Track"}</h5>
        <ul className="em-upload-track-list-info">
          <li>
            <FileUploader
              multiple={data?.trackType === "album" ? true : false}
              handleChange={selectFile}
              name="file"
              types={fileTypes?.audio}
            >
              <div className="em-upload-track-list">
                <label htmlFor="em-input-file">
                  <img src={Images.Tracist_img} alt=""></img>
                </label>
                <h4>Drag & Drop files here to upload</h4>
                <span className="em-upload-track-list-btn">Browse Track</span>
              </div>
            </FileUploader>
          </li>
          {errors.track ? <ErrorMessage message={errors.track} /> : ""}
        </ul>

        {/* Song List */}
        <ul className="em-top-release-music-wraper">
          {track &&
            track?.length > 0 &&
            track?.map((trackSong, index) => (
              <li key={index}>
                <div className="em-top-release-music-info">
                  <span>#{index + 1}</span>
                  <img
                    className="em-thumbnail"
                    src={`${
                      trackSong?.thumbnail
                        ? URL.createObjectURL(trackSong?.thumbnail)
                        : Images.music_thumbnail
                    }`}
                    alt="music image"
                  />
                  <div className="em-top-release-music-profie">
                    <div className="em-top-release-music-profie-info">
                      <span className="text-wrap">
                        {trackSong?.track?.name}
                      </span>
                    </div>

                    {trackSong?.done ? (
                      <div className="em-top-release-music-time">
                        <i className="bx bxs-check-circle"></i>
                      </div>
                    ) : (
                      <div
                        className="em-top-release-music-time"
                        onClick={(e) => nextSubmit(e, index)}
                      >
                        <i className="bx bx-edit"></i>
                        <span id={`time${index}`}>Edit</span>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            ))}
        </ul>
        {/* Song List */}

        <ul className="em-upload-btn">
          {allTrackInfoUpdate !== undefined &&
          data?.songs !== undefined &&
          allTrackInfoUpdate?.length === data?.songs?.length ? (
            <li>
              <button
                onClick={() => nextStep({ submit: true })}
                className="em-upoad-btn"
              >
                RELEASE MUSIC
              </button>
            </li>
          ) : (
            <li>
              <button onClick={prevStep} className="em-upoad-btn active">
                BACK
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default ChooseTrackForm;

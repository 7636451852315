import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import ImagePath from "../constants/ImagePath";

function DesktopSideBar() {
  const [user, setUser] = useState({ name: "", accountType: "" });
  const navigate = useNavigate();
  const [width, setWidth] = useState("");
  const location = useLocation();

  useEffect(() => {
    const cookies = new Cookies()
    setWidth($(window).width());
    let sidebar = document.querySelector(".sidebar");

    let closeBtn = document.querySelector("#btn");

    let userName = cookies.get("user_name");
    let accountType = cookies.get("account_type");

    if (userName) {
      setUser({
        name: userName,
        accountType: accountType,
      });
    }

    if (closeBtn) {
      closeBtn.addEventListener("click", () => {
        sidebar.classList.toggle("open");
        menuBtnChange(); //calling the function(optional)
      });
    }
    // following are the code to change sidebar button(optional)
  }, []);

  const menuBtnChange = () => {
    let sidebar = document.querySelector(".sidebar");
    let closeBtn = document.querySelector("#btn");
    if (sidebar.classList.contains("open")) {
      closeBtn.classList.replace("bx-menu", "bx-menu-alt-right"); //replacing the iocns class
    } else {
      closeBtn.classList.replace("bx-menu-alt-right", "bx-menu"); //replacing the iocns class
    }
  };
  const logout = () => {
    const cookies = new Cookies();
    cookies.remove("auth_token");
    cookies.remove("user");
    cookies.remove("user_name");
    cookies.remove("account_type");
    navigate.push("/login");
  };

  useEffect(() => {
    if (width > 1000) {
      import("./sidebar-desktop.css");
    }
  }, [width]);

  return (
    <>
      <div className="position-relative desktop">
        <div className={`sidebar`}>
          <div className="logo-details">
            <Link to="/">
              <img
                src={ImagePath?.icLogoWithName}
                alt=""
                width="200"
                className="img-fluid py-2"
              />
            </Link>
          </div>
          <ul className="nav-list">
            <li>
              <Link
                className={location?.pathname === "/dashboard" ? "active" : ""}
                to="/dashboard"
              >
                <i className="bx bx-grid-alt"></i>
                <span className="links_name">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                className={
                  location?.pathname === "/upload-music" ? "active" : ""
                }
                to="/upload-music"
              >
                <i className="bx bx-cloud-upload"></i>
                <span className="links_name">Upload Music</span>
              </Link>
            </li>
            <li>
              <Link
                className={location?.pathname === "/musics" ? "active" : ""}
                to="/musics"
              >
                <i className="bx bx-music"></i>
                <span className="links_name">Music</span>
              </Link>
            </li>
            <li>
              <Link
                className={location?.pathname === "/albums" ? "active" : ""}
                to="/albums"
              >
                <i className="bx bx-album"></i>
                <span className="links_name">EP / Albums</span>
              </Link>
            </li>
            <li>
              <Link
                className={
                  location?.pathname === "/subscription" ? "active" : ""
                }
                to="/subscription"
              >
                <i className="bx bx-crown"></i>
                <span className="links_name">Subscriptions</span>
              </Link>
            </li>
            <li>
              <Link
                className={location?.pathname === "/settings" ? "active" : ""}
                to="/settings"
              >
                <i className="bx bx-cog"></i>
                <span className="links_name">Settings</span>
              </Link>
            </li>

            <li>
              <Link to="#" type="button" onClick={logout}>
                <i className="bx bx-log-in"></i>
                <span className="links_name">Log Out</span>
              </Link>
            </li>

            {user?.name && (
              <li className="profile">
                <div className="profile-details logout-sec">
                  {user?.accountType === "free" ? (
                    <img src={ImagePath?.freeProfileLogo} />
                  ) : (
                    <img src={ImagePath?.paidProfileLogo} />
                  )}
                  <div className="links_name p-2">
                    <p className="text-wrap small">{user?.name}</p>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default DesktopSideBar;

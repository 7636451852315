import React from "react";
import "./ambasdor.css";
import Menu from "../Menu/Menu";
import Layout from "../Layout";
import { Link } from "react-router-dom";
import { rankingList } from "../../../api/data";
import ImagePath from "../../../constants/ImagePath";

function Ambassadors() {
  return (
    <Layout>
      <div className="em-bgcolor p-0">
        <div className="container pb-5">
          <Menu />
          <div className="ambasor-section">
            <div className="py-0 py-lg-5">
              <h2>
                Emergence Music Ambassador
                <span> Clubs</span>
              </h2>

              <p className="amb-desc">
                Join one of our clubs and become part of the Elite of Music
                Artists.
              </p>

              <p className="amb-desc">
                Emergence Music Distribution has created the EMERGENCE MUSIC
                AMBASSADOR CLUBS for artists who want to grow in the music
                industry, challenge themselves and perform better in a
                competitive environment. We believe this is crucial to
                professional digital artistic development, which is why we
                created a revolutionary structure where artists can sharpen
                their musical skills, their music marketing strategies, improve
                their professional leaderships compared to international artists
                regardless of musical genres. They will learn how to generate
                income, get support for their artistic career development from
                Emergence Music Distribution, get career opportunities and
                receive prizes.
              </p>
              <p className="amb-desc">
                The EMERGENCE MUSIC AMBASSADOR CLUBS was designed because music
                distribution plays a major role in an artist’s visibility, even
                on the international music scene. However, this is not how
                artists make a career out of music. We want to push and
                challenge artists by inviting them to find out through this new
                adventure who has the best career plan. Each year, different
                artists will compete against each other. They will be split into
                4 programs DISRUPTOR SOUND, WILD CARD SOUND, SOUND BREAKER AND
                GLOBAL SOUND. Every month, specific calculations will help
                determine their ranking. Every artist of a specific club will
                receive the same services. This is our way of giving our members
                the same opportunities for career development. This way, we can
                also find out who has the best entrepreneurial vision and the
                best game plan to become the next international music sensation.
              </p>
              <p className="amb-desc">
                At the end of each year, artists who perform beyond the required
                minimum for each Emergence Music Ambassador Clubs program will
                be promoted to the next program. For instance, they can be
                upgraded from WILD CARD SOUND to SOUND BREAKER, from SOUND
                BREAKER to GLOBAL SOUND. If they are already in the GLOBAL SOUND
                program, they will keep on enjoying the benefits of their
                ranking. At the end of each year, artists who perform below the
                required minimum for each Emergence Music Ambassador Clubs
                program will be downgraded to a lower program. For instance,
                they can be demoted from GLOBAL SOUND to SOUND BREAKER, from
                SOUND BREAKER to WILD CARD SOUND and from WILD CARD SOUND to
                DISRUPTOR SOUND.
              </p>
            </div>
            <div className="amb-wrap">
              <div className="amb-box">
                <div className="amb-inner">
                  <div className="icon d-flex justify-content-center py-3">
                    <img
                      src={ImagePath?.disruptorSound}
                      className="img-fluid"
                      width={100}
                    />
                  </div>
                  <p className="amb-title">Disruptor Sound</p>
                  <p className="amb-subTitle">25% Discount on Music services</p>
                  <ul className="amb-list">
                    <li>Artist Initiative Return Funding</li>
                    <li>EPK | Basic </li>
                    <li>Graphic Design | New Music Release</li>
                    <li>Licensing | Synch Opportunities</li>
                    <li>Music Consultation | 30:00 MIN / 4X per year</li>
                    <li>Music Distribution Unlimited</li>
                    <li>Music Opportunities </li>
                    <li>Music Playlist Opportunities | In-House Playlisting</li>
                    <li>Pre-Save & Smart Link </li>
                  </ul>
                  <div className="amb-hori-center">
                    <Link className="amb-emergence" to="/signup">
                      Get Started for Free
                    </Link>
                  </div>
                </div>
              </div>
              <div className="amb-box">
                <div className="amb-inner">
                  <div className="icon d-flex justify-content-center py-3">
                    <img
                      src={ImagePath?.wildCardSound}
                      className="img-fluid"
                      width={100}
                    />
                  </div>
                  <p className="amb-title">Wild Card Sound</p>
                  <p className="amb-subTitle">20% Discount on Music services</p>
                  <ul className="amb-list">
                    <li>Artist Initiative Return Funding</li>
                    <li>Emergence Music Mental Health Support For Artist</li>
                    <li>EPK | Pro</li>
                    <li>Licensing | Synch Opportunities</li>
                    <li>Graphic Design</li>
                    <li>Marketing Campaign</li>
                    <li>
                      Music Playlisting | In-House Playlisting | Music Playlist
                      Curation
                    </li>
                    <li>Music Distribution Unlimited </li>
                    <li>Music Consultation | 30:00 MIN / 6X per year</li>
                    <li>Music Opportunities</li>
                    <li>Licensing | Synch Opportunities</li>
                    <li>Public Relations Opportunities</li>
                    <li>Pre-Save & Smart Link</li>
                  </ul>
                  <p className="amb-subTitle text-start">
                    <i>
                      Requirement: Monthly minimum of 150K streams or monthly
                      listeners on all streaming platforms combined.
                    </i>
                  </p>
                </div>
              </div>

              <div className="amb-box">
                <div className="amb-inner">
                  <div className="icon d-flex justify-content-center py-3">
                    <img
                      src={ImagePath?.soundBreaker}
                      className="img-fluid"
                      width={100}
                    />
                  </div>
                  <p className="amb-title">Sound Breaker</p>
                  <p className="amb-subTitle">15% Discount on Music services</p>
                  <ul className="amb-list">
                    <li>Artist Initiative Return Funding</li>
                    <li>Emergence Music Mental Health Support For Artist</li>
                    <li>EPK Pro | Unlimited Update</li>
                    <li>Graphic Design</li>
                    <li>Licensing | Synch Opportunities</li>
                    <li>Marketing Campaign</li>
                    <li>Music Consultation | 60:00 MIN 6X per year</li>
                    <li>Music Distribution Unlimited</li>
                    <li>Music Opportunities</li>
                    <li>
                      Music Playlisting | In-House Playlisting | Music Playlist
                      Curation | DSP
                    </li>
                    <li>Music Video Distribution</li>
                    <li>Public Relations Opportunities</li>
                    <li>Pre-Save & Smart Link</li>
                  </ul>
                  <p className="amb-subTitle text-start">
                    <i>
                      Requirement: Monthly minimum of 500K streams or monthly
                      listeners on all streaming platforms combined
                    </i>
                  </p>
                </div>
              </div>

              <div className="amb-box">
                <div className="amb-inner">
                  <div className="icon d-flex justify-content-center py-3">
                    <img
                      src={ImagePath?.globalSound}
                      className="img-fluid"
                      width={100}
                    />
                  </div>
                  <p className="amb-title">Global Sound</p>
                  <p className="amb-subTitle">10% Discount on Music services</p>
                  <ul className="amb-list">
                    <li>Artist Initiative Return Funding</li>
                    <li>Emergence Music Mental Health Support For Artist</li>
                    <li>EPK Pro & Unlimited Update</li>
                    <li>Licensing | Synch Opportunities</li>
                    <li>Graphic Design</li>
                    <li>Marketing Campaign</li>
                    <li>Music Consultation Unlimited </li>
                    <li>Music Distribution Unlimited </li>
                    <li>Music Opportunities</li>
                    <li>
                      Music Playlisting | In-House Playlisting | Music Playlist
                      Curation | DSP
                    </li>
                    <li>Music Video Distribution</li>
                    <li>Public Relations Opportunities</li>
                    <li>Pre-Save & Smart Link</li>
                    <li>Music services On Demand</li>
                  </ul>
                  <p className="amb-subTitle text-start">
                    <i>
                      Requirement: Monthly minimum of 1M streams or monthly
                      listeners on all streaming platforms combined.
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ambasor-section">
            <div className="py-0 py-lg-5">
              <h2>
                Ambassadors <span>only Services</span>
              </h2>
            </div>
            <div className="amb-wrap gap-3 justify-content-center">
              {/* <div className="amb-box">
                <div className="amb-inner">
                  <p className="amb-title">Emergence Music Academy</p>
                  <p className="text-white">
                    Learn every aspect and avoid all hurdles in in the music
                    industry
                  </p>
                 
                </div>
              </div> */}
              <div className="amb-box">
                <div className="amb-inner">
                  <p className="amb-title">Music Opportunity</p>
                  <p className="text-white">
                    Join our Emergence Ambassador Clubs program and take
                    advantage of all music opportunities to propel your music
                    career.
                  </p>
                  <ul className="amb-list">
                    <li>Gig Life Pro</li>
                    <li>Manitoba Music Association</li>
                    <li>Songshop</li>
                  </ul>
                </div>
              </div>
              <div className="amb-box">
                <div className="amb-inner">
                  <p className="amb-title">Pre-Save & Smart Link</p>
                  <p className="text-white">
                    Create a Pre-Save or Smart Link for your new musical project
                    or your social media in one hyper link.
                  </p>
                  <ul className="amb-list">
                    {/* <li> <a href="http://solo.to/" target="_blank">Solo.To</a> or Feature FM+</li> */}
                    <li>
                      Pre-save |{" "}
                      <strong>&nbsp;Spotify, Apple Music, Deezer</strong>
                    </li>
                    <li>Social Media</li>
                  </ul>
                </div>
              </div>
              <div className="amb-box">
                <div className="amb-inner">
                  <p className="amb-title">Licensing | Synch</p>
                  <p className="text-white">
                    Increase your music income with Film & TV placements, plus
                    other opportunities.
                  </p>
                  <ul className="amb-list">
                    <li>ClickNClear</li>
                    <li>Songshop</li>
                    <li>Music Gateway</li>
                  </ul>
                </div>
              </div>
              {/* <div className="amb-box">
                <div className="amb-inner">
                  <p className="amb-title">Global Sound</p>
                  <ul className="amb-list">
                    <li>Spotify</li>
                    <li>Spotify</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>

          <div className="ambasor-section">
            <div className="py-0 py-lg-5">
              <h2>
                Emergence Music Ambassador <span>Clubs Standings</span>
              </h2>
              <p className="amb-desc">Season 2023</p>
            </div>
            <div className="table-responsive">
              <table
                className="rang-table table rounded text-white"
                style={{ background: "rgba(255, 255, 255, 0.08)" }}
              >
                <thead>
                  <tr>
                    <th scope="col" width="5%">
                      Ranking
                    </th>
                    <th scope="col" width="10%">
                      Artist
                    </th>
                    <th scope="col" className="d-none d-lg-table-cell">
                      EMAC Badge
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      Country
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      Genre
                    </th>
                    <th scope="col" width="15%">
                      Track
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      TSS
                    </th>
                    <th scope="col" width="10%" className="text-center">
                      Listen Now
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-dark">
                    <td>
                      <div className="d-flex align-items-center">
                        <span> &nbsp;</span>
                        {/* <span className="mt-1">
                            <i className="bx bx-up-arrow-alt text-danger"></i>
                            <i className="bx bx-down-arrow-alt text-success"></i>
                          </span> */}
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="d-none d-lg-table-cell">&nbsp;</td>
                    <td className="d-none d-md-table-cell">&nbsp;</td>
                    <td className="d-none d-md-table-cell">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="d-none d-md-table-cell">&nbsp;</td>
                    <td className="text-center">&nbsp;</td>
                  </tr>
                  {/* {rankingList?.map((rank, index) => (
                    <tr className="border-dark" key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span>{index + 1} &nbsp;</span>
                          <span className="mt-1">
                            <i className="bx bx-up-arrow-alt text-danger"></i>
                            <i className="bx bx-down-arrow-alt text-success"></i>
                          </span>
                        </div>
                      </td>
                      <td>{rank?.artistName}</td>
                      <td className="d-none d-lg-table-cell">{rank?.emac}</td>
                      <td className="d-none d-md-table-cell">
                        {rank?.country}
                      </td>
                      <td className="d-none d-md-table-cell">{rank?.genre}</td>
                      <td>{rank?.trackTittle}</td>
                      <td className="d-none d-md-table-cell">{rank?.tss}</td>
                      <td className="text-center">
                        <a className="nav-link" href={rank?.listenNow}>
                          <i className="fab fa-spotify text-success fs-4"></i>
                        </a>
                      </td>
                    </tr>
                  ))} */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Ambassadors;

import React, { useEffect, useState } from "react";
import "../../../sass/Pages/_em-login.scss";
import "../../../components/PaymentMethod/stripe.css";
import { Link } from "react-router-dom";
import ImagePath from "../../../constants/ImagePath";
import ConfirmModal from "./ConfirmModal";

import SubscriptionCard from "../../../components/SubscriptionCard";

const service = {
  title: "AWB | Artist With Benefit",
  price: 14.99,
  discount: 15,
  discount_type: "percentage",
  benefits: ["Discount on Music services 15%"],
};

function OrderDetail({ error, nextStep, prevStep, data }) {
  const [formData, setFormData] = useState({
    isFree: true,
    service: {},
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (name, value = {}) => {
    name === "free"
      ? setFormData({ isFree: true, service: value })
      : setFormData({ isFree: false, service: value });
  };

  const finalSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    nextStep({ submit: true, isFree: true }, 3);
  };

  const nextSubmit = (e) => {
    e.preventDefault();
    nextStep({ orderDetails: formData }, 3);
  };

  return (
    <div className="em-signup min-vh-100">
      <Link
        className="d-flex align-items-center text-white nav-link"
        onClick={prevStep}
        to="#"
        type="button"
      >
        <img src={ImagePath?.icBackArrow} className="img-fluid" />
      </Link>
      <h3 className="py-4">Order Details</h3>

      <div className="rounded-3 p-2">
        <div role="button">
          <div
            className={`border rounded-3 mb-3 ${
              formData?.isFree ? "border-danger" : ""
            }`}
            onClick={() => handleChange("free", {})}
          >
            <div className="p-3">
              <div className="d-flex align-items-center justify-content-between text-white">
                <div className="d-flex align-items-center">
                  <img src={ImagePath?.icCrown} className="img-fluid mb-1" />
                  <span className="mx-2  small">
                    Disruptor Sound Ambassador Program
                  </span>
                </div>
              </div>
            </div>

            <div className="border-top"></div>
            <div className="d-flex align-items-center justify-content-between p-3">
              <span className="text-white fs-5 fw-bold">Free</span>
            </div>
          </div>

          <SubscriptionCard
            data={formData}
            handleChange={handleChange}
            item={service}
          />
        </div>
      </div>

      {formData?.isFree ? (
        <>
          {loading ? (
            <div className="text-center">
              <img width={50} src={ImagePath?.icSpinner} />
            </div>
          ) : (
            <button
              className="em-sign-in-btn active m-0 mt-3"
              onClick={(e) => finalSubmit(e)}
            >
              Sign Up
            </button>
          )}
        </>
      ) : (
        <button
          className="em-sign-in-btn active m-0 mt-3"
          onClick={(e) => nextSubmit(e)}
        >
          Continue and Pay
        </button>
      )}

      {/* {Object.keys(paymentInfo).length > 0 && (
        <ConfirmModal nextSubmit={confirmAndPay} />
      )}

      {data?.cardInfo?.paymentMethod === "free" && (
        <ConfirmModal nextSubmit={confirmAndPay} />
      )} */}
    </div>
  );
}

export default OrderDetail;

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../api/API";
import SongList from "../../components/SongList";
import backArrow from "../../assets/images/frontend/back.svg";
import playIcon from "../../assets/images/frontend/play.svg";
import { getDuration } from "../../utils";
import ReactAudioPlayer from "react-audio-player";
import SideBar from "../../components/SideBar";

const AlbumDetail = () => {
  const { id } = useParams();
  const [album, setAlbum] = useState({});
  const navigate = useNavigate();
  const [selectedTruck, setSelectedTruck] = useState("");
  useEffect(() => {
    // const cookies = new Cookies();
    // let user_id = cookies.get("user_id");
    API.get(`albums/details/${id}`).then((res) => {
      // console.log(res, "res");
      setAlbum(res?.data?.data);
    });
  }, []);

  const handelTruckPlay = (id, file) => {
    selectedTruck?.file == file
      ? setSelectedTruck({})
      : setSelectedTruck({ id, file });
  };
  return (
    <>
      <SideBar />
      <div className="em-db-content home-section position-relative">
        <Link
          className="d-flex align-items-center text-white nav-link"
          onClick={() => navigate(-1)}
          to="#"
          type="button"
        >
          <img src={backArrow} />
          <h3 className="mx-3 my-0 text-capitalize">{album?.title}</h3>
        </Link>

        <div className="row py-5">
          <div className="col-4">
            <img className="img-fluid rounded" src={album?.thumbnail} />
          </div>
          <div className="col-8">
            <div className="em-top-release-music-info">
              <div className="row">
                <div className="col-6 mb-4">
                  <h6>Track Title</h6>
                  <span className="text-white mt-2 display-3">
                    {album?.title}
                  </span>
                </div>
                <div className="col-6 mb-4">
                  <h6>Primary Artist</h6>
                  <span className="text-white mt-2 display-3">
                    {album?.artist}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row py-5">
          <div className="col-12">
            <ul className="em-top-release-music-wraper m-0">
              {album?.songs &&
                album?.songs?.length > 0 &&
                album?.songs?.map((song, index) => (
                  <li className="active" key={index}>
                    <div
                      className={`em-top-release-music-info ${
                        selectedTruck?.file == song?.track?.file ? "active" : ""
                      }`}
                    >
                      <span
                        onClick={() =>
                          handelTruckPlay(song?.id, song?.track?.file)
                        }
                      >
                        {Object.keys(selectedTruck)?.length > 0 &&
                        selectedTruck?.file == song?.track?.file ? (
                          <i className="bx bx-pause bx-md"></i>
                        ) : (
                          <i className="bx bx-play bx-md"></i>
                        )}
                      </span>
                      <img
                        className="em-thumbnail rounded"
                        src={`${song?.track?.thumbnail}`}
                        alt="music image"
                      />
                      <div className="em-top-release-music-profie">
                        <div className="em-top-release-music-profie-info">
                          <span className="text-wrap mb-2">
                            {song?.track?.title}
                          </span>
                          <h6 className="text-wrap">{song?.artist}</h6>
                        </div>
                        <div className="em-top-release-music-time">
                          <i className="fa-solid fa-clock"></i>
                          <span id={`time${index}`}>
                            {getDuration(`${song?.track?.file}`, index)}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {Object.keys(selectedTruck).length > 0 && (
          <div className="position-fixed bottom-0 start-50 translate-middle-x w-25 px-2">
            <ReactAudioPlayer
              className="w-100"
              src={selectedTruck?.file}
              autoPlay
              controls
              preload
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AlbumDetail;

import React, { useEffect, useState } from "react";
import Images from "../../assets/images";
import "../../sass/Pages/_em-dashboard.scss";
import Slider from "react-slick";
import API from "../../api/API";
import Cookies from "universal-cookie";
import SongList from "../../components/SongList";
import Album from "../../components/Music/Album";
import { Link } from "react-router-dom";
import ImagePath from "../../constants/ImagePath";
import SideBar from "../../components/SideBar";
function Dashboard() {
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [user, setUser] = useState({ name: "", accountType: "" });

  var settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 3,
    speed: 300,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var imgSetting = {
    dots: true,
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const cookies = new Cookies();
    let user_id = cookies.get("user_id");

    let userName = cookies.get("user_name");
    let accountType = cookies.get("account_type");

    if (userName) {
      setUser({
        name: userName,
        accountType: accountType,
      });
    }

    API.get(`dashboard/${user_id}`).then((res) => {
      // console.log(res, "dashboard res");
      if ((res.data.type = "success")) {
        setDashboardInfo(res.data?.dashboardInfo);
      }
    });
  }, []);

  return (
    <>
      <SideBar />
      <div className="em-db-content home-section py-3">
        <div className="em-db-content-title d-flex align-items-center justify-content-between">
          <h2>Dashboard</h2>
          <div className="d-flex align-items-center">
            <div className="profile-logo me-2">
              {user?.accountType === "free" ? (
                <img
                  className="rounded"
                  width="40"
                  src={ImagePath?.freeProfileLogo}
                  alt=""
                />
              ) : (
                <img
                  className="rounded"
                  width="40"
                  src={ImagePath?.paidProfileLogo}
                  alt=""
                />
              )}
            </div>
            <div>
              <p className="text-white m-0 small">
                <strong>{user?.name}</strong>
              </p>
              <p className="text-white m-0 small">
                <strong>Total Balance</strong>: <span>C$0.00</span>
              </p>
            </div>
          </div>
        </div>
        <div className="em-db-content-wraper">
          <div className="row">
            <div className="col-lg-7">
              <div className="em-db-marketing">
                <Slider {...imgSetting}>
                  <div className="em-db-marketing-info d-flex">
                    <div className="db-get-marketing">
                      <h3>
                        Promote services marketing campaigns & music
                        consultation | 60 min
                      </h3>
                      <p>
                        Plan your promotional music campaign with Music
                        Emergence Distribution!
                      </p>
                      <Link
                        to="/services/music-consultation"
                        className="db-marketing-btn"
                      >
                        Explore Now
                      </Link>
                    </div>
                    <div className="db-marketing-img">
                      <img src={Images.marketing} alt="" />
                    </div>
                  </div>
                  <div className="em-db-marketing-info d-flex">
                    <div className="db-get-marketing">
                      <h3>Get Marketing Campaign 149.99$</h3>
                      <p>
                        Plan your promotional music campaign with Music
                        Emergence Distribution!
                      </p>
                      <Link
                        to="/services/marketing-campaigns"
                        className="db-marketing-btn"
                      >
                        Explore Now
                      </Link>
                    </div>
                    <div className="db-marketing-img">
                      <img src={Images.marketing} alt="" />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="col-lg-5">
              <ul className="em-db-music row gap-3 m-0">
                <li className="col m-0">
                  <img
                    width="24"
                    height="24"
                    src={ImagePath?.icMusic}
                    className="img-fluid"
                    alt=""
                  />
                  <span>Music</span>
                  <em>{dashboardInfo?.totalMusic}</em>
                </li>
                <li className="col m-0">
                  <img
                    width="24"
                    height="24"
                    src={ImagePath?.icAlbum}
                    className="img-fluid"
                    alt=""
                  />

                  <span>EP / Albums</span>
                  <em>{dashboardInfo?.albums?.total}</em>
                </li>
                <li className="col m-0">
                  <img
                    width="24"
                    height="24"
                    src={ImagePath?.icCalender}
                    className="img-fluid"
                    alt=""
                  />

                  <span>Upcoming Music</span>
                  <em>{dashboardInfo?.totalUpcomingMusic}</em>
                </li>
                <li className="col m-0">
                  <img
                    width="24"
                    height="24"
                    src={ImagePath?.icPublishMusic}
                    className="img-fluid"
                    alt=""
                  />

                  <span>Published Music</span>
                  <em>{dashboardInfo?.totalPublishedMusic}</em>
                </li>
              </ul>
            </div>
          </div>

          <div className="em-my-music">
            <h4 className="mb-4">My EP / Albums</h4>
            {dashboardInfo?.albums &&
            dashboardInfo?.albums?.all &&
            dashboardInfo?.albums?.all?.length > 0 ? (
              <Slider {...settings}>
                {dashboardInfo?.albums?.all?.map((item, index) => (
                  <div key={`alb_${item?.id}`}>
                    <Link to={`/albums/${item?.id}`} className="nav-link">
                      <Album item={item} key={index} />
                    </Link>
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="empty py-3">
                <i className="bx bx-album"></i>
                <p className="m-0">Empty list!</p>
                <p className="m-0">You have no ep/albums at this moment.</p>
              </div>
            )}
          </div>
          <div className="em-top-release-music">
            <SongList />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;

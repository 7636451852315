// import "./assets/css/fontawesome.min.css";
import "./assets/css/all.css";
import "./assets/css/fonts.css";
// import "./assets/css/icon.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './sass/Pages/_responsive.scss';
import './sass/base/_typography.scss';
import Container from "./Container";
import React, {useEffect} from "react";
function App() { 
  useEffect(() => {
    const script = document.createElement('script');
    const link = document.createElement('link');
    const bootStrapScript = document.createElement('script');
  
    script.src = "https://kit.fontawesome.com/74b5eac548.js";
    script.async = true;
    bootStrapScript.async = true;
    document.body.appendChild(script);


    link.href='https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css';
    link.rel='stylesheet'
    document.head.appendChild(link);
    
    return () => {
      document.head.removeChild(link);
      document.body.removeChild(script);
    }
  }, []);
  return ( 
    <div className="em-app"> 
      <Container />
      {/* <h1>abc</h1> */}
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Images from "../../assets/images";
import ErrorMessage from "../../components/ErrorMessage";
import { FileUploader } from "react-drag-drop-files";
// Release artwork
function AlbumForm({ errorList, prevStep, nextStep }) {
  const [errors, setErrors] = useState([]);

  const [albumInfo, setAlbumInfo] = useState({
    thumbnail: null,
    title: "",
    primaryArtist: "",
    type: "album",
    performer: "",
    producer: "",
    remixer: "",
    composer: "",
    lyricist: "",
    publisher: "",
    featuredArtist: "",
    with: "",
    conductor: "",
    arranger: "",
    orchestra: "",
    actor: "",
  });

  const requiredFields = [
    "title",
    "primaryArtist",
    "type",
    "performer",
    "producer",
    "composer",
    "lyricist",
  ];

  const handleChange = (input) => (evt) => {
    const value = evt.target.value;
    setAlbumInfo({
      ...albumInfo,
      [input]: value,
    });
  };

  const imgTypes = ["jpg", "png", "jpeg", "gif"];

  const selectAlbumThumbnail = (file) => {
    setErrors([]);
    setAlbumInfo((prevState) => ({
      ...prevState,
      thumbnail: file,
    }));
  };

  const nextSubmit = (e) => {
    e.preventDefault();
    setErrors([]);
    let error_list = [];
    Object.keys(albumInfo).forEach(function (key, index) {
      if (
        requiredFields.indexOf(key) !== -1 &&
        (albumInfo[key] === "" || albumInfo[key] == null)
      ) {
        error_list[key] = ["This field id required"];
      }
    });

    // console.log(error_list, "errro");

    if (Object.keys(error_list).length) {
      setErrors(error_list);
      return;
    }
    nextStep({
      album: { ...albumInfo },
    });
  };

  return (
    <div className="em-db-content">
      <div className="em-upload-track">
        <h5>Album</h5>
        <ul className="em-upload-track-list-info">
          <li>
            <div className="row">
              <div className={`${albumInfo?.thumbnail ? "col-10" : "col-12"}`}>
                <FileUploader
                  multiple={false}
                  handleChange={selectAlbumThumbnail}
                  name="file"
                  types={imgTypes}
                >
                  <div className="em-upload-track-list">
                    <label htmlFor="em-input-file">
                      <i className="fa-solid fa-upload"></i>
                    </label>
                    <h4>Drag & Drop or choose album thumbnail</h4>
                    <a href="" className="em-upload-track-list-btn">
                      Browse Track
                    </a>
                  </div>
                </FileUploader>
              </div>

              <div className="text-muted">
                <ul className="ps-0 my-2">
                  <li>
                    <strong>Size:</strong> 3000 - 5000 pixels (perfect square)
                  </li>
                  <li className="m-0">
                    <strong>Format:</strong> JPG, TIFF or PNG (Max. 36 MB; RGB
                    mode)
                  </li>
                </ul>
                <p>
                  <small>
                    You must own the rights of the artwork and every image or
                    visual element in it. The information on the artwork has to
                    be consistent with all the information entered in the
                    release metadata.
                  </small>
                </p>

                <p>
                  <small>
                    Stores will reject blurry images, low quality, or if it
                    contains any URL, e-mail, phone number, barcode, price or
                    registered trademark logos. Also, it must not contain any
                    explicit or outrageous material such as pornography or hate
                    speech. Any artwork not meeting the stores’ requirements
                    will be rejected.
                  </small>
                </p>
              </div>

              {albumInfo?.thumbnail && (
                <div className="col-2 align-self-center">
                  <img
                    src={URL.createObjectURL(albumInfo?.thumbnail)}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="">
                    <i className="bx bx-grid-alt"></i>
                  </div>
                </div>
              )}
            </div>
            {errors.thumbnail ? (
              <ErrorMessage message={errors.thumbnail} />
            ) : (
              ""
            )}
          </li>
        </ul>

        <ul className="em-track-title">
          <li>
            <label htmlFor="">Title</label>
            <input
              onChange={handleChange("title")}
              type="text"
              placeholder="Enter album title"
              className="form-select"
              value={albumInfo?.title}
              required
            />
            {errors.title ? <ErrorMessage message={errors.title} /> : ""}
          </li>
          <li>
            <label htmlFor="">Primary Artist</label>
            <input
              onChange={handleChange("primaryArtist")}
              type="text"
              placeholder="Enter primary artist"
              className="form-select"
              value={albumInfo?.primaryArtist}
              required
            />
            {errors.primaryArtist ? (
              <ErrorMessage message={errors.primaryArtist} />
            ) : (
              ""
            )}
          </li>

          <li>
            <label htmlFor="">Type</label>
            <select
              defaultValue={albumInfo?.type}
              onChange={handleChange("type")}
              className="form-select"
              required
            >
              <option value="album">Album</option>
              <option value="ep">EP</option>
            </select>
            {errors.type ? <ErrorMessage message={errors.type} /> : ""}
          </li>

          <li>
            <label htmlFor="">Performer</label>
            <input
              onChange={handleChange("performer")}
              type="text"
              placeholder="Enter performer"
              className="form-select"
              value={albumInfo?.performer}
              required
            />
            {errors.performer ? (
              <ErrorMessage message={errors.performer} />
            ) : (
              ""
            )}
          </li>
          <li>
            <label htmlFor="">Producer</label>
            <input
              onChange={handleChange("producer")}
              type="text"
              placeholder="Enter producer"
              className="form-select"
              value={albumInfo?.producer}
              required
            />
            {errors.producer ? <ErrorMessage message={errors.producer} /> : ""}
          </li>
          <li>
            <label htmlFor="">Remixer (optional)</label>
            <input
              onChange={handleChange("remixer")}
              type="text"
              placeholder="Enter remixer"
              className="form-select"
              value={albumInfo?.remixer}
            />
            {errors.remixer ? <ErrorMessage message={errors.remixer} /> : ""}
          </li>
          <li>
            <label htmlFor="">Composer</label>
            <input
              onChange={handleChange("composer")}
              type="text"
              placeholder="Enter composer"
              className="form-select"
              value={albumInfo?.composer}
              required
            />
            {errors.composer ? <ErrorMessage message={errors.composer} /> : ""}
          </li>
          <li>
            <label htmlFor="">Lyricist</label>
            <input
              onChange={handleChange("lyricist")}
              type="text"
              placeholder="Enter lyricist"
              className="form-select"
              value={albumInfo?.lyricist}
              required
            />
            {errors.lyricist ? <ErrorMessage message={errors.lyricist} /> : ""}
          </li>
          <li>
            <label htmlFor="">Publisher (optional)</label>
            <input
              onChange={handleChange("publisher")}
              type="text"
              placeholder="Enter publisher"
              className="form-select"
              value={albumInfo?.publisher}
            />
            {errors.publisher ? (
              <ErrorMessage message={errors.publisher} />
            ) : (
              ""
            )}
          </li>

          <li>
            <label htmlFor="">Featuring (optional)</label>
            <input
              onChange={handleChange("featuredArtist")}
              type="text"
              placeholder="Enter featured artist"
              className="form-select"
              value={albumInfo?.featuredArtist}
            />
            {errors.featuredArtist ? (
              <ErrorMessage message={errors.featuredArtist} />
            ) : (
              ""
            )}
          </li>
          <li>
            <label htmlFor="">With (optional)</label>
            <input
              onChange={handleChange("with")}
              type="text"
              placeholder="Enter with"
              className="form-select"
              value={albumInfo?.with}
            />
            {errors.with ? <ErrorMessage message={errors.with} /> : ""}
          </li>
          <li>
            <label htmlFor="">Conductor (optional)</label>
            <input
              onChange={handleChange("conductor")}
              type="text"
              placeholder="Enter conductor"
              className="form-select"
              value={albumInfo?.conductor}
            />
            {errors.conductor ? (
              <ErrorMessage message={errors.conductor} />
            ) : (
              ""
            )}
          </li>
          <li>
            <label htmlFor="">Arranger (optional)</label>
            <input
              onChange={handleChange("arranger")}
              type="text"
              placeholder="Enter arranger"
              className="form-select"
              value={albumInfo?.arranger}
            />
            {errors.arranger ? <ErrorMessage message={errors.arranger} /> : ""}
          </li>
          <li>
            <label htmlFor="">Orchestra (optional)</label>
            <input
              onChange={handleChange("orchestra")}
              type="text"
              placeholder="Enter orchestra"
              className="form-select"
              value={albumInfo?.orchestra}
            />
            {errors.orchestra ? (
              <ErrorMessage message={errors.orchestra} />
            ) : (
              ""
            )}
          </li>
          <li>
            <label htmlFor="">Actor (optional)</label>
            <input
              onChange={handleChange("actor")}
              type="text"
              placeholder="Enter actor"
              className="form-select"
              value={albumInfo?.actor}
            />
            {errors.actor ? <ErrorMessage message={errors.actor} /> : ""}
          </li>
        </ul>

        <ul className="em-upload-btn">
          <li>
            <button onClick={prevStep} className="em-upoad-btn">
              BACK
            </button>
          </li>
          <li>
            <button onClick={nextSubmit} className="em-upoad-btn">
              NEXT
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AlbumForm;

import React, { useState } from "react";
import "../../../assets/pricing.css";
import Menu from "../Menu/Menu";
import Layout from "../Layout";
import ErrorMessage from "../../../components/ErrorMessage";

// Images
import Internship from "../../../assets/images/frontend/internship.png";
import Employment from "../../../assets/images/frontend/employment.png";
import Investor from "../../../assets/images/frontend/investor.png";
import Partnership from "../../../assets/images/frontend/partnership.png";
import API from "../../../api/API";
import { ToastContainer } from "react-toastify";
import { toastMessage } from "../../../utils";
import Loading from "../../../components/Loading";

const initialInputs = {
  position: "part time",
  fullName: "",
  email: "",
  message: "",
};

function Career() {
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState([]);
  const [careerFrom, setCareerFrom] = useState(initialInputs);

  const handleChange = (input) => (evt) => {
    const value = evt.target.value;
    setCareerFrom({
      ...careerFrom,
      [input]: value,
    });
  };

  const careerFormSubmit = () => {
    const formData = new FormData();
    formData.append("position", careerFrom?.position);
    formData.append("email", careerFrom?.email);
    formData.append("fullName", careerFrom?.fullName);
    formData.append("message", careerFrom?.message);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setProgress(percent);
      },
    };

    API.post(`career`, formData, options)
      .then((res) => {
        // console.log(res, "career");
        toastMessage("success", "Successfully submitted!");
        setCareerFrom({ ...initialInputs });
      })
      .then((res) => {
        setProgress(0);
      })
      .catch((error) => {
        setProgress(0);
        console.log(error, "error");
        toastMessage("error", "All field's are required. Please check");
        setErrors(error.response.data.errors);
      });
  };

  return (
    <Layout>
      <ToastContainer />
      <div className="em-bgcolor">
        <div className="container">
          <Menu />
        </div>

        <div className="container">
          <div className="em-db-content">
            <div className="em-upload-track pt-0">
              <div className="row">
                <div className="col-md-7">
                  <div className="pay-box py-3">
                    <div className="d-flex align-items-center py-3">
                      <img src={Internship} width="60" height="60" />
                      <h2 className="mx-3 fs-1">Internship</h2>
                    </div>
                    <p>
                      Great news! Emergence Music Distribution Inc. is always
                      looking for new and motivated talent to join our endlessly
                      growing staff. If you have what it takes, if you want to
                      discover and be involved in the music industry, we believe
                      in giving opportunities to all. If you feel you are a
                      perfect candidate to help grow our vision, then Emergence
                      Music Distribution Inc. will gladly welcome you.
                    </p>
                    <p>Apply to be an intern and emerge with us now!</p>
                  </div>

                  <div className="pay-box py-3">
                    <div className="d-flex align-items-center py-3">
                      <img src={Employment} width="60" height="60" />
                      <h2 className="mx-3 fs-1">Employment</h2>
                    </div>
                    <p>
                      At Emergence Music Distribution Inc. we do not know what
                      “work” is. We are building careers in a fun and fulfilling
                      environment. If you love music and would like a long-term
                      position with our company, there are many positions to
                      fill. We will teach you the ins and outs of the music
                      industry.
                    </p>
                    <p>Apply to have a career and emerge with us now!</p>
                  </div>

                  <div className="pay-box py-3">
                    <div className="d-flex align-items-center py-3">
                      <img src={Investor} width="60" height="60" />
                      <h2 className="mx-3 fs-1">Investor</h2>
                    </div>
                    <p>
                      As you know, the music industry is one of the global
                      industries with the fastest-growing annual profits. Would
                      you like to invest in a company that will help you
                      increase your profits? Emergence Music Distribution Inc.
                      is the answer to your question. Our young, diverse and
                      dynamic team works hard to grow every dollar you invest
                      with us.
                    </p>
                    <p>Apply to be an investor and emerge with us now!</p>
                  </div>

                  <div className="pay-box py-3">
                    <div className="d-flex align-items-center py-3">
                      <img src={Partnership} width="60" height="60" />
                      <h2 className="mx-3 fs-1">Partnership</h2>
                    </div>
                    <p>
                      Do you want to become a partner? At Emergence Music
                      Distribution Inc. we love win-win partnerships where every
                      stakeholder has an opportunity to grow their businesses
                      and communities. Let’s grow together!
                    </p>
                    <p>Apply to be a partner and emerge with us now!!</p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="bg-black rounded-4 px-5 py-4 position-relative">
                    {progress > 0 && (
                      <Loading message="Complete" progress={progress} />
                    )}
                    <h3 className="text-white py-3">Apply For a Position</h3>
                    <p className="text-muted pb-2">
                      At Emergence Music Distribution. We are always looking for
                      determined and ambitious people to grow our team. We
                      believe in giving opportunities to all. Apply now, if you
                      feel you are a perfect candidate to help grow our vision.
                    </p>
                    <ul className="em-track-title p-0">
                      <li>
                        <label htmlFor="em-Copyright">Position</label>
                        <select
                          onChange={handleChange("position")}
                          id="em-Copyright"
                          className="form-select"
                          defaultValue={careerFrom?.position}
                        >
                          <option value="part-time">Part-time</option>
                          <option value="full-time">Full-time</option>
                          <option value="internship">Internship</option>
                          <option value="partnership">Partnership</option>
                          <option value="investors">Investors</option>
                        </select>
                        {errors.position ? (
                          <ErrorMessage message={errors.position} />
                        ) : (
                          ""
                        )}
                      </li>
                      <li>
                        <label htmlFor="">Full Name</label>
                        <input
                          onChange={handleChange("fullName")}
                          type="text"
                          placeholder="Enter full name"
                          className="form-select"
                          value={careerFrom?.fullName}
                        />
                        {errors.fullName ? (
                          <ErrorMessage message={errors.fullName} />
                        ) : (
                          ""
                        )}
                      </li>

                      <li>
                        <label htmlFor="">Email</label>
                        <input
                          onChange={handleChange("email")}
                          type="email"
                          placeholder="Enter email"
                          className="form-select"
                          value={careerFrom?.email}
                        />
                        {errors.email ? (
                          <ErrorMessage message={errors.email} />
                        ) : (
                          ""
                        )}
                      </li>

                      <li>
                        <label htmlFor="">Message</label>
                        <textarea
                          onChange={handleChange("message")}
                          type="text"
                          placeholder="Enter message"
                          className="form-select"
                          rows={5}
                          value={careerFrom?.message}
                        ></textarea>
                        {errors.message ? (
                          <ErrorMessage message={errors.message} />
                        ) : (
                          ""
                        )}
                      </li>
                    </ul>
                    <ul className="em-upload-btn mw-100 my-4">
                      <li className="w-100">
                        <button
                          onClick={careerFormSubmit}
                          className="em-upoad-btn active py-3 w-100"
                        >
                          SUBMIT
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Career;

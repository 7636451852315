import React from "react";
import Layout from "../Layout";
import Menu from "../Menu/Menu";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { artists } from "../../../api/data";
import { useParams } from "react-router-dom";

const NewsDetail = () => {
  const { slug } = useParams();
  const singleArticle = artists?.find((artist) => artist?.slug === slug);

  return (
    <Layout>
      <div className="em-bgcolor p-0">
        <div className="container">
          <Menu />

          <div className="row py-5">
            <div className="col-md-12 mb-md-3 mb-lg-0 col-lg-8">
              <div className="flex-column d-flex rounded overflow-hidden">
                <div className="text-white py-3">
                  <h2 className="m-0 fs-2">{singleArticle?.title}</h2>
                  <div className="d-flex py-2 text-white-50 gap-2">
                    <span>{singleArticle?.createdAt}</span> <span>|</span>
                    <span>{singleArticle?.category}</span>
                  </div>
                </div>
                <div
                  className="thumbnail d-flex align-items-center justify-content-center rounded-2 overflow-hidden"
                  style={{
                    backgroundImage: `url(${singleArticle?.thumbnail})`,
                  }}
                >
                  <div className="blur"></div>
                  <img
                    src={singleArticle?.thumbnail}
                    object="fit"
                    className="img-fluid"
                  />
                </div>
                <div className="text-white py-3">
                  <p style={{ whiteSpace: "break-spaces" }}>
                    {singleArticle?.description}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-md-3 mb-lg-0 col-lg-4">
              {/* <div className="pay-box">
                <div className="pb-3">
                  <h2 className="fs-2">Social Media</h2>
                </div>
              </div> */}
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="emergence_music"
                options={{ height: 1200 }}
                theme="dark"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewsDetail;

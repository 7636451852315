import React, { useState } from 'react'
import API from "../../api/API";
import '../../sass/Pages/_em-login.scss';
import Images from '../../assets/images';
import ErrorMessage from '../../components/ErrorMessage';
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import ImagePath from '../../constants/ImagePath'

function Login({setUserToken}) { 
    const [credential, setCredential ] = useState({
        email:'',
        password: ''
    });
    const [errors, setErrors]               = useState([]);
    const [showPassword, setShowPassword]   = useState(false);
    const navigate = useNavigate();

    const handleChange = (evt) => {
        const value = evt.target.value;
    
        setCredential({
          ...credential,
          [evt.target.name]: value,
        });
    };

    const login = (e) =>{
        e.preventDefault();
        setErrors([]);
        const formData = new FormData();
        formData.append('email', credential.email);
        formData.append('password', credential.password);
        
        API.post(`login`, formData).then((res) => {
            // console.log(res.data)
            const cookies = new Cookies();
            cookies.set('auth_token', res.data.data.token);
            cookies.set('user_id', res.data.data.user_id);
            cookies.set('user_name', res.data.data.name);
            cookies.set('account_type', res.data.data.account_type);
            setUserToken();
            navigate('/dashboard');
        }).catch(error => {
            // console.log(error)
            setErrors(error.response.data.errors)
        });
    }

    return (
        <section className="em-signup-section min-vh-100">
            <div className="row em-row">
                <div className="col-4 g-0">
                    <div className="em-signup min-vh-100">
                        <h3>Welcome to Emergence <i className="em-eyeglass"></i> </h3>
                        <em>Enter your email address and password to access your account.</em>
                        <div className="em-form">
                            <div className="em-form-group">
                                <label htmlFor="email">Email address</label>
                                <div className="em-input">
                                    <input onChange={handleChange} name="email" type="email" className="form-control" id="email" placeholder="Enter email address"/>
                                </div>
                                { errors.email ? <ErrorMessage message={errors.email}/> : ''}
                            </div>
                            <div className="em-form-group">
                                <label htmlFor="password">password</label>
                                <div className="em-input">
                                    <input onChange={handleChange} name="password" type={ showPassword ? 'text' : 'password' } className="form-control em-hasicon" id="password" placeholder="Enter password"/> 
                                    <i className={`fa-solid ${showPassword ? 'fa-eye-slash' : 'em-eye' }`} onClick={() => setShowPassword(!showPassword) }></i>
                                </div>
                                { errors.password ? <ErrorMessage message={errors.password}/> : ''}
                                <Link to="/forget-password">Forgot Password?</Link>
                            </div>
                            <div>
                            <button onClick={login} className="em-sign-in-btn"> SIGN IN </button>
                            </div>
                            <span className="em-account-login">Don’t have an account? <Link to="/signup" className="em-Sign-up-btn"> Sign up <i className="em-infinity"></i>  </Link></span>
                        </div>
                    </div>
                </div>
                <div className="col-8">
                    <div className="em-info">
                        <figure>
                        <Link to="/" className="nav-link"><img src={ImagePath.icLogoWithName} className="img-fluid" alt="" /></Link></figure>
                        {/* <p>Emergence Music Distribution was created first and foremost for artists to get noticed and discovered internationally. After observing a lack of services other digital distributions were offering, we decided to offer a revolutionary plan that will disrupt and forever change the music industry. At Emergence Music Distribution, each artist will be represented equally and will access the same promotional service as they would with a major label.</p> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login

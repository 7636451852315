import React, { useEffect, useState } from "react";
import "../../sass/Pages/_em-release-artwork.scss";
import Step1 from "./Step1.js";
import AlbumForm from "./AlbumForm";
import ChooseTrackForm from "./ChooseTrackForm";
import TrackDetailsForm from "./TrackDetailsForm";
import TrackGenres from "./TrackGenres";
import TrackScheduleForm from "./TrackScheduleForm";
import MusicStore from "./MusicStore";
import { useNavigate } from "react-router-dom";
import API from "../../api/API";
import Cookies from "universal-cookie";
import "../../sass/Pages/_em-upload-music.scss";
import Loading from "../../components/Loading";
import SideBar from "../../components/SideBar";

function UploadMusic() {
  const [data, setData] = useState({});
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState([]);
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  const nextStep = (record, trackType = null, isTrackUpdate = false) => {
    // console.log(record, record.stores, "new record");

    if (record.submit) {
      submitForm(data);
      return;
    }

    if (!record.stores) {
      setStep(step + 1);
    }

    if (trackType === "album") {
      trackDataSet(record, trackType, isTrackUpdate);
    } else if (trackType === "single") {
      trackDataSet(record, trackType, isTrackUpdate);
    } else {
      setData({
        ...data,
        ...record,
      });
    }

    // console.log("record", data, step);
  };

  const trackDataSet = (record, trackType, isTrackUpdate) => {
    if (record.stores) {
      setStep(step - 4);
    }
    setData((prevState) => ({
      ...prevState,
      trackType: trackType,
      isTrackUpdate: isTrackUpdate,
      songs: prevState?.songs?.map((song, index) => {
        return data?.selectedTrack == index
          ? {
              ...song,
              ...record,
              done: true,
            }
          : { ...song, done: song?.done ? true : false };
      }),
    }));
  };

  const getFormData = (formData, data, previousKey = "") => {
    if (data instanceof Object) {
      Object.keys(data).forEach((key) => {
        const value = data[key];
        if (value instanceof Object && !Array.isArray(value)) {
          return getFormData(formData, value, key);
        }

        if (previousKey) {
          key = `${previousKey}[${key}]`;
        }

        if (Array.isArray(value)) {
          value.forEach((val) => {
            formData.append(`${key}[]`, val);
          });
        } else {
          formData.append(key, value);
        }
      });
    }
  };

  const submitForm = (data) => {
    // console.log(data, "for submit");
    setErrors([]);
    const cookies = new Cookies();
    let userId = cookies.get("user_id");
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("trackType", data?.trackType);
    formData.append("album[primaryArtist]", data?.album?.primaryArtist);
    formData.append("album[thumbnail]", data?.album?.thumbnail);
    formData.append("album[title]", data?.album?.title);
    formData.append("album[type]", data?.album?.type);
    formData.append(`album[performer]`, data?.album?.performer);
      formData.append(`album[producer]`, data?.album?.producer);
      formData.append(`album[remixer]`, data?.album?.remixer);
      formData.append(`album[composer]`, data?.album?.composer);
      formData.append(`album[lyricist]`, data?.album?.lyricist);
      formData.append(`album[publisher]`, data?.album?.publisher);
      formData.append(`album[featuredArtist]`, data?.album?.featuredArtist);
      formData.append(`album[with]`, data?.album?.with);
      formData.append(`album[conductor]`, data?.album?.conductor);
      formData.append(`album[arranger]`, data?.album?.arranger);
      formData.append(`album[orchestra]`, data?.album?.orchestra);
      formData.append(`album[actor]`, data?.album?.actor);

    data.songs.forEach((item, key) => {
      formData.append(`songs[${key}][title]`, item?.title);
      formData.append(`songs[${key}][performer]`, item?.performer);
      formData.append(`songs[${key}][producer]`, item?.producer);
      formData.append(`songs[${key}][remixer]`, item?.remixer);
      formData.append(`songs[${key}][composer]`, item?.composer);
      formData.append(`songs[${key}][lyricist]`, item?.lyricist);
      formData.append(`songs[${key}][publisher]`, item?.publisher);
      formData.append(`songs[${key}][featuredArtist]`, item?.featuredArtist);
      formData.append(`songs[${key}][with]`, item?.with);
      formData.append(`songs[${key}][conductor]`, item?.conductor);
      formData.append(`songs[${key}][arranger]`, item?.arranger);
      formData.append(`songs[${key}][orchestra]`, item?.orchestra);
      formData.append(`songs[${key}][actor]`, item?.actor);
      formData.append(`songs[${key}][label]`, item?.label);
      formData.append(`songs[${key}][copyrightHolder]`, item?.copyrightHolder);
      formData.append(`songs[${key}][copyrightYear]`, item?.copyrightYear);
      formData.append(`songs[${key}][upcCodeRelease]`, item?.upcCodeRelease);
      formData.append(`songs[${key}][upcCode]`, item?.upcCode);
      formData.append(`songs[${key}][referenceNumber]`, item?.referenceNumber);
      formData.append(
        `songs[${key}][globalReleaseIdentifier]`,
        item?.globalReleaseIdentifier
      );
      formData.append(`songs[${key}][ISRCCode]`, item?.ISRCCode);
      formData.append(`songs[${key}][ISWCCode]`, item?.ISWCCode);
      formData.append(
        `songs[${key}][publishingRights]`,
        item?.publishingRights
      );
      formData.append(`songs[${key}][lyrics]`, item?.lyrics);
      formData.append(`songs[${key}][explicitLyric]`, item?.explicitLyric);
      formData.append(`songs[${key}][primaryArtist]`, item?.primaryArtist);
      formData.append(`songs[${key}][featuredArtist]`, item?.featuredArtist);
      formData.append(`songs[${key}][recordLabel]`, "");
      formData.append(`songs[${key}][upcCode]`, item?.upcCode);
      formData.append(`songs[${key}][track]`, item?.track);
      formData.append(`songs[${key}][thumbnail]`, item?.thumbnail);
      formData.append(
        `songs[${key}][isSingleTrack]`,
        item?.schedule?.isSingleTrack
      );
      formData.append(
        `songs[${key}][digitalReleaseDate]`,
        item?.schedule?.digitalReleaseDate
      );
      formData.append(
        `songs[${key}][originalReleaseDate]`,
        item?.schedule?.originalReleaseDate
      );
      formData.append(
        `songs[${key}][releasingType]`,
        item?.schedule?.releasingType
      );
      formData.append(
        `songs[${key}][licenseType]`,
        item?.schedule?.licenseType
      );
      formData.append(
        `songs[${key}][creativeCommon]`,
        item?.schedule?.creativeCommon
      );
      formData.append(
        `songs[${key}][licenseHolder]`,
        item?.schedule?.licenseHolder
      );
      formData.append(
        `songs[${key}][copyright]`,
        item?.schedule?.copyright
      );
      formData.append(
        `songs[${key}][primaryGenre]`,
        item?.genres?.primaryGenre
      );
      formData.append(
        `songs[${key}][secondaryGenre]`,
        item?.genres?.secondaryGenre
      );
      formData.append(`songs[${key}][language]`, item?.genres?.language);
      formData.append(`songs[${key}][albumFormat]`, item?.genres?.albumFormat);
      formData.append(`songs[${key}][stores]`, JSON.stringify(item?.stores));
    });

    // console.log("beforeSendFormData", formData.entries());

    // for (var pair of formData.entries()) {
    //   console.log(pair[0], pair[1]);
    // }

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setProgress(percent);
      },
    };

    API.post(`update-music`, formData, options)
      .then((res) => {
        // console.log(res, "music data upload");
        if (res?.data?.trackType === "album") {
          navigate("/albums");
        } else {
          navigate("/musics");
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setErrors(error.response.data.errors);
      });
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 errors={errors} nextStep={nextStep} />;
      //   return <MusicStore
      //   errors={errors}
      //   nextStep={nextStep}
      //   prevStep={prevStep}
      //   data={data}
      // />;
      case 2:
        return data?.trackType == "album" ? (
          <AlbumForm
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        ) : (
          <ChooseTrackForm
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        );
      case 3:
        return data?.trackType == "album" ? (
          <ChooseTrackForm
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        ) : (
          <TrackDetailsForm
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        );
      case 4:
        return data?.trackType == "album" ? (
          <TrackDetailsForm
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        ) : (
          <TrackGenres
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        );
      case 5:
        return data?.trackType == "album" ? (
          <TrackGenres
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        ) : (
          <TrackScheduleForm
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        );
      case 6:
        return data?.trackType == "album" ? (
          <TrackScheduleForm
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        ) : (
          <MusicStore
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            data={data}
          />
        );
      case 7:
        return (
          data?.trackType == "album" && (
            <MusicStore
              errors={errors}
              nextStep={nextStep}
              prevStep={prevStep}
              data={data}
            />
          )
        );
      default:
      // do nothing
    }
  };

  // useEffect(() => {
  //   console.log(data, "update data");
  // }, [data]);

  return (
    <>
      <SideBar />
      <div className="home-section">
        <div className="em-upload-music-nav">
          <ul className="em-upload-music-nav-wraper">
            <li className={step == 1 ? "active" : ""}>
              {step > 1 ? (
                <i className="fa-solid fa-check"></i>
              ) : (
                <span>1</span>
              )}
              <a href="#">Track Type</a>
              <i className="fa-solid fa-chevron-right"></i>
            </li>
            <li className={step == 2 ? "active" : ""}>
              {step > 2 ? (
                <i className="fa-solid fa-check"></i>
              ) : (
                <span>2</span>
              )}
              <a href="#">{data?.trackType === "album" ? "Album" : "Track"}</a>
              <i className="fa-solid fa-chevron-right"></i>
            </li>
            <li className={step == 3 ? "active" : ""}>
              {step > 3 ? (
                <i className="fa-solid fa-check"></i>
              ) : (
                <span>3</span>
              )}
              <a href="#">
                {data?.trackType === "album" ? "Track" : "Track Details"}
              </a>
              <i className="fa-solid fa-chevron-right"></i>
            </li>
            {/* <li className={ step == 4 ? 'active' : '' }>
                    {
                       step > 4 ? 
                       <i className="fa-solid fa-check"></i> : 
                       <span>4</span>
                    }
                    <a href="#">Track List</a>
                    <i className="fa-solid fa-chevron-right"></i>
                </li> */}
            <li className={step == 4 ? "active" : ""}>
              {step > 4 ? (
                <i className="fa-solid fa-check"></i>
              ) : (
                <span>4</span>
              )}
              <a href="#">
                {data?.trackType === "album"
                  ? "Release Details"
                  : "Release Genres"}
              </a>
              <i className="fa-solid fa-chevron-right"></i>
            </li>
            <li className={step == 5 ? "active" : ""}>
              {step > 5 ? (
                <i className="fa-solid fa-check"></i>
              ) : (
                <span>5</span>
              )}
              <a href="#">
                {data?.trackType === "album" ? "Genres" : "Schedule"}{" "}
              </a>
              <i className="fa-solid fa-chevron-right"></i>
            </li>
            <li className={step == 6 ? "active" : ""}>
              {step > 6 ? (
                <i className="fa-solid fa-check"></i>
              ) : (
                <span>6</span>
              )}
              <a href="#">
                {data?.trackType === "album" ? "Schedule" : "Stores"}{" "}
              </a>
              {/* <i className="fa-solid fa-chevron-right"></i> */}
            </li>
            {step == 7 && (
              <li className={step == 7 ? "active" : ""}>
                {step > 7 ? (
                  <i className="fa-solid fa-check"></i>
                ) : (
                  <span>7</span>
                )}
                <a href="#">Stores</a>
                {/* <i className="fa-solid fa-chevron-right"></i> */}
              </li>
            )}
          </ul>
          {/* <button onClick={submitForm} >SubmitForm</button> */}
          {progress > 0 && <Loading message="Complete" progress={progress} />}
          {renderStep()}
        </div>
      </div>
    </>
  );
}

export default UploadMusic;

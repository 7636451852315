import React from "react";
import "../../../assets/pricing.css";
import Images from "../../../assets/images";
import Menu from "../Menu/Menu";
import Layout from "../Layout";
function AboutUs() {
  return (
    <Layout>
      <div className="em-bgcolor p-0">
        <div className="container">
          <Menu />
          <div className="row">
            <div className="pay-box col-md-6 offset-md-3 text-center">
              <div className="py-4">
                <h2 className="fs-1">OUR TEAM</h2>
                <p className="text-white d-block">
                  Discover what we can do to help you emerge
                </p>
              </div>
            </div>
          </div>

          <div className="pb-5 row">
            <div className="pay-box col-md-12 py-2 py-md-4">
              <div className="d-flex align-items-center py-3">
                <h2 className="fs-2">OUR VISION</h2>
              </div>
              <p>
                Online music streaming dominates the industry so we’ve decided
                to set up a structure that will not take advantage of the
                artists and will help them achieve success within the music
                industry.
              </p>
            </div>

            <div className="pay-box col-md-12 pb-2 pb-md-4">
              <div className="d-flex align-items-center py-3">
                <h2 className="fs-2">MISSION</h2>
              </div>
              <p>
                At Emergence Music Distribution Inc. each artist will be
                represented equally and will access the same promotional
                services as they would with a major label. We offer a wide range
                of services including music distribution on the largest
                streaming platforms, digital artist development services, music
                placement in different music playlists, monthly analytical
                reports, graphic design services and music marketing. Take part
                in the revolution and our community of emerging artists.
              </p>
            </div>

            <div className="pay-box col-md-12">
              <div className="d-flex align-items-center py-3">
                <h2 className="fs-2">INVESTORS</h2>
              </div>
              <p>
                Would you potentially consider investing in Emergence Music
                Distribution Inc.? You are more than welcome to do so. We take
                pride in what we do and are ecstatic about what we are about to
                accomplish. As Canada’s first digital artist development center
                and digital music distribution company. We have developed a
                business structure where we value the artist instead of taking
                advantage of them. We stand by our slogan:{" "}
                <strong className="text-white">
                  GIVING ARTISTS WHAT THEY NEED
                </strong>
                . We will give them access to the necessary tools they need
                regardless of their background, nationality or musical genre. We
                live in a cultural melting pot where untapped treasures are
                waiting to be discovered. This is why we chose to have a
                multicolor logo to signify there is not just one color in this
                world. Investing in our vision is an incredible opportunity to
                explore uncharted territories and invest in the future. At
                Emergence Music Distribution Inc. we side with the artists. We
                are all leaders, we are trailblazers. Don’t be caught in the
                trail of dust behind us. We will be game-changers in the music
                industry!
              </p>
              <p>Invest in us and emerge with us now! </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutUs;

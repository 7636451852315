const Images = {
    logo                        : require('./em-logo.png'),
    footer_logo                 : require('./em-db-footer-logo.png'),
    sm_logo                     : require('./em-db-logo.png'),
    marketing                   : require('./em-db-marketing.png'),
    music1                      : require('./em-my-music1.png'),
    music2                      : require('./em-my-music2.png'),
    music3                      : require('./em-my-music3.png'),
    music4                      : require('./em-my-music4.png'),
    music5                      : require('./em-my-music5.png'),
    release_music1              : require('./em-top-release-music1.png'),
    release_music2              : require('./em-top-release-music2.png'),
    release_music3              : require('./em-top-release-music3.png'),
    music_thumbnail             : require('./music-thumbnail.png'),
    em_uplaod_msc               : require('./em-uplaod-msc.png'),
    em_uplaod_msc2              : require('./em-uplaod-msc2.png'),
    Tracist_img                 : require('./Tracist-img.png'),
    em_music_store              : require('./em-music-store.png'),
    em_spotify                  : require('./store_icons/em-spotify.png'),
    em_tik_tok                  : require('./store_icons/em-tik-tok.png'),
    em_nepstar                  : require('./store_icons/em-nepstar.png'),
    em_gracento                 : require('./store_icons/em-gracento.png'),
    em_yt                       : require('./store_icons/em-yt.png'),
    em_tidal                    : require('./store_icons/em-tidal.png'),
    em_apple_music              : require('./store_icons/em-apple-music.png'),
    em_deezar                   : require('./store_icons/em-deezar.png'),
    em_caro                     : require('./store_icons/em-caro.png'),
    em_amazon_prime             : require('./store_icons/em-amazon-prime.png'),
    sell_music                  : require('./frontend/sell-music.png'),
    singer                      : require('./frontend/singer.png'),
    site_logo                   : require('./frontend/site-logo.png'),
    speaker                     : require('./frontend/speaker.png'),
    spotify                     : require('./frontend/spotify.png'),
    taylor_swift                : require('./frontend/Taylor-Swift.png'),
    three                       : require('./frontend/three.png'),
    four                       : require('./frontend/four.png'),
    tiktok                      : require('./frontend/tiktok.png'),
    boomplay                      : require('./frontend/boomplay.png'),
    two                         : require('./frontend/two.png'),
    where_sell                  : require('./frontend/where-sell.png'),
    why_chosse                  : require('./frontend/why-chosse.png'),
    youtube                     : require('./frontend/youtube.png'),
    amazon                      : require('./frontend/amazon.png'),
    apple                       : require('./frontend/apple.png'),
    ariana_grande               : require('./frontend/Ariana-Grande.png'),
    arrow                       : require('./frontend/arrow.png'),
    digital                     : require('./frontend/digital.png'),
    emergence_works             : require('./frontend/emergence-works.png'),
    justin_bieber               : require('./frontend/Justin-Bieber.png'),
    king                        : require('./frontend/king.png'),
    magnifier                   : require('./frontend/magnifier.png'),
    mic                         : require('./frontend/mic.png'),
    money_pot                   : require('./frontend/money-pot.png'),
    one                         : require('./frontend/one.png'),
    selena_gomez                : require('./frontend/Selena-Gomez.png'),

} 
export default Images;
import React, { createRef, useState } from "react";
import { useEffect } from "react";
import StripeCheckout from "react-stripe-checkout";
import { ToastContainer } from "react-toastify";
import API from "../../api/API";
import { toastMessage } from "../../utils";
import Cookies from "universal-cookie";

const StripeForm = ({ item, setPaymentInfo }) => {
  const [authUser, setAuthUser] = useState({ name: "", accountType: "" });

  const stripeRef = createRef();
  const handelToken = async (token, address) => {
    API.post(`stripe-checkout`, { token, service: item })
      .then((res) => {
        res?.data && setPaymentInfo(res?.data?.payment);
        // console.log(res, "Stripe payment");
        toastMessage("success", "Payment complete successfully.");
      })
      .catch((error) => {
        toastMessage("error", "Payment not complete. Please try again!");
        console.error(error, "error");
      });
  };

  useEffect(() => {
    const cookies = new Cookies();
    setAuthUser({
      name: cookies.get("user_name"),
      accountType: cookies.get("account_type"),
    });
  }, []);

  const handelStripeOpen = () => {
    // const stripeFrame = document.getElementsByClassName("stripe_checkout_app")
    // const stripeFrame = document.getElementsByTagName("iframe")
    // const iWindow = stripeFrame?.stripe_checkout_app?.contentWindow;
    // const iDocument = iWindow?.document;
    // const ap = document.querySelector('.stripe_checkout_app')
    // console.log('stripeRef', stripeFrame?.stripe_checkout_app, iWindow)
    // console.log(stripeFrame, 'iWindow')
  };
  return (
    <div>
      <ToastContainer />
      <StripeCheckout
        stripeKey={process.env.REACT_APP_STRIPE_PK}
        ref={stripeRef}
        token={handelToken}
        amount={
          authUser?.accountType === "free"
            ? parseFloat(item?.price - item?.price * (25 / 100)).toFixed(2) *
              100
            : parseFloat(item?.price - item?.price * (15 / 100)).toFixed(2) *
              100
        }
        name={item?.title}
        // billingAddress
        ComponentClass="div"
        opened={handelStripeOpen}
      >
        <button className="em-sign-in-btn active m-0 mt-3">Pay</button>
      </StripeCheckout>
    </div>
  );
};

export default StripeForm;

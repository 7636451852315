import React from "react";
import { Link, useLocation } from "react-router-dom";

const MobileMenu = () => {
  const location = useLocation();

  return (
    <div className="d-lg-none mobile-menu">
      <ul className="d-flex justify-content-between position-fixed fixed-bottom bottom-0 start-50 translate-middle-x m-0 p-0 w-100 list-unstyled rounded-top bg-dark overflow-hidden">
        <li
          className={`nav-item text-center w-100 p-2 ${
            location?.pathname === "/" ? "active" : ""
          }`}
        >
          <Link className={"nav-link"} to="/">
            <i className="fas fa-home"></i>
            <p className="m-0" style={{ fontSize: "10px" }}>
              Home
            </p>
          </Link>
        </li>
        <li
          className={`nav-item text-center w-100 p-2 ${
            location?.pathname === "/services" ? "active" : ""
          }`}
        >
          <Link className={"nav-link"} to="/services" title="SERVICES">
            <i className="fas fa-dollar-sign"></i>
            <p className="m-0" style={{ fontSize: "10px" }}>
              Services
            </p>
          </Link>
        </li>
        <li
          className={`nav-item text-center w-100 p-2 ${
            location?.pathname === "/ambassador" ? "active" : ""
          }`}
        >
          <Link className={"nav-link"} to="/ambassador" title="AMBASSADOR">
            <i className="fas fa-user-tie"></i>
            <p className="m-0" style={{ fontSize: "10px" }}>
              Ambassador{" "}
            </p>
            {/* Club */}
          </Link>
        </li>
        <li
          className={`nav-item text-center w-100 p-2 ${
            location?.pathname === "/about-us" ? "active" : ""
          }`}
        >
          <Link className={"nav-link"} to="/about-us" title="ABOUT US">
            <i className="fas fa-address-card"></i>
            <p className="m-0" style={{ fontSize: "10px" }}>
              About Us
            </p>
          </Link>
        </li>
        <li
          className={`nav-item text-center w-100 p-2 ${
            location?.pathname === "/career" ? "active" : ""
          }`}
        >
          <Link className={"nav-link"} to="/career" title="CAREERS">
            <i className="fas fa-briefcase"></i>
            <p className="m-0" style={{ fontSize: "10px" }}>
              Join Us
            </p>
          </Link>
        </li>

        <li
          className={`nav-item text-center w-100 p-2 ${
            location?.pathname === "/news" ? "active" : ""
          }`}
        >
          <Link className={"nav-link"} to="/news" title="NEWS">
            <i className="fas fa-briefcase"></i>
            <p className="m-0" style={{ fontSize: "10px" }}>
              News
            </p>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default MobileMenu;

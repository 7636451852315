import axios from "axios";

// const url = "http://127.0.0.1:8000";
// const url = "https://api.child-hunger.org";
const url = "https://admin.emergencemusicdistribution.com";
export const pdfLink = `${url}/uploads/pdf/terms-and-conditions.pdf`;
export const pdfLink2 = `${url}/uploads/pdf/User-Agreement-for-Emergence-Music-Ambassador-Clubs.pdf`;

export default axios.create({
  baseURL: `${url}/api/`,
  mode: "no-cors",
  headers: {
    // 'Content-type': 'application/json',
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
});

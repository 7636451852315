import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../api/API";
import LoadingIcon from "../../components/LoadingIcon";
import PayPal from "../../components/PaymentMethod/PayPal";
import Cookies from "universal-cookie";
import ImagePath from "../../constants/ImagePath";
import { toastMessage } from "../../utils";
import { ToastContainer } from "react-toastify";
import SubscribeModal from "../Subscription/SubscribeModal";
import SideBar from "../../components/SideBar";

function ServiceDetail() {
  const [authUser, setAuthUser] = useState({ name: "", accountType: "" });
  const { slug } = useParams();
  const navigate = useNavigate();

  const [service, setService] = useState({});
  const [loading, setLoading] = useState(false);
  const [buyService, setBuyService] = useState({});

  useEffect(() => {
    const cookies = new Cookies();
    setAuthUser({
      name: cookies.get("user_name"),
      accountType: cookies.get("account_type"),
    });

    setLoading(true);
    API.get(`services/detail/${slug}`).then((res) => {
      setLoading(false);
      setService(res?.data?.data);
    });
  }, []);

  const handelSubscription = (paymentInfo) => {
    // console.log(paymentInfo, "paymentInfo");
    if (Object.keys(paymentInfo)?.length > 0) {
      const cookies = new Cookies();
      let user_id = cookies.get("user_id");
      const formData = new FormData();
      formData.append("userId", user_id);
      formData.append("serviceId", buyService?.id);
      formData.append("price", 0.0);
      formData.append("paymentType", "paypal");
      formData.append("paymentHistory", paymentInfo);

      API.post(`subscriptions/store`, formData)
        .then((res) => {
          if (res?.data?.status) {
            toastMessage("success", res?.data?.message);
            navigate(-1);
          }
        })
        .catch((error) => {
          if (!error?.status) {
            toastMessage("error", error?.message);
          }
        });
    }
  };

  const handelBuyService = (service) => {
    if (service?.link && service?.link !== null) {
      window.open(service?.link);
    } else {
      setBuyService(service);
    }
  };

  return (
    <>
      <SideBar />

      <div className="em-db-content home-section position-relative">
        <ToastContainer />

        {loading ? (
          <>
            <LoadingIcon />
          </>
        ) : (
          <>
            <Link
              className="d-flex align-items-center text-white nav-link"
              onClick={() => navigate(-1)}
              to="#"
              type="button"
            >
              <img src={ImagePath?.icBackArrow} />
              <h3 className="mx-3 my-0">{service?.title}</h3>
            </Link>

            <div className="em-db-content-wraper">
              <div className="em-my-music">
                {service && service?.childServices?.length > 0 && (
                  <div className="row">
                    {service?.childServices?.map((cService, index) => (
                      <div className="col-md-4 mb-4" key={index}>
                        <div className="service-card border rounded-3 h-100 d-flex flex-column justify-content-between">
                          <div className="p-3">
                            <div className="d-flex align-items-center justify-content-between text-white">
                              <div className="d-flex align-items-center w-100">
                                <img
                                  src={ImagePath?.icCrown}
                                  className="img-fluid mb-1"
                                />
                                <div className="w-100 d-flex justify-content-between align-items-start">
                                  <span className="mx-2 fw-bold">
                                    {cService?.title}
                                  </span>
                                  <span className="flex-lg-shrink-0">
                                    {" "}
                                    {parseFloat(cService?.price) !==
                                    parseFloat(0) ? (
                                      <span>
                                        <span className="text-yellow">
                                          $
                                          {authUser?.accountType === "free"
                                            ? parseFloat(
                                                cService?.price -
                                                  cService?.price * (25 / 100)
                                              ).toFixed(2)
                                            : parseFloat(
                                                cService?.price -
                                                  cService?.price * (15 / 100)
                                              ).toFixed(2)}
                                        </span>
                                        <i className="text-muted">
                                          &nbsp;<del>${cService?.price}</del>
                                        </i>
                                      </span>
                                    ) : (
                                      "On Demand"
                                    )}
                                  </span>
                                </div>
                              </div>
                              {/* <span className="small">{0.0}$ / annum</span> */}
                            </div>
                            <div className="pt-3 text-start text-white">
                              <span className="small">
                                {cService?.description}
                              </span>
                              {cService?.benefits && (
                                <ul className="list-unstyled m-0 mt-3">
                                  {JSON.parse(cService?.benefits)?.map(
                                    (benefit) => (
                                      <li>
                                        <i className="fas fa-check-circle text-success small"></i>
                                        <span className="ms-2 small">
                                          {benefit?.name}
                                        </span>
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </div>
                          </div>

                          <div className="d-flex flex-column p-3 w-100">
                            <button
                              onClick={() => handelBuyService(cService)}
                              className="em-upoad-btn active rounded-5 border border-dark py-3 w-100 text-uppercase"
                            >
                              {parseFloat(cService?.price) !== parseFloat(0)
                                ? "Buy now"
                                : "Request A Quote"}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {Object.keys(buyService)?.length > 0 && (
          <SubscribeModal
            service={buyService}
            onClick={handelSubscription}
            closeModal={setBuyService}
          />
        )}
      </div>
    </>
  );
}

export default ServiceDetail;

import Logo from "../assets/images/frontend/logo/siteLogo.svg";
import LogoWithName from "../assets/images/frontend/logo/siteLogoName.svg";
import paidProfileLogo from "../assets/images/dashbaord/awd.png";
import freeProfileLogo from "../assets/images/dashbaord/ds.png";
import backArrow from "../assets/images/frontend/back.svg";
import play from "../assets/images/frontend/play.svg";
import visaMasterCard from "../assets/images/frontend/visa-master-card.svg";
import paypal from "../assets/images/frontend/paypal.svg";
import crown from "../assets/images/frontend/crown.svg";
import at from "../assets/images/frontend/a.svg";
import aireUrbano from "../assets/images/frontend/artists/aire-urbano.jpg";
import billber from "../assets/images/frontend/artists/billber.jpg";
import cheTheLord from "../assets/images/frontend/artists/che-the-lord.jpg";
import fylx from "../assets/images/frontend/artists/fylx.jpg";
import geelo from "../assets/images/frontend/artists/geelo.jpg";
import gunHouse from "../assets/images/frontend/artists/gun-house.jpg";
import jamhaitian from "../assets/images/frontend/artists/jamhaitian.jpg";
import losBodieds from "../assets/images/frontend/artists/los-bodies.jpg";
import peckeyPower from "../assets/images/frontend/artists/peckey-power.jpg";
import soldatSolitaire from "../assets/images/frontend/artists/soldat-solitaire.jpg";
import syla from "../assets/images/frontend/artists/syla.jpg";
import spinner from "../assets/images/spinner.svg";
import newsOne from "../assets/images/frontend/news/news-1.png";
import albums from "../assets/images/albums.png";
import musics from "../assets/images/musics.png";
import calender from "../assets/images/calender.png";
import publishMusic from "../assets/images/publish-music.png";

// Service Icons
import AWBIcon from "../assets/images/frontend/music-service-icon/AWB.svg";
import EPKServiceIcon from "../assets/images/frontend/music-service-icon/EPK Service Icon (3).svg";
import GraphicDesignServiceIcon from "../assets/images/frontend/music-service-icon/Graphic Design Service Icon (3).svg";
import MarketingCampaignServiceIcon from "../assets/images/frontend/music-service-icon/Marketing Campaign Service Icon (3).svg";
import MusicConsultationServiceIcon from "../assets/images/frontend/music-service-icon/Music Consultation Service Icon (3).svg";
import MusicDistributionServiceIcon from "../assets/images/frontend/music-service-icon/Music Distribution Service Icon (3).svg";
import MusicIndustryContractServiceIcon from "../assets/images/frontend/music-service-icon/Music Industry Contract Service Icon (3).svg";
import MusicPlaylistServiceIcon from "../assets/images/frontend/music-service-icon/Music Playlist Service Icon (3).svg";
import MusicVideoDistributionServiceIcon from "../assets/images/frontend/music-service-icon/Music Video Distribution Service Icon (3).svg";
import PublicRelationServiceIcon from "../assets/images/frontend/music-service-icon/Public Relation Service Icon (3).svg";
import RadioBroadcastingServiceIcon from "../assets/images/frontend/music-service-icon/Radio Broadcasting Service Icon (3).svg";
import TvBroadcastingServiceIcon from "../assets/images/frontend/music-service-icon/Tv Broadcasting Service Icon (3).svg";
import VocalCoachServiceIcon from "../assets/images/frontend/music-service-icon/Vocal Coach Service Icon (3).svg";
import disruptorSound from "../assets/images/frontend/ambassador-icons/disruptor-sound.svg";
import globalSound from "../assets/images/frontend/ambassador-icons/global-sound.svg";
import soundBreaker from "../assets/images/frontend/ambassador-icons/sound-breaker.svg";
import wildCardSound from "../assets/images/frontend/ambassador-icons/wild-card-sound.svg";

export default {
  AWBIcon,
  disruptorSound,
  wildCardSound,
  soundBreaker,
  globalSound,
  icLogo: Logo,
  icLogoWithName: LogoWithName,
  icBackArrow: backArrow,
  icPlay: play,
  icVisaMasterCard: visaMasterCard,
  icPayPal: paypal,
  icCrown: crown,
  icAt: at,
  jamhaitian,
  geelo,
  soldatSolitaire,
  billber,
  cheTheLord,
  aireUrbano,
  fylx,
  gunHouse,
  losBodieds,
  peckeyPower,
  syla,
  icSpinner: spinner,
  thumbnail1: newsOne,
  icAlbum: albums,
  icMusic: musics,
  icCalender: calender,
  icPublishMusic: publishMusic,
  paidProfileLogo,
  freeProfileLogo,
  EPKServiceIcon,
  GraphicDesignServiceIcon,
  MarketingCampaignServiceIcon,
  MusicConsultationServiceIcon,
  MusicDistributionServiceIcon,
  MusicIndustryContractServiceIcon,
  MusicPlaylistServiceIcon,
  MusicVideoDistributionServiceIcon,
  PublicRelationServiceIcon,
  RadioBroadcastingServiceIcon,
  TvBroadcastingServiceIcon,
  VocalCoachServiceIcon,
};

import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import API from "../api/API";
import Pagination from "./Pagination";
import Song from "./Music/Song";
import { makeSerialNumber } from "../utils";

const SongList = () => {
  const [songs, setSongs] = useState([]);
  const [paginate, setPaginate] = useState({ currentPage: 1 });

  useEffect(() => {
    const cookies = new Cookies();
    let user_id = cookies.get("user_id");
    API.get(`musics/list/${user_id}?page=${paginate?.currentPage}`).then(
      (res) => {
        if (res.data?.data?.length > 0) {
          setSongs(res.data.data);
          setPaginate({
            currentPage: res.data?.meta?.current_page,
            lastPage: res.data?.meta?.last_page,
            total: res.data?.meta?.total,
            perPage: res.data?.meta?.per_page,
          });
        }
        // if ((res.data.type = "success")) {
        //   setSongs(res.data.songs);
        //   setBaseURL(res.data.base_url);
        // }
      }
    );
  }, [paginate?.currentPage]);

  const pageChange = (item) => {
    setPaginate((prevState) => ({ ...prevState, currentPage: item }));
  };
  return (
    <div>
      <h5>My Music</h5>

      {songs && songs?.length > 0 ? (
        <ul className="em-top-release-music-wraper">
          {songs.map((item, index) => {
            let serial = makeSerialNumber(
              paginate?.total,
              paginate?.currentPage,
              paginate?.perPage,
              index,
              "asc"
            );
            return (
              <li key={index}>
                <Song item={item} serial={serial} />
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="empty py-3">
          <i className="bx bx-music"></i>
          <p className="m-0">Empty list!</p>
          <p className="m-0">You have no songs at this moment.</p>
        </div>
      )}

      <Pagination
        current={paginate?.currentPage}
        last={paginate?.lastPage}
        pageChange={pageChange}
      />
    </div>
  );
};

export default SongList;

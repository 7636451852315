import React from "react";
import Layout from "../Layout";
import Menu from "../Menu/Menu";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { artists } from "../../../api/data";
import { Link } from "react-router-dom";
import { FacebookEmbed, InstagramEmbed } from "react-social-media-embed";

const News = () => {
  return (
    <Layout>
      <div className="em-bgcolor p-0">
        <div className="container">
          <Menu />
          {/* <div className="row">
            <div className="pay-box col-md-6 offset-md-3 text-center">
              <div className="py-4">
                <h2 className="fs-1">Our Tweets</h2>
                <p className="text-white d-block">
                  Discover what we can do to help you emerge
                </p>
              </div>
            </div>
          </div> */}

          <div className="row py-5">
            <div className="col-md-12 mb-md-3 mb-lg-0 col-lg-8">
              <div className="row">
                {artists?.map((artist) => (
                  <div className="col-12 mb-3" key={artist?.id}>
                    <div className="news-card flex-column flex-md-row d-flex rounded overflow-hidden  border border-dark">
                      <div
                        className="thumbnail d-flex align-items-center justify-content-center overflow-hidden"
                        style={{
                          backgroundImage: `url(${artist?.thumbnail})`,
                        }}
                      >
                        <div className="blur"></div>
                        <img
                          src={artist?.thumbnail}
                          object="fit"
                          className="img-fluid"
                        />
                      </div>
                      <div className="text-white p-3">
                        <h2 className="m-0 fs-4">{artist?.title}</h2>
                        <div className="d-flex py-2 text-white-50 gap-2">
                          <span>{artist?.createdAt}</span> <span>|</span>
                          <span>{artist?.category}</span>
                        </div>
                        <p className="m-0">{artist?.shortDescription}</p>

                        <div className="text-end pt-4 pb-1">
                          <Link
                            to={`/news/${artist?.slug}`}
                            className="btn btn-dark"
                          >
                            Read more...
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* <div className="col-12 mb-3">
                  <div className="news-card flex-column flex-md-row d-flex rounded overflow-hidden  border border-dark">
                    <div
                      className="thumbnail d-flex align-items-center justify-content-center overflow-hidden"
                      style={{
                        backgroundImage: `url(${ImagePath?.thumbnail1})`,
                      }}
                    >
                      <div className="blur"></div>
                      <img
                        src={ImagePath?.thumbnail1}
                        object="fit"
                        className="img-fluid"
                      />
                    </div>
                    <div className="text-white p-3">
                      <h2 className="m-0 fs-4">WELCOME TO EVERYONE</h2>
                      <div className="d-flex py-2 text-white-50 gap-2">
                        <span>Apr 24, 2020</span> <span>|</span>
                        <span>General News, Promotions</span>
                      </div>
                      <p className="m-0">
                        Hello, Who distributes your music currently? Is it Cd
                        Baby, Distrokid, Tunecore or a competitor? You feel like
                        you're paying too much to distribute your music. You
                        lack visibility you feel just like a cash grab? I know
                        the feeling. If you would like to change that...
                      </p>

                      <div className="text-end pt-4 pb-1">
                        <button
                          onClick={() => console.log("read more")}
                          className="btn btn-dark"
                        >
                          Read more...
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="news-card flex-column flex-md-row d-flex rounded overflow-hidden  border border-dark">
                    <div
                      className="thumbnail d-flex align-items-center justify-content-center overflow-hidden"
                      style={{
                        backgroundImage: `url(${ImagePath?.thumbnail1})`,
                      }}
                    >
                      <div className="blur"></div>
                      <img
                        src={ImagePath?.thumbnail1}
                        object="fit"
                        className="img-fluid"
                      />
                    </div>
                    <div className="text-white p-3">
                      <h2 className="m-0 fs-4">WELCOME TO EVERYONE</h2>
                      <div className="d-flex py-2 text-white-50 gap-2">
                        <span>Apr 24, 2020</span> <span>|</span>
                        <span>General News, Promotions</span>
                      </div>
                      <p className="m-0">
                        Hello, Who distributes your music currently? Is it Cd
                        Baby, Distrokid, Tunecore or a competitor? You feel like
                        you're paying too much to distribute your music. You
                        lack visibility you feel just like a cash grab? I know
                        the feeling. If you would like to change that...
                      </p>

                      <div className="text-end pt-4 pb-1">
                        <button
                          onClick={() => console.log("read more")}
                          className="btn btn-dark"
                        >
                          Read more...
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="news-card flex-column flex-md-row d-flex rounded overflow-hidden  border border-dark">
                    <div
                      className="thumbnail d-flex align-items-center justify-content-center overflow-hidden"
                      style={{
                        backgroundImage: `url(${ImagePath?.thumbnail1})`,
                      }}
                    >
                      <div className="blur"></div>
                      <img
                        src={ImagePath?.thumbnail1}
                        object="fit"
                        className="img-fluid"
                      />
                    </div>
                    <div className="text-white p-3">
                      <h2 className="m-0 fs-4">WELCOME TO EVERYONE</h2>
                      <div className="d-flex py-2 text-white-50 gap-2">
                        <span>Apr 24, 2020</span> <span>|</span>
                        <span>General News, Promotions</span>
                      </div>
                      <p className="m-0">
                        Hello, Who distributes your music currently? Is it Cd
                        Baby, Distrokid, Tunecore or a competitor? You feel like
                        you're paying too much to distribute your music. You
                        lack visibility you feel just like a cash grab? I know
                        the feeling. If you would like to change that...
                      </p>

                      <div className="text-end pt-4 pb-1">
                        <button
                          onClick={() => console.log("read more")}
                          className="btn btn-dark"
                        >
                          Read more...
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="news-card flex-column flex-md-row d-flex rounded overflow-hidden  border border-dark">
                    <div
                      className="thumbnail d-flex align-items-center justify-content-center overflow-hidden"
                      style={{
                        backgroundImage: `url(${ImagePath?.thumbnail1})`,
                      }}
                    >
                      <div className="blur"></div>
                      <img
                        src={ImagePath?.thumbnail1}
                        object="fit"
                        className="img-fluid"
                      />
                    </div>
                    <div className="text-white p-3">
                      <h2 className="m-0 fs-4">WELCOME TO EVERYONE</h2>
                      <div className="d-flex py-2 text-white-50 gap-2">
                        <span>Apr 24, 2020</span> <span>|</span>
                        <span>General News, Promotions</span>
                      </div>
                      <p className="m-0">
                        Hello, Who distributes your music currently? Is it Cd
                        Baby, Distrokid, Tunecore or a competitor? You feel like
                        you're paying too much to distribute your music. You
                        lack visibility you feel just like a cash grab? I know
                        the feeling. If you would like to change that...
                      </p>

                      <div className="text-end pt-4 pb-1">
                        <button
                          onClick={() => console.log("read more")}
                          className="btn btn-dark"
                        >
                          Read more...
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-12 mb-md-3 mb-lg-0 col-lg-4">
              {/* <div className="pay-box">
                <div className="pb-3">
                  <h2 className="fs-2">Social Media</h2>
                </div>
              </div> */}

              <div className="row">
                <div className="col-md-12 mb-3">
                  <iframe
                    title="facebook feed"
                    src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100064302362140%2Fvideos%2F468049617227849%2F&show_text=true&width=560&t=0"
                    className="w-100"
                    height="260"
                    style={{ border: "none", overflow: "hidden" }}
                    scrolling="no"
                    frameBorder="0"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"
                  ></iframe>
                </div>
                <div className="col-md-12 mb-3">
                  <div>
                    <InstagramEmbed url="https://www.instagram.com/p/Cn2IwLHOcr4/" />
                  </div>
                </div>
              </div>

              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="emergence_music"
                options={{ height: 1200 }}
                // autoHeight={true}
                theme="dark"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default News;

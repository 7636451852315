import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const makeSerialNumber = (
  totalItems,
  currentPage,
  perPage,
  indexNumber,
  order = "desc"
) => {
  return order.toLowerCase() === "asc"
    ? (currentPage - 1) * perPage + (indexNumber + 1)
    : totalItems - ((currentPage - 1) * perPage + (indexNumber + 1)) + 1;
};

export const secondsToHms = (d) => {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.floor((d % 3600) % 60);

  let hDisplay = h > 0 ? h + ":" : "";
  let mDisplay = m > 0 ? m + ":" : "";
  let sDisplay = s > 0 ? s : "00";

  if (!hDisplay.length & !mDisplay.length) {
    mDisplay = "00:";
  }
  return hDisplay + mDisplay + sDisplay;
};

export const getDuration = (src, index) => {
  var audio = new Audio();
  audio.src = src;

  $(audio).on("loadedmetadata", function () {
    $(`#time${index}`).text(secondsToHms(audio.duration));
  });
};

export const toastMessage = (type = "success", message = "") => {
  switch (type) {
    case "success":
      toast.success(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      break;
    case "error":
      toast.error(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      break;
    case "info":
      toast.info(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      break;
    default:
  }
};


import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Cookies from "universal-cookie";
import API from "../../api/API";
import SideBar from "../../components/SideBar";
import ImagePath from "../../constants/ImagePath";
import Services from "../Services";

function Subscription() {

  var settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 300,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [subscribes, setSubscribes] = useState([]);
  useEffect(() => {
    const cookies = new Cookies();
    let user_id = cookies.get("user_id");


    API.get(`subscriptions/${user_id}?page=1`).then((res) => {
      setSubscribes(res?.data?.data);
    });
  }, []);

  return (
    <>
      <SideBar />
      <div className="em-db-content home-section">
        <div className="em-db-content-title">
          <h2>Subscriptions</h2>
        </div>

        <div className="em-db-content-wraper">
          <div className="em-my-music">
            {subscribes && subscribes?.length > 0 ? (
              <Slider {...settings}>
                {subscribes?.map((item, index) => (
                  <div key={item?.id}>
                    <div className="border rounded-3 me-3">
                      <div className="p-3">
                        <div className="d-flex align-items-center justify-content-between text-white">
                          <div className="d-flex align-items-center">
                            <img
                              src={ImagePath?.icCrown}
                              className="img-fluid"
                            />
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="ms-2 small">
                                {item?.service?.parentServices?.title}
                              </span>
                              <span className="mx-2">|</span>
                              <span>{item?.service?.title}</span>
                            </div>
                          </div>
                          {item?.service?.price && (
                            <span className="small">
                              {item?.service?.price}$ / annum
                            </span>
                          )}
                        </div>

                        <div className="pt-3 d-flex align-items-center justify-content-between text-white">
                          {item?.service?.description && (
                            <span className="small text-start">
                              {item?.service?.description}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="border-top"></div>

                      <div className="d-flex align-items-center justify-content-between p-3">
                        <span className="text-white fs-5 fw-bold">Total</span>
                        <span className="text-white fs-5 fw-bold">
                          {item?.service?.price}$ / annum
                        </span>
                      </div>
                    </div>
                    {item?.expired && (
                      <div className="d-flex p-3 w-100">
                        <button
                          onClick={() => {}}
                          className="em-upoad-btn active py-3 w-100 text-uppercase"
                        >
                          Cancel subscription
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="empty py-3">
                <i className="bx bx-crown"></i>
                <p className="m-0">Empty list!</p>
                <p className="m-0">You have no subscriptions at this moment.</p>
              </div>
            )}
          </div>
        </div>
        <div id="services" className="pt-5">
          <Services />
        </div>
      </div>
    </>
  );
}

export default Subscription;

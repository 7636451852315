import React from "react";

import MobileSideBar from "./MobileSideBar";
import DesktopSideBar from "./DesktopSideBar";

function SideBar() {
  return (
    <>
      <div>
        <div className="d-block d-lg-none">
          <MobileSideBar />
        </div>
        <div className="d-none d-lg-block">
          <DesktopSideBar />
        </div>
      </div>
    </>
  );
}

export default SideBar;

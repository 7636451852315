import React from "react";
import { Link } from "react-router-dom";
import ImagePath from "../../../constants/ImagePath";
import ontariO from "../../../assets/images/ontariocreates.svg";

const Footer = () => {
  return (
    <div className="footer-bg">
      <div className="container">
        <div className="footer-wrap">
          <div className="footer-inner d-flex flex-column justify-content-between">
            <Link to="/" className="nav-link">
              <img
                src={ImagePath?.icLogoWithName}
                alt="site-logo.png"
                className="img-fluid"
              />
            </Link>

            <div>
              <a
                rel="noreferrer"
                href="https://www.ontariocreates.ca"
                target="_blank"
                className="nav-link"
              >
                <img width="200" src={ontariO} alt="ontariocreates" />
              </a>
            </div>
          </div>
          {/* <div className="footer-menu"> */}
          <div className="footer-inner">
            <p className="title-footer">Resources</p>
            <ul className="footer-list">
              <li>
                <Link to="/about-us">About</Link>
              </li>
              <li>
                <Link to="/career">Join Us</Link>
              </li>
              <li>
                <a href="javscript:void(0)" title="">
                  Terms & Conditions
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-inner">
            <p className="title-footer">Services</p>
            <ul className="footer-list">
              <li>
                <Link to="/signup">Create Your Account</Link>
              </li>
              <li>
                <Link to="/ambassador" className="text-break">
                  Licensing/Synchronization
                </Link>
              </li>
              <li>
                <Link to="/services">Pricing</Link>
              </li>
              <li>
                <Link to="/login">Sell Your Music</Link>
              </li>
            </ul>
          </div>
          <div className="footer-inner">
            <p className="title-footer">Community</p>
            <ul className="footer-list">
              <li>
                <a
                  href="https://www.facebook.com/people/Emergence-Music-Distribution/100064302362140/"
                  title=""
                >
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://twitter.com/emergence_music " title="">
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/emergencemusicdistribution/"
                  title=""
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@emergencemusic?is_from_webapp=1&sender_device=pc"
                  title=""
                >
                  Tiktok
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@emergencemusicdistribution"
                  title=""
                >
                  Youtube
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-inner">
            <p className="title-footer">Newsletter</p>
            <form className="footer-subscribe" action="">
              <label htmlFor="Email">Email Address *</label>
              <input
                className="email px-2"
                type="email"
                id="Email"
                placeholder="Enter your email address"
              />
              <span className="agreement-wrap py-2">
                <input
                  className="checkbox"
                  type="checkbox"
                  id="agrement"
                  name="agrement"
                  value="agrement"
                />
                <label htmlFor="agrement">
                  I would like to receive emails from&nbsp;
                  <strong className="text-white">Emergence</strong>
                </label>
              </span>
              <a
                href="javscript:void(0)"
                title="Subscribe"
                className="emergence"
              >
                Subscribe
              </a>
            </form>
          </div>
          {/* </div> */}
        </div>

        <div className="copy-right">
          <p className="text-muted py-3 border-top border-dark text-center">
            &copy; Copyright Emergence Music Distribution Inc. 2020-2023. All
            Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

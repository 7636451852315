import React from 'react'
// import PropTypes from 'prop-types'

function ErrorMessage(props) {
    const {message} = props

    return (
        <span className='text-danger'>{message[0]}</span>
    )
}

// ErrorMessage.propTypes = {

// }

export default ErrorMessage

import React from "react";
import Images from "../../../assets/images";
import "../../../assets/landing.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Menu from "../Menu/Menu";
import Layout from "../Layout";
import ImagePath from "../../../constants/ImagePath";

const artists = [
  {
    id: 1,
    name: "Jamhaitian",
    image: ImagePath?.jamhaitian,
  },
  {
    id: 2,
    name: "Geelo",
    image: ImagePath?.geelo,
  },
  {
    id: 3,
    name: "Soldat Solitaire",
    image: ImagePath?.soldatSolitaire,
  },
  {
    id: 4,
    name: "Billber",
    image: ImagePath?.billber,
  },
  {
    id: 5,
    name: "Che The Lord",
    image: ImagePath?.cheTheLord,
  },
  {
    id: 6,
    name: "Aire Urbano",
    image: ImagePath?.aireUrbano,
  },
  {
    id: 7,
    name: "FyLX",
    image: ImagePath?.fylx,
  },
  {
    id: 8,
    name: "Gun House",
    image: ImagePath?.gunHouse,
  },
  {
    id: 9,
    name: "Los Bodies",
    image: ImagePath?.losBodieds,
  },
  {
    id: 10,
    name: "Peckey Power",
    image: ImagePath?.peckeyPower,
  },
  {
    id: 11,
    name: "SYLA",
    image: ImagePath?.syla,
  },
];
function LandingPage() {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Layout>
      <div className="below-header-bg">
        <div className="container">
          <Menu />
          <div className="below-header-wrap">
            <div className="below-haeder-left">
              <p className="be-part">
                Be Part Of The New Emergence Of
                <br />
                <span className="odd-clr text-uppercase text-yellow">
                  ARTISTS
                </span>
              </p>
              <p className="below-part">
                First and only platform to digitize music artists worldwide by{" "}
                <br />
                connecting artists to our exclusive Digital Artist Development
                center.
              </p>
              <Link to="/signup" title="Emergence Now" className="emergence">
                Emerge Now
              </Link>
            </div>
            <div className="below-haeder-right">
              <img src={Images.singer} alt="singer.png" />
            </div>
          </div>
        </div>
      </div>
      <div className="digital-bg">
        <div className="container">
          <div className="digital-wrap">
            <p className="be-part">
              <span className="odd-clr text-uppercase text-aqua">
                Infinite{" "}
              </span>
              Digital Music Streaming Platforms Worldwide
            </p>
            <p className="below-part col-12 col-md-8">
              Distribute your music on the most popular digital music streaming
              platforms around the universe. Emergence Music Distribution
              continuously adds new DSP's to our forever-growing distribution
              channels.
            </p>
            <div className="digital-partener-wrap">
              <div className="partener-inner">
                <img
                  src={Images.apple}
                  alt="apple.png"
                  className="partener-img"
                />
              </div>
              <div className="partener-inner">
                <img
                  src={Images.spotify}
                  alt="spotify.png"
                  className="partener-img"
                />
              </div>
              <div className="partener-inner">
                <img
                  src={Images.tiktok}
                  alt="tiktok.png"
                  className="partener-img"
                />
              </div>
              <div className="partener-inner">
                <img
                  src={Images.youtube}
                  alt="youtube.png"
                  className="partener-img"
                />
              </div>
              <div className="partener-inner">
                <img
                  src={Images.boomplay}
                  alt="amazon.png"
                  className="partener-img"
                />
              </div>
            </div>
            {/* <Link
             to="/signup"
              title="View All Stores"
              className="emergence"
            >
              View All Stores
            </Link> */}
          </div>
        </div>
      </div>
      <div className="features-bg">
        <div className="container">
          <div className="features-wrap">
            <p className="be-part">
              <span className="odd-clr text-uppercase text-yellow">Enter</span>{" "}
              The Emergence Music Center
            </p>
            <ul className="features-list">
              <li className="feature-inner">
                <Link
                  to="/signup"
                  title="Features"
                  className="feature-inner-link"
                >
                  <img
                    src={Images.magnifier}
                    alt="magnifier"
                    className="feature-img"
                  />
                  <p className="feature-title">Analytic Reports</p>
                  <p className="feature-sub-title">
                    Access your monthly financial reports. <br /> Find out where
                    you are trending.
                  </p>
                </Link>
              </li>
              <li className="feature-inner">
                <Link
                  to="/signup"
                  title="Features"
                  className="feature-inner-link"
                >
                  <img
                    src={Images.money_pot}
                    alt="money_pot"
                    className="feature-img"
                  />
                  <p className="feature-title">
                    Artist Initiative Return Funding 3%
                  </p>
                  <p className="feature-sub-title">
                    We give 3% of our annual income back to our distributed
                    artists.
                  </p>
                </Link>
              </li>
              <li className="feature-inner">
                <Link
                  to="/signup"
                  title="Features"
                  className="feature-inner-link"
                >
                  <img src={Images.mic} alt="" className="feature-img" />
                  <p className="feature-title">Digital Artist Development</p>
                  <p className="feature-sub-title">
                    The world’s leading online <br />
                    platform for professional
                    <br /> artistic development.
                  </p>
                </Link>
              </li>

              <li className="feature-inner">
                <Link
                  to="/signup"
                  title="Features"
                  className="feature-inner-link"
                >
                  <img
                    src={Images.speaker}
                    alt="speaker"
                    className="feature-img"
                  />
                  <p className="feature-title">Music Marketing Strategies</p>
                  <p className="feature-sub-title">
                    Our team will map out the best strategies for your new
                    release.
                  </p>
                </Link>
              </li>

              <li className="feature-inner">
                <Link
                  to="/signup"
                  title="Features"
                  className="feature-inner-link"
                >
                  <img src={Images.king} alt="king" className="feature-img" />
                  <p className="feature-title">Sound Collection Royalties</p>
                  <p className="feature-sub-title">
                    You will always get 100%
                    <br /> of your royalties, there
                    <br /> are no hidden fees.
                  </p>
                </Link>
              </li>
            </ul>
            {/* <Link
              to="/signup"
              title="View All Stores"
              className="emergence"
            >
              View All Stores
            </Link> */}
          </div>
        </div>
      </div>
      <div className="below-features-bg">
        <div className="container">
          <div className="below-features">
            <div className="below-feature-left">
              <p className="feature-title">
                <span className="odd-clr text-uppercase text-aqua">EMERGE</span>{" "}
                With Us
              </p>
              <p className="feature-subtitle">
                Presenting the Emergence Music Center, which will be the first
                International music tech epicenter for digital artist
                development worldwide. The only ecosystem involving the
                automatization of the artist being registered and distributed
                throughout the world. By using our one-stop-shop platform to
                secure and digitalize every aspect of a music artist.
              </p>
              <Link to="/signup" title="View All Stores" className="emergence">
                Emerge Now
              </Link>
            </div>
            <div className="below-feature-right">
              <img
                src={Images.why_chosse}
                alt="why_chosse"
                className="below-feature-img"
              />
            </div>
            <div className="below-feature-right order">
              <img
                src={Images.sell_music}
                alt="sell_music"
                className="below-feature-img"
              />
            </div>
            <div className="below-feature-left order-1">
              <p className="feature-title">
                How To{" "}
                <span className="odd-clr text-uppercase text-yellow">
                  Develop
                </span>{" "}
                Your Music Career
              </p>
              <p className="feature-subtitle">
                Emergence Music Distribution offers you a variety of services,
                to transition successfully to a professional digital music
                career. Tips on how to position yourself on social media and
                which specific promotional tools to use. To assist you in the
                process of improving your brand awareness as an artist,
                regardless of your musical genre. Don’t fall behind the pack, be
                ahead of the curve with Emergence Music Distribution and emerge
                your digital musical future now.
              </p>
              <Link to="/signup" title="View All Stores" className="emergence">
                Emerge Now
              </Link>
            </div>
            <div className="below-feature-left order-2">
              <p className="feature-title">
                Distribute and sell your{" "}
                <span className="odd-clr text-uppercase text-aqua">
                  MUSIC VIDEO
                </span>
              </p>
              <p className="feature-subtitle">
                With our exclusive video channel partnerships. Access and take
                advantage of our unique network to increase your revenue as an
                Emergence artist. Get your videos seen and heard universally.
              </p>
              <Link to="/signup" title="View All Stores" className="emergence">
                Emerge Now
              </Link>
            </div>
            <div className="below-feature-right order-3">
              <img
                src={Images.where_sell}
                alt="where_sell"
                className="below-feature-img"
                style={{ maxWidth: "450px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="how-works-bg">
        <div className="container">
          <div className="how-works-wrap">
            <p className="how-work-title">
              How{" "}
              <span className="odd-clr text-uppercase text-yellow">
                Emergence
              </span>{" "}
              Works
            </p>
            <ul className="how-work-list">
              <li className="how-work--inner">
                <img src={Images.one} alt="one" className="feature-img" />
                <p className="feature-title">
                  Register & Choose Your Music Program
                </p>
                <p className="feature-sub-title">
                  A.W.B or Ambassador Clubs are the programs we offer at
                  Emergence Music Distribution to all the artists who want to
                  develop their entrepreneurial music skills.
                </p>
              </li>
              <li className="how-work--inner">
                <img src={Images.two} alt="two" className="feature-img" />
                <p className="feature-title">
                  Upload Your Music & Select Your Music Store
                </p>
                <p className="feature-sub-title">
                  We offer our members a wide range of services including music
                  distribution on the largest streaming platforms.
                </p>
              </li>
              <li className="how-work--inner">
                <img src={Images.three} alt="three" className="feature-img" />
                <p className="feature-title">Build Your Digital Music Brand</p>
                <p className="feature-sub-title">
                  Emergence Music Distribution offers you a variety of services
                  that will make you successfully transition to a professional
                  digital music career.
                </p>
              </li>
              <li className="how-work--inner">
                <img src={Images.four} alt="four" className="feature-img" />
                <p className="feature-title">Cash Out Your Earnings</p>
                <p className="feature-sub-title">
                  No more contracts, no hidden fees and be in control of your
                  musical career. Explore new avenues in the music industry.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="artist-community-bg overflow-hidden">
        <div className="container">
          <div className="artisti-community-wrap">
            <p className="community-title">
              <span className="odd-clr text-uppercase text-aqua">
                EMERGENCE
              </span>{" "}
              Community
            </p>
            <p className="community-sub-title col-12 col-md-6 mx-auto mt-2 mb-4">
              Come and engage with our diverse community of Emergence Music
              Artists. Discover, collaborate and emerge with us.
            </p>
            <div className="community-inner-wrap">
              <Slider {...settings}>
                {artists?.map((artist) => (
                  <div
                    className="community-inner overflow-hidden"
                    key={artist?.id}
                  >
                    <img
                      src={artist?.image}
                      alt={artist?.name}
                      className="img-fluid rounded"
                    />
                    <p className="artist-name">{artist?.name}</p>
                  </div>
                ))}
              </Slider>
            </div>
            <Link to="/signup" title="Join Emergence" className="emergence">
              join emergence
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default LandingPage;

import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import ScrollToTop from "./Hooks/ScrollToTop";
import Cookies from "universal-cookie";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import Dashboard from "./pages/Dashboard/Dashboard";
import SideBar from "./components/SideBar";
import ForgetPassword from "./pages/Auth/ResetPassword";
import UpdatePassword from "./pages/Auth/UpdatePassword";
import VerifyOtp from "./pages/Auth/VerifyOtp";
import UploadMusic from "./pages/UploadMusic/UploadMusic";
import Musics from "./pages/Musics/Musics";
import Albums from "./pages/Albums";
import Settings from "./pages/Settings/Settings";
import Subscription from "./pages/Subscription/Subscription";
import LandingPage from "./pages/Frontend/LandingPage/LandingPage";
import Pricing from "./pages/Frontend/Pricing/Pricing";
import Ambassadors from "./pages/Frontend/Ambassadors/Ambassadors";
import MusicDetail from "./pages/Musics/Detail";
import AlbumDetail from "./pages/Albums/Detail";
import AboutUs from "./pages/Frontend/AboutUs";
import Career from "./pages/Frontend/Career";
import News from "./pages/Frontend/News";
import NewsDetail from "./pages/Frontend/News/detail";
import ServiceDetail from "./pages/Services/ServiceDetail";
function Container() {
  const cookies = new Cookies();
  const [authtoken, setAuthtoken] = useState(cookies.get("auth_token"));

  const setUserToken = () => {
    let token = cookies.get("auth_token");
    setAuthtoken(token);
  };

  const removeToken = () => {
    cookies.remove("auth_token");
    setAuthtoken(null);
  };

  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          {authtoken && (
            <div
              style={{
                background: "linear-gradient(70deg, #372d4c 0%, #201f27 100%)",
              }}
            >
              {/* <SideBar removeToken={removeToken} /> */}
              <Routes>
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/albums" element={<Albums />} />
                <Route path="/albums/:id" element={<AlbumDetail />} />
                <Route path="/musics" element={<Musics />} />
                <Route path="/musics/:id" element={<MusicDetail />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/upload-music" element={<UploadMusic />} />
                <Route path="/services/:slug" element={<ServiceDetail />} />
                <Route path="/login" element={<Navigate to={"/dashboard"} />} />
                <Route
                  path="/signup"
                  element={<Navigate to={"/dashboard"} />}
                />
              </Routes>
            </div>
          )}

          <>
            <Routes>
              {!authtoken && (
                <>
                <Route path="/dashboard" element={<Navigate to={"/login"} />} />
                <Route path="/subscription" element={<Navigate to={"/login"} />} />
                <Route path="/settings" element={<Navigate to={"/login"} />} />
                <Route path="/albums" element={<Navigate to={"/login"} />} />
                <Route path="/albums/:id" element={<Navigate to={"/login"} />} />
                <Route path="/musics" element={<Navigate to={"/login"} />} />
                <Route path="/musics/:id" element={<Navigate to={"/login"} />} />
                <Route path="/dashboard" element={<Navigate to={"/login"} />} />
                <Route path="/upload-music" element={<Navigate to={"/login"} />} />
                <Route path="/services/:slug" element={<Navigate to={"/login"} />} />
                </>
              )}

              <Route
                path="/login"
                element={<Login setUserToken={setUserToken} />}
              />
              <Route
                path="/signup"
                element={<SignUp setUserToken={setUserToken} />}
              />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/update-passord/:otp" element={<UpdatePassword />} />
              <Route path="/verify-otp" element={<VerifyOtp />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/services" element={<Pricing />} />
              <Route path="/ambassador" element={<Ambassadors />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/career" element={<Career />} />
              <Route path="/news" element={<News />} />
              <Route path="/news/:slug" element={<NewsDetail />} />
              {/* <Route path="*" element={<Navigate to={"/"} />} /> */}
            </Routes>
          </>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default Container;
